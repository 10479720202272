@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i|Roboto:300,300i,400,400i,500,500i,700,700i);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap);
#fetch-wrapper-noty-container .noty_body {
  padding: 0.5rem 1rem;
  font-size: 0.8rem; }

.inplant-service .modal.fade {
  display: none; }

.inplant-service .modal.fade.show {
  display: block; }

.inplant-service .w-10 {
  width: 10%; }

.inplant-service .w-15 {
  width: 15%; }

.inplant-service .w-35 {
  width: 35%; }

.inplant-service .w-40 {
  width: 40%; }

.inplant-service .issue-attachments figure {
  cursor: pointer; }

.inplant-service .breadcrumb {
  padding-left: 0;
  padding-right: 0;
  background: transparent;
  font-size: 0.9em; }

.inplant-service .issue-list-item {
  cursor: pointer; }

.inplant-service .color-gray {
  color: gray; }

.inplant-service .tooltips {
  position: relative; }
  .inplant-service .tooltips::before {
    content: '';
    position: absolute;
    top: -6px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    border-width: 4px 6px 0 6px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
    z-index: 100;
    transition: opacity 0.3s;
    opacity: 0; }
  .inplant-service .tooltips::after {
    content: attr(title);
    position: absolute;
    left: 50%;
    top: -6px;
    -webkit-transform: translateX(-50%) translateY(-100%);
            transform: translateX(-50%) translateY(-100%);
    background: rgba(0, 0, 0, 0.7);
    text-align: center;
    color: #fff;
    padding: 4px 2px;
    font-size: 12px;
    min-width: 140px;
    border-radius: 5px;
    pointer-events: none;
    transition: opacity 0.3s;
    opacity: 0; }
  .inplant-service .tooltips:hover::before, .inplant-service .tooltips:hover::after {
    opacity: 1; }

.inplant-service .card-has-errored {
  box-shadow: 0 0 7px #ff6530; }

.inplant-service .service-table .rt-tr-group {
  cursor: pointer; }

.inplant-service .attachments-preview h4 {
  font-size: 16px;
  line-height: 24px; }

.inplant-service .attachments-preview ul {
  list-style: none;
  padding: 0; }


.react-datepicker__navigation-icon::before, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-color: #ccc;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  margin-left: -4px;
  position: absolute;
  width: 0; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px;
    content: "";
    z-index: -1;
    border-width: 8px;
    left: -8px; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before {
    border-bottom-color: #aeaeae; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
    border-top: none;
    border-bottom-color: #f0f0f0; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
    top: 0; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: #aeaeae; }

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  bottom: 0;
  margin-bottom: -8px; }
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after {
    border-bottom: none;
    border-top-color: #fff; }
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after {
    bottom: 0; }
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before {
    bottom: -1px;
    border-top-color: #aeaeae; }

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%; }

.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative; }

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px; }

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0; }

.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.react-datepicker__triangle {
  position: absolute;
  left: 50px; }

.react-datepicker-popper {
  z-index: 1; }
  .react-datepicker-popper[data-placement^="bottom"] {
    padding-top: 10px; }
  .react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle, .react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
    left: auto;
    right: 50px; }
  .react-datepicker-popper[data-placement^="top"] {
    padding-bottom: 10px; }
  .react-datepicker-popper[data-placement^="right"] {
    padding-left: 8px; }
    .react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
      left: auto;
      right: 42px; }
  .react-datepicker-popper[data-placement^="left"] {
    padding-right: 8px; }
    .react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
      left: 42px;
      right: auto; }

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative; }
  .react-datepicker__header--time {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px; }
    .react-datepicker__header--time:not(.react-datepicker__header--time--only) {
      border-top-left-radius: 0; }
  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 0.3rem; }

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px; }

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem; }

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.react-datepicker__navigation {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 2px;
  padding: 0;
  border: none;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  overflow: hidden; }
  .react-datepicker__navigation--previous {
    left: 2px; }
  .react-datepicker__navigation--next {
    right: 2px; }
    .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
      right: 85px; }
  .react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto; }
    .react-datepicker__navigation--years-previous {
      top: 4px; }
    .react-datepicker__navigation--years-upcoming {
      top: -4px; }
  .react-datepicker__navigation:hover *::before {
    border-color: #a6a6a6; }

.react-datepicker__navigation-icon {
  position: relative;
  top: -1px;
  font-size: 20px;
  width: 0; }
  .react-datepicker__navigation-icon--next {
    left: -2px; }
    .react-datepicker__navigation-icon--next::before {
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      left: -7px; }
  .react-datepicker__navigation-icon--previous {
    right: -2px; }
    .react-datepicker__navigation-icon--previous::before {
      -webkit-transform: rotate(225deg);
              transform: rotate(225deg);
      right: -7px; }

.react-datepicker__month-container {
  float: left; }

.react-datepicker__year {
  margin: 0.4rem;
  text-align: center; }
  .react-datepicker__year-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 180px; }
  .react-datepicker__year .react-datepicker__year-text {
    display: inline-block;
    width: 4rem;
    margin: 2px; }

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center; }
  .react-datepicker__month .react-datepicker__month-text,
  .react-datepicker__month .react-datepicker__quarter-text {
    display: inline-block;
    width: 4rem;
    margin: 2px; }

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left; }
  .react-datepicker__input-time-container .react-datepicker-time__caption {
    display: inline-block; }
  .react-datepicker__input-time-container .react-datepicker-time__input-container {
    display: inline-block; }
    .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
      display: inline-block;
      margin-left: 10px; }
      .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
        width: auto; }
      .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button,
      .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0; }
      .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
        -moz-appearance: textfield; }
    .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
      margin-left: 5px;
      display: inline-block; }

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 85px; }
  .react-datepicker__time-container--with-today-button {
    display: inline;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    position: absolute;
    right: -72px;
    top: 0; }
  .react-datepicker__time-container .react-datepicker__time {
    position: relative;
    background: white;
    border-bottom-right-radius: 0.3rem; }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
      width: 85px;
      overflow-x: hidden;
      margin: 0 auto;
      text-align: center;
      border-bottom-right-radius: 0.3rem; }
      .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
        list-style: none;
        margin: 0;
        height: calc(195px + (1.7rem / 2));
        overflow-y: scroll;
        padding-right: 0;
        padding-left: 0;
        width: 100%;
        box-sizing: content-box; }
        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
          height: 30px;
          padding: 5px 10px;
          white-space: nowrap; }
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
            cursor: pointer;
            background-color: #f0f0f0; }
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
            background-color: #216ba5;
            color: white;
            font-weight: bold; }
            .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
              background-color: #216ba5; }
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
            color: #ccc; }
            .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
              cursor: default;
              background-color: transparent; }

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem; }
  .react-datepicker__week-number.react-datepicker__week-number--clickable {
    cursor: pointer; }
    .react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
      border-radius: 0.3rem;
      background-color: #f0f0f0; }

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap; }

.react-datepicker__day-names {
  margin-bottom: -8px; }

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem; }

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff; }
  .react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover,
  .react-datepicker__quarter--selected:hover,
  .react-datepicker__quarter--in-selecting-range:hover,
  .react-datepicker__quarter--in-range:hover {
    background-color: #1d5d90; }

.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none; }
  .react-datepicker__month--disabled:hover,
  .react-datepicker__quarter--disabled:hover {
    cursor: default;
    background-color: transparent; }

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer; }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    border-radius: 0.3rem;
    background-color: #f0f0f0; }
  .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today,
  .react-datepicker__year-text--today {
    font-weight: bold; }
  .react-datepicker__day--highlighted,
  .react-datepicker__month-text--highlighted,
  .react-datepicker__quarter-text--highlighted,
  .react-datepicker__year-text--highlighted {
    border-radius: 0.3rem;
    background-color: #3dcc4a;
    color: #fff; }
    .react-datepicker__day--highlighted:hover,
    .react-datepicker__month-text--highlighted:hover,
    .react-datepicker__quarter-text--highlighted:hover,
    .react-datepicker__year-text--highlighted:hover {
      background-color: #32be3f; }
    .react-datepicker__day--highlighted-custom-1,
    .react-datepicker__month-text--highlighted-custom-1,
    .react-datepicker__quarter-text--highlighted-custom-1,
    .react-datepicker__year-text--highlighted-custom-1 {
      color: magenta; }
    .react-datepicker__day--highlighted-custom-2,
    .react-datepicker__month-text--highlighted-custom-2,
    .react-datepicker__quarter-text--highlighted-custom-2,
    .react-datepicker__year-text--highlighted-custom-2 {
      color: green; }
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: #216ba5;
    color: #fff; }
    .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
    .react-datepicker__month-text--selected:hover,
    .react-datepicker__month-text--in-selecting-range:hover,
    .react-datepicker__month-text--in-range:hover,
    .react-datepicker__quarter-text--selected:hover,
    .react-datepicker__quarter-text--in-selecting-range:hover,
    .react-datepicker__quarter-text--in-range:hover,
    .react-datepicker__year-text--selected:hover,
    .react-datepicker__year-text--in-selecting-range:hover,
    .react-datepicker__year-text--in-range:hover {
      background-color: #1d5d90; }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    border-radius: 0.3rem;
    background-color: #2a87d0;
    color: #fff; }
    .react-datepicker__day--keyboard-selected:hover,
    .react-datepicker__month-text--keyboard-selected:hover,
    .react-datepicker__quarter-text--keyboard-selected:hover,
    .react-datepicker__year-text--keyboard-selected:hover {
      background-color: #1d5d90; }
  .react-datepicker__day--in-selecting-range ,
  .react-datepicker__month-text--in-selecting-range ,
  .react-datepicker__quarter-text--in-selecting-range ,
  .react-datepicker__year-text--in-selecting-range {
    background-color: rgba(33, 107, 165, 0.5); }
  .react-datepicker__month--selecting-range .react-datepicker__day--in-range , .react-datepicker__month--selecting-range
  .react-datepicker__month-text--in-range , .react-datepicker__month--selecting-range
  .react-datepicker__quarter-text--in-range , .react-datepicker__month--selecting-range
  .react-datepicker__year-text--in-range {
    background-color: #f0f0f0;
    color: #000; }
  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    cursor: default;
    color: #ccc; }
    .react-datepicker__day--disabled:hover,
    .react-datepicker__month-text--disabled:hover,
    .react-datepicker__quarter-text--disabled:hover,
    .react-datepicker__year-text--disabled:hover {
      background-color: transparent; }

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #216ba5; }

.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background-color: #f0f0f0; }

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%; }

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
  position: relative; }
  .react-datepicker__year-read-view:hover,
  .react-datepicker__month-read-view:hover,
  .react-datepicker__month-year-read-view:hover {
    cursor: pointer; }
    .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
      border-top-color: #b3b3b3; }
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
    right: -16px;
    top: 0; }

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae; }
  .react-datepicker__year-dropdown:hover,
  .react-datepicker__month-dropdown:hover,
  .react-datepicker__month-year-dropdown:hover {
    cursor: pointer; }
  .react-datepicker__year-dropdown--scrollable,
  .react-datepicker__month-dropdown--scrollable,
  .react-datepicker__month-year-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll; }

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .react-datepicker__year-option:first-of-type,
  .react-datepicker__month-option:first-of-type,
  .react-datepicker__month-year-option:first-of-type {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem; }
  .react-datepicker__year-option:last-of-type,
  .react-datepicker__month-option:last-of-type,
  .react-datepicker__month-year-option:last-of-type {
    -webkit-user-select: none;
    user-select: none;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem; }
  .react-datepicker__year-option:hover,
  .react-datepicker__month-option:hover,
  .react-datepicker__month-year-option:hover {
    background-color: #ccc; }
    .react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
      border-bottom-color: #b3b3b3; }
    .react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
      border-top-color: #b3b3b3; }
  .react-datepicker__year-option--selected,
  .react-datepicker__month-option--selected,
  .react-datepicker__month-year-option--selected {
    position: absolute;
    left: 15px; }

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle; }
  .react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: #216ba5;
    color: #fff;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "\D7"; }

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left; }

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647; }
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem; }
  @media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
      width: 2rem;
      line-height: 2rem; } }
  .react-datepicker__portal .react-datepicker__current-month,
  .react-datepicker__portal .react-datepicker-time__header {
    font-size: 1.44rem; }

.inplant-designer-fe .WidgetDesigner {
  display: flex;
  height: 100%;
  flex: 1 1; }
  .inplant-designer-fe .WidgetDesigner .breadcrumb {
    padding: 0.15rem 1rem; }
    .inplant-designer-fe .WidgetDesigner .breadcrumb > span + span::before {
      content: ' > ';
      display: inline-block;
      padding: 0 8px;
      color: #aaa; }
    .inplant-designer-fe .WidgetDesigner .breadcrumb span {
      cursor: pointer; }
      .inplant-designer-fe .WidgetDesigner .breadcrumb span:hover {
        opacity: 0.7; }
  .inplant-designer-fe .WidgetDesigner .cards {
    min-height: 100px; }
  .inplant-designer-fe .WidgetDesigner table a {
    cursor: pointer; }
  .inplant-designer-fe .WidgetDesigner .column {
    float: left;
    overflow-x: hidden;
    flex-shrink: 0;
    flex-grow: 1;
    height: 100%;
    transition: opacity 0.3s, width 0.3s; }
    .inplant-designer-fe .WidgetDesigner .column.to-hide {
      width: 0; }
  .inplant-designer-fe .WidgetDesigner .wrapper {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex: 1 1;
    justify-content: flex-end; }
  .inplant-designer-fe .WidgetDesigner .wrapper,
  .inplant-designer-fe .WidgetDesigner .innerWrapper {
    transition: all 0.3s; }
  .inplant-designer-fe .WidgetDesigner .innerWrapper {
    display: flex; }
  .inplant-designer-fe .WidgetDesigner .card-details h4 {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold; }
  .inplant-designer-fe .WidgetDesigner .card-data h4 {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold; }

.inplant-designer-fe .column-level {
  display: inline-flex;
  width: 150px;
  height: 84vh;
  overflow: hidden;
  transition: width 0.3s; }

.inplant-designer-fe .column-level.active {
  width: 550px; }

.inplant-designer-fe .column-level-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden; }

.inplant-designer-fe .column-level-inner-wrapper {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-right: 20px;
  margin-right: -20px;
  margin-left: auto; }

.inplant-designer-fe .card-level {
  width: 100%;
  min-height: 270px;
  transition: all 0.3s; }
  .inplant-designer-fe .card-level .node-has-qrcode {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #555;
    font-size: 1.4rem; }
    .inplant-designer-fe .card-level .node-has-qrcode:hover {
      color: #212529; }

.inplant-designer-fe .column-level.active ~ .column-level {
  opacity: 0;
  visibility: hidden; }

.inplant-designer-fe .card-level {
  position: relative; }

.inplant-designer-fe .card-level .prev {
  width: 30px;
  height: 100%;
  text-align: center;
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 0;
  z-index: 10; }

.inplant-designer-fe .card-level .prev svg {
  width: 10px; }

.inplant-designer-fe .card-level {
  border: 1px solid #ddd; }

.inplant-designer-fe .card-inner {
  padding: 15px 35px 30px; }

.inplant-designer-fe .card-level .prev svg {
  opacity: 0.3;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.inplant-designer-fe .column-level:not(:nth-child(1)) .card-level .prev svg {
  opacity: 0.3; }

.inplant-designer-fe .card-level.active .prev:hover svg {
  opacity: 1;
  -webkit-transform: translateX(-5px);
          transform: translateX(-5px); }

.inplant-designer-fe .card-level .prev path {
  stroke-width: 3px; }

.inplant-designer-fe .card-actions {
  margin-top: 25px;
  margin-bottom: 0;
  align-self: flex-end; }

.inplant-designer-fe .card-inner {
  display: flex;
  width: 100%;
  min-height: 100px;
  flex-direction: column;
  /* flex-grow: 1; */ }

.inplant-designer-fe .card-level {
  display: flex;
  flex-direction: column; }

.inplant-designer-fe .card-details {
  flex-grow: 1; }
  .inplant-designer-fe .card-details .period + .totalHours::before {
    content: ' / '; }
  .inplant-designer-fe .card-details .value {
    float: right; }

.inplant-designer-fe .card-inner .list-group-item {
  padding: 0.2rem 0.5rem;
  background-color: transparent;
  font-size: 0.8rem; }

.inplant-designer-fe .card-inner .list-group {
  border-top: 1px solid #888;
  border-bottom: 1px solid #888; }

.inplant-designer-fe .card-inner span.label {
  font-weight: bold;
  display: inline-block;
  width: 30%; }

.inplant-designer-fe .card-level {
  border: 1px solid #ccc !important;
  margin-bottom: 8px;
  border-radius: 3px;
  background: #dddddd; }

.inplant-designer-fe .card-actions a + a {
  margin-left: 10px; }

.inplant-designer-fe .card-inner h4 {
  padding-bottom: 5px;
  font-size: 1.1rem; }

.inplant-designer-fe .column-level .column-level-inner-wrapper {
  opacity: 0.5;
  pointer-events: none; }

.inplant-designer-fe .column-level .card-title,
.inplant-designer-fe .column-level .card-details,
.inplant-designer-fe .column-level .card-actions {
  display: none; }

.inplant-designer-fe .column-level .card-title p {
  margin-bottom: 0 !important; }

.inplant-designer-fe .card-title p {
  margin-bottom: 0 !important;
  font-size: 0.85rem; }

.inplant-designer-fe .column-level.active .column-level-inner-wrapper {
  opacity: 1;
  pointer-events: auto; }

.inplant-designer-fe .column-level.active .card-title,
.inplant-designer-fe .column-level.active .card-details,
.inplant-designer-fe .column-level.active .card-actions {
  display: flex; }

.inplant-designer-fe .column-level-wrapper .column-title {
  font-size: 0.9rem;
  line-height: 1.4rem;
  margin-bottom: 20px;
  margin-left: 33px;
  text-transform: uppercase;
  opacity: 0;
  transition: opacity 0.3s; }

.inplant-designer-fe .column-level.active .column-level-wrapper .column-title {
  font-size: 1.4rem;
  line-height: 1.4rem;
  opacity: 1; }

.inplant-designer-fe .card-level.active,
.inplant-designer-fe .card-level:hover {
  background: #e9ecef; }

.inplant-designer-fe .column-level .prev,
.inplant-designer-fe .column-level .next {
  opacity: 0; }

.inplant-designer-fe .column-level.active .prev,
.inplant-designer-fe .column-level.active .next {
  opacity: 0.5; }

.inplant-designer-fe .column-level .column-level-wrapper h3::after {
  content: '>';
  display: inline-block;
  width: 20px;
  height: 20px;
  float: right;
  margin-right: 10px; }

.inplant-designer-fe .column-level.active .column-level-wrapper h3::after {
  display: none; }

.inplant-designer-fe .column-level.active .card-level:hover {
  box-shadow: 0 2px 13px rgba(0, 0, 0, 0.2); }

.inplant-designer-fe .WidgetTreeNavigation {
  flex-direction: column; }

.inplant-designer-fe .WidgetTreeNavigation .column-wrapper {
  flex-direction: row; }

.inplant-designer-fe .WidgetTreeNavigation .breadcrumb {
  width: 100%;
  display: inline-flex;
  height: 24px;
  line-height: 24px;
  margin: 15px 5px 30px; }

.inplant-designer-fe .breadcrumb .active + .active::before {
  content: '>';
  display: inline-block;
  width: 40px;
  height: 14px;
  text-align: center; }

.inplant-designer-fe .breadcrumb .active {
  cursor: pointer; }

.inplant-designer-fe .column-wrapper {
  overflow-x: hidden; }

.inplant-designer-fe .card-data {
  opacity: 0;
  transition: opacity 0.3s linear 0.3s, height 0.3s, max-height 0.3s ease-out;
  max-height: 0;
  width: 100%;
  border-top: 1px solid #ccc;
  overflow: hidden; }

.inplant-designer-fe .card-data.active {
  max-height: 900px;
  opacity: 1; }

.inplant-designer-fe .card-data.active.data-maintenance {
  height: 350px; }

.inplant-designer-fe .card-data.active .card-data-inner {
  opacity: 1; }

.inplant-designer-fe .card-data.active.loading .card-data-inner {
  opacity: 0 !important; }

.inplant-designer-fe .card-data-inner {
  padding: 30px; }

.inplant-designer-fe .card-data-inner {
  transition: opacity 0.3s; }

.inplant-designer-fe .card-data-inner a {
  color: #555; }

.inplant-designer-fe .card-data-inner .small-data {
  font-size: 0.85rem; }

.inplant-designer-fe .hidden {
  display: none; }

.inplant-designer-fe .card-data td {
  line-height: 1;
  font-size: 0.9rem; }

.inplant-designer-fe .table-sm th,
.inplant-designer-fe .table-sm td {
  padding: 0.5rem 0.3rem; }

.inplant-designer-fe .card-data a {
  font-weight: bold; }

.inplant-designer-fe .card-actions a {
  width: 40px; }

.inplant-designer-fe .card-level:last-child {
  margin-bottom: 140px; }

.inplant-designer-fe .designer-node-actions {
  list-style: none;
  margin: 0;
  position: absolute;
  border-top: 1px solid #777;
  border-left: 1px solid #777;
  padding: 5px 4px 3px 5px;
  bottom: 0;
  right: 0;
  border-top-left-radius: 4px;
  background-color: #888; }
  .inplant-designer-fe .designer-node-actions li {
    display: inline-block;
    width: 20px;
    text-align: center;
    height: 20px;
    margin: 0;
    padding: 0;
    cursor: pointer; }
    .inplant-designer-fe .designer-node-actions li path {
      fill: white; }
    .inplant-designer-fe .designer-node-actions li + li {
      margin-left: 7px; }
    .inplant-designer-fe .designer-node-actions li::after {
      content: '';
      display: inline-block; }
    .inplant-designer-fe .designer-node-actions li:hover path {
      fill: #7adc7a; }
  .inplant-designer-fe .designer-node-actions .action-delete-node:hover path {
    fill: #ff7575; }
  .inplant-designer-fe .designer-node-actions a {
    width: 100%;
    display: inline-block; }

.inplant-designer-fe .add-sibling-node {
  width: calc(100% - 30px);
  background-color: #c7c7c7;
  margin-top: 15px;
  border: 1px solid #aaa; }
  .inplant-designer-fe .add-sibling-node:hover {
    background-color: #a8e2ba; }
  .inplant-designer-fe .add-sibling-node:focus, .inplant-designer-fe .add-sibling-node:active {
    background-color: #7ea; }
  .inplant-designer-fe .add-sibling-node path {
    fill: #333; }

.inplant-designer-fe .add-node form,
.inplant-designer-fe .edit-node form {
  margin-top: 20px; }
  .inplant-designer-fe .add-node form .form-check,
  .inplant-designer-fe .edit-node form .form-check {
    margin-left: 1rem; }

.inplant-designer-fe .top-actions {
  position: absolute;
  top: 1rem;
  right: 1rem; }

.inplant-designer-fe .column-level-inner-wrapper > div {
  padding-bottom: 100px;
  height: auto !important; }

.inplant-designer-fe .column-actions {
  max-width: 430px;
  display: flex; }

.inplant-designer-fe .column-actions a {
  background-color: #c7c7c7;
  margin-top: 15px;
  border: 1px solid #aaa;
  flex-basis: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  transition: all 0.3s !important; }

.inplant-designer-fe .column-actions a + a {
  margin-left: 8px; }

.inplant-designer-fe .column-actions.multiple a {
  flex-grow: 2 !important;
  flex-shrink: 2 !important; }

.inplant-designer-fe .list-folders,
.inplant-designer-fe .list-files {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s; }

.inplant-designer-fe .modal-body > .list-group > .list-folders,
.inplant-designer-fe .modal-body > .list-group > .list-folders > .list-folder > .list-group > .list-files {
  max-height: 9999em; }

.inplant-designer-fe .modal-body {
  max-height: 70vh;
  overflow-y: auto; }

.inplant-designer-fe .list-folder.open > .list-group > .list-folders,
.inplant-designer-fe .list-folder.open > .list-group > .list-files {
  max-height: 9999em; }

.inplant-designer-fe .list-folder .svg-inline--fa {
  font-size: 1.2rem;
  margin-right: 15px; }

.inplant-designer-fe .list-file-single {
  display: inline-flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer; }

.inplant-designer-fe .list-file-single:hover {
  background-color: #eee; }

.inplant-designer-fe .list-folder {
  cursor: pointer; }

.inplant-designer-fe .list-file-single.selected {
  background-color: #ffcccc; }

.inplant-designer-fe .list-file-single .file-name {
  justify-self: flex-start;
  flex-grow: 1; }

.inplant-designer-fe .list-file-single .file-size,
.inplant-designer-fe .list-folder .file-data {
  justify-self: flex-end;
  text-align: right; }

.inplant-designer-fe .list-file-single .file-data {
  margin-left: 20px; }

.inplant-designer-fe .list-file-single .file-data {
  order: 3;
  width: 20%; }

.inplant-designer-fe .list-file-single .file-size {
  order: 2;
  width: 20%; }

.inplant-designer-fe .list-group-item {
  padding-right: 0; }

.inplant-designer-fe .form-attachments li {
  padding: 5px 10px !important;
  justify-content: space-between;
  display: inline-flex; }
  .inplant-designer-fe .form-attachments li .fa-language {
    position: absolute;
    top: 10px;
    left: -16px;
    z-index: 10;
    -webkit-transform: rotateZ(90deg);
            transform: rotateZ(90deg);
    color: var(--brand-secondary);
    opacity: 0.7; }
  .inplant-designer-fe .form-attachments li:hover .fa-language {
    opacity: 1; }
  .inplant-designer-fe .form-attachments li.list-group-item {
    flex-wrap: wrap;
    padding-bottom: 20px !important;
    background-color: #fff; }
    .inplant-designer-fe .form-attachments li.list-group-item + li {
      border-top: 1px solid #ccc; }
    .inplant-designer-fe .form-attachments li.list-group-item:hover, .inplant-designer-fe .form-attachments li.list-group-item:focus {
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
      z-index: 10; }
    .inplant-designer-fe .form-attachments li.list-group-item .file-version {
      flex-basis: 30%; }
      .inplant-designer-fe .form-attachments li.list-group-item .file-version input {
        padding-left: 0;
        margin: 0 auto;
        border: none;
        border-bottom: 2px dashed #ccc;
        text-align: left;
        cursor: pointer; }
        .inplant-designer-fe .form-attachments li.list-group-item .file-version input:focus {
          border: 1px solid #ced4da;
          border-radius: 0.25rem; }
        .inplant-designer-fe .form-attachments li.list-group-item .file-version input::-webkit-input-placeholder {
          color: #aaa;
          font-style: italic; }
        .inplant-designer-fe .form-attachments li.list-group-item .file-version input::placeholder {
          color: #aaa;
          font-style: italic; }
    .inplant-designer-fe .form-attachments li.list-group-item .file-name {
      font-size: 1rem;
      flex-basis: 70%;
      border-bottom: 1px solid #eee; }
    .inplant-designer-fe .form-attachments li.list-group-item .file-type {
      flex-basis: 30%;
      text-align: right;
      border-bottom: 1px solid #eee; }

.inplant-designer-fe .form-attachments .fa-times {
  cursor: pointer; }

.inplant-designer-fe .form-attachments .file-name {
  font-size: 0.9rem;
  padding-top: 2px; }

.inplant-designer-fe .form-attachments .file-langs {
  margin-left: auto;
  margin-right: 25px; }

.inplant-designer-fe .form-attachments .file-list-action,
.inplant-designer-fe .form-media .media-list-action {
  margin-left: 7px;
  margin-right: 7px;
  color: #444; }

.inplant-designer-fe .language-select {
  float: right;
  position: relative; }
  .inplant-designer-fe .language-select .fa-globe {
    font-size: 16px;
    cursor: pointer;
    color: #555; }

.inplant-designer-fe .language-select ul {
  margin: 0;
  list-style: none;
  border: 1px solid #aaa;
  border-radius: 3px;
  padding: 3px 8px;
  position: absolute;
  background-color: #fff;
  right: 0;
  display: none; }

.inplant-designer-fe .copyable {
  cursor: pointer; }

.inplant-designer-fe .input-translations {
  float: right; }
  .inplant-designer-fe .input-translations span {
    font-size: 0.75rem;
    color: var(--primary);
    cursor: pointer;
    text-transform: lowercase; }
    .inplant-designer-fe .input-translations span + span {
      margin-left: 2px; }
      .inplant-designer-fe .input-translations span + span::before {
        content: ' | ';
        margin-right: 2px;
        color: #777; }

.inplant-designer-fe .flag-icon {
  width: 1rem;
  cursor: pointer; }

.inplant-designer-fe .flag-icon:hover {
  opacity: 0.7; }

.inplant-designer-fe .language-select:hover ul {
  display: block; }

.inplant-designer-fe .language-select .do-select {
  font-size: 0.75rem; }

.inplant-designer-fe .file-langs .file-lang-single + .file-lang-single {
  margin-left: 8px; }

.inplant-designer-fe .file-lang-single.has-translations {
  opacity: 0.4; }

.inplant-designer-fe .file-lang-single.has-translations.enabled {
  opacity: 1; }

.inplant-designer-fe .language-select .fa-caret-left {
  line-height: 1rem;
  color: #555;
  width: 10px;
  height: 13px;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s; }

.inplant-designer-fe .language-select:hover .fa-caret-left {
  -webkit-transform: rotateZ(-90deg);
          transform: rotateZ(-90deg); }

.inplant-designer-fe .language-select.language-selected li span {
  opacity: 0.4; }

.inplant-designer-fe .language-select.language-selected li.enabled span {
  opacity: 1; }

.inplant-designer-fe .file-list-action,
.inplant-designer-fe .media-list-action {
  cursor: pointer; }

.inplant-designer-fe .form-attachments .file-name {
  width: 50%;
  position: relative; }
  .inplant-designer-fe .form-attachments .file-name::after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, white 100%); }
  .inplant-designer-fe .form-attachments .file-name:hover {
    cursor: pointer; }

.inplant-designer-fe .form-attachments .file-langs {
  margin-left: auto;
  margin-right: 25px; }

.inplant-designer-fe .form-attachments .file-list-action,
.inplant-designer-fe .form-media .media-list-action {
  margin-left: 15px;
  color: #588; }

.inplant-designer-fe .form-attachments .file-list-action .fa-times,
.inplant-designer-fe .form-media .media-list-action .fa-times,
.inplant-designer-fe .metric-actions .fa-times {
  color: #f55; }

.inplant-designer-fe .metrics-list li {
  cursor: ns-resize; }
  .inplant-designer-fe .metrics-list li.active {
    background-color: var(--info) !important;
    cursor: default; }

.inplant-designer-fe .metrics-list.ordering-disabled li {
  cursor: default; }

.inplant-designer-fe .metrics-list .metric-address {
  float: right;
  font-size: 0.8rem;
  color: #555; }

.inplant-designer-fe .metrics-list .metric-actions {
  text-align: right;
  float: right;
  display: inline-flex;
  justify-content: flex-end;
  min-width: 80px; }
  .inplant-designer-fe .metrics-list .metric-actions .metric-action {
    font-size: 16px;
    cursor: pointer; }
    .inplant-designer-fe .metrics-list .metric-actions .metric-action svg {
      color: #555; }
    .inplant-designer-fe .metrics-list .metric-actions .metric-action + .metric-action {
      margin-left: 10px; }

.inplant-designer-fe .form-metrics ul {
  list-style: none;
  padding: 0;
  border: 1px solid #ccc; }

.inplant-designer-fe .form-metrics li {
  padding: 4px 8px 3px;
  font-size: 0.9rem; }
  .inplant-designer-fe .form-metrics li + li {
    border-top: 1px solid #ccc;
    margin-top: 0; }
  .inplant-designer-fe .form-metrics li:nth-child(2n) {
    background-color: #eee; }

.inplant-designer-fe .form-metrics-global ul {
  margin-top: 25px; }

.inplant-designer-fe .form-metrics-global .section-body.open {
  overflow-y: auto;
  max-height: 70vh;
  padding-bottom: 48px;
  position: relative; }

@media screen and (max-width: 768px) {
  .inplant-designer-fe {
    /* MOBILE TEST */ }
    .inplant-designer-fe .tree-container {
      width: 100%; }
    .inplant-designer-fe .column-level,
    .inplant-designer-fe .column-level-wrapper,
    .inplant-designer-fe .column-level-inner-wrapper {
      overflow: visible; }
    .inplant-designer-fe .column-level.active {
      max-width: 100%; }
    .inplant-designer-fe .column-level-wrapper .column-title {
      margin-left: 0;
      display: none; }
    .inplant-designer-fe .column-level-inner-wrapper {
      margin-left: 0;
      margin-right: 0;
      padding-right: 0; }
      .inplant-designer-fe .column-level-inner-wrapper > .container-fluid {
        height: auto !important;
        padding-left: 0;
        padding-right: 0; }
    .inplant-designer-fe .card-title p {
      margin-bottom: 0; }
    .inplant-designer-fe .card-inner {
      padding: 15px 20px; }
      .inplant-designer-fe .card-inner h4 {
        font-size: 1.25rem; }
    .inplant-designer-fe .card-actions {
      justify-content: space-between; }
      .inplant-designer-fe .card-actions a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        padding: 3px; }
    .inplant-designer-fe .breadcrumb {
      padding: 0.15rem 0rem; }
      .inplant-designer-fe .breadcrumb > span + span::before {
        content: ' > ';
        display: inline-block;
        padding: 0 8px;
        color: #aaa; }
      .inplant-designer-fe .breadcrumb span {
        cursor: pointer; }
        .inplant-designer-fe .breadcrumb span:hover {
          opacity: 0.7; }
    .inplant-designer-fe .card-actions > div {
      text-align: center !important; }
    .inplant-designer-fe .card-level .prev {
      width: 18px; }
    .inplant-designer-fe .WidgetDesigner .breadcrumb span {
      width: 100%;
      padding: 2px 0; }
      .inplant-designer-fe .WidgetDesigner .breadcrumb span + span {
        border-top: 1px solid #aaa; }
        .inplant-designer-fe .WidgetDesigner .breadcrumb span + span::before {
          display: none; }
    .inplant-designer-fe .card-title .svg-inline--fa {
      display: none !important; }
    .inplant-designer-fe .WidgetDesigner .wrapper,
    .inplant-designer-fe .WidgetDesigner .cards {
      width: 100%; }
    .inplant-designer-fe .WidgetDesigner .innerWrapper {
      justify-content: flex-end; } }

.inplant-designer-fe .section-body {
  overflow: hidden;
  max-height: 0;
  border: 1px solid #ccc;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 0 15px 0;
  border-top: none; }
  .inplant-designer-fe .section-body.open {
    max-height: 50vh;
    overflow-y: auto; }
  .inplant-designer-fe .section-body .section-wrapper {
    padding-top: 15px; }
    .inplant-designer-fe .section-body .section-wrapper label {
      margin-bottom: 0;
      font-size: 0.9rem; }

.inplant-designer-fe .section-header {
  background-color: #eee;
  padding: 6px 8px 3px 25px;
  cursor: pointer;
  font-size: 0.9rem !important;
  border-radius: 3px;
  border: 1px solid #ccc;
  border-bottom-color: transparent;
  text-transform: uppercase;
  line-height: 1rem;
  position: relative; }
  .inplant-designer-fe .section-header .svg-inline--fa {
    position: absolute;
    left: 8px; }

.inplant-designer-fe .section-actions {
  margin-top: 15px;
  margin-bottom: 15px;
  padding-bottom: 8px;
  height: 32px; }

.inplant-designer-fe .form-actions {
  margin-top: 40px !important; }

.inplant-designer-fe .metric-form-visible > .form-actions .btn {
  pointer-events: none;
  opacity: 0.3; }

@media screen and (min-width: 1025px) {
  .inplant-designer-fe .breadcrumb {
    padding-left: 0;
    padding-right: 0;
    font-size: 0.9em;
    background-color: transparent;
    margin-left: 15px;
    margin-top: 5px; }
  .inplant-designer-fe .breadcrumb span {
    color: #e94e1b;
    text-decoration: none;
    background-color: transparent; }
  .inplant-designer-fe .breadcrumb span:last-child {
    color: #6c757d; }
  .inplant-designer-fe .WidgetDesigner .breadcrumb > span + span::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: '/'; }
  .inplant-designer-fe .main-title {
    margin-left: 30px;
    margin-top: 15px; }
  .inplant-designer-fe .main-title h1 {
    font-size: 1.5rem;
    letter-spacing: -0.025em;
    margin: 0;
    font-weight: 500;
    line-height: 1.2;
    color: inherit; }
  .inplant-designer-fe .card-level .prev span {
    margin: auto; }
  .inplant-designer-fe .card-level .prev {
    display: flex;
    justify-content: center; }
  .inplant-designer-fe .WidgetDesigner .column {
    max-width: 85px; }
  .inplant-designer-fe .WidgetDesigner .column:last-child {
    width: 500px;
    max-width: 100%; }
  .inplant-designer-fe .column-level-inner-wrapper {
    overflow-x: hidden; }
  .inplant-designer-fe .WidgetDesigner .column {
    position: relative; }
    .inplant-designer-fe .WidgetDesigner .column:first-child {
      margin-left: 10px; }
  .inplant-designer-fe .WidgetDesigner .wrapper {
    justify-content: flex-end; }
  .inplant-designer-fe .WidgetDesigner .column::after {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 20px);
    height: 100%;
    background-color: #dddddd;
    content: '';
    display: block;
    margin-left: 20px;
    z-index: 100;
    border-radius: 3px;
    border: 1px solid #ccc; }
  .inplant-designer-fe .WidgetDesigner .column:last-child::after {
    display: none; }
  .inplant-designer-fe .column .column-title {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    opacity: 1;
    margin: 0;
    position: absolute;
    top: 30px;
    left: 65px;
    width: 250px;
    -webkit-transform-origin: top left;
            transform-origin: top left;
    z-index: 200;
    cursor: pointer; }
  .inplant-designer-fe .column:last-child .column-title {
    display: none; }
  .inplant-designer-fe .WidgetDesigner .wrapper,
  .inplant-designer-fe .WidgetDesigner .innerWrapper {
    min-width: 48vw; }
  .inplant-designer-fe .sidecontent {
    width: 45vw;
    float: left;
    margin-top: 82px;
    padding-left: 30px;
    padding-right: 30px;
    border-left: 1px solid #eee;
    overflow: hidden; }
    .inplant-designer-fe .sidecontent .vertical-wrapper {
      max-height: calc(100% - 40px);
      overflow-y: auto;
      overflow-x: hidden; }
  .inplant-designer-fe .card-data.active {
    max-height: 0;
    opacity: 0; }
  .inplant-designer-fe .WidgetDesigner .innerWrapper {
    flex-grow: 1;
    flex-shrink: 0;
    width: auto !important; }
  .inplant-designer-fe .WidgetDesigner .wrapper {
    justify-content: flex-end; }
  .inplant-designer-fe .innerWrapper.animating .column:last-child {
    width: 0; }
  .inplant-designer-fe .column:last-child .column-title {
    display: block;
    -webkit-transform: none;
            transform: none;
    position: relative;
    top: auto;
    left: 35px;
    font-size: 1.7rem;
    text-transform: none;
    padding-bottom: 20px;
    margin-bottom: 25px;
    border-bottom: 1px solid #eee;
    width: calc(100% - 50px);
    text-align: left; }
  .inplant-designer-fe .col-1-0 {
    margin-left: 0 !important; }
  .inplant-designer-fe .sidecontent h3 {
    margin: 0;
    text-align: left;
    font-size: 1.5rem;
    margin-top: 15px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px; }
  .inplant-designer-fe .sidecontent h4 {
    margin-top: 15px;
    font-size: 1.3rem; }
  .inplant-designer-fe .cards.has-media .card-title {
    cursor: pointer;
    flex-shrink: 0; }
  .inplant-designer-fe .cards.no-media .card-details,
  .inplant-designer-fe .cards.no-media .card-actions {
    display: none; }
  .inplant-designer-fe .cards.no-media .card-title {
    margin-bottom: 0; }
    .inplant-designer-fe .cards.no-media .card-title h4 {
      margin-bottom: 0;
      padding-bottom: 0; }
  .inplant-designer-fe .WidgetDesigner .cards,
  .inplant-designer-fe .card-inner {
    min-height: 60px; }
  .inplant-designer-fe .WidgetDesigner .cards.is-collapsed {
    max-height: 100px;
    overflow-y: hidden; }
    .inplant-designer-fe .WidgetDesigner .cards.is-collapsed .card-details,
    .inplant-designer-fe .WidgetDesigner .cards.is-collapsed .card-actions {
      opacity: 0; }
  .inplant-designer-fe .card-title .svg-inline--fa {
    position: absolute;
    top: 25px;
    right: 0;
    width: 18px;
    height: 18px;
    color: #444;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s; }
  .inplant-designer-fe .cards.has-media .card-title .svg-inline--fa {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  .inplant-designer-fe .cards.has-media.is-collapsed .card-title .svg-inline--fa {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); } }

@media screen and (min-width: 1440px) {
  .inplant-designer-fe .WidgetDesigner .cards,
  .inplant-designer-fe .add-sibling-node {
    max-width: 430px; } }

.inplant-designer-fe .tooltips {
  position: relative; }
  .inplant-designer-fe .tooltips::before {
    content: '';
    position: absolute;
    top: -6px;
    left: 50%;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    border-width: 4px 6px 0 6px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
    z-index: 100;
    transition: opacity 0.3s;
    opacity: 0; }
  .inplant-designer-fe .tooltips::after {
    content: attr(data-title);
    position: absolute;
    left: 50%;
    top: -6px;
    -webkit-transform: translateX(0) translateY(-100%);
            transform: translateX(0) translateY(-100%);
    background: rgba(0, 0, 0, 0.7);
    text-align: center;
    color: #fff;
    padding: 4px 2px;
    font-size: 12px;
    min-width: 100px;
    border-radius: 5px;
    pointer-events: none;
    transition: opacity 0.3s;
    opacity: 0; }
  .inplant-designer-fe .tooltips:hover::before, .inplant-designer-fe .tooltips:hover::after {
    opacity: 1; }

.inplant-designer-fe .overlay-focuser {
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  transition: opacity 0.3s;
  pointer-events: none; }

.inplant-designer-fe .sidecontent {
  transition: box-shadow 0.3s; }

.inplant-designer-fe .card-level {
  transition: background-color 0.3s; }

.inplant-designer-fe.sidecontent-open .overlay-focuser {
  opacity: 0.25; }

.inplant-designer-fe.sidecontent-open .sidecontent {
  z-index: 10000;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); }

.inplant-designer-fe.sidecontent-open .top-actions .btn {
  pointer-events: none;
  opacity: 0.3; }

.inplant-designer-fe.sidecontent-open .card-level.active {
  background-color: #fff;
  z-index: 10; }
  .inplant-designer-fe.sidecontent-open .card-level.active .designer-node-actions {
    background-color: var(--brand-secondary); }

.metric-label {
  cursor: pointer; }

.preview-data .value {
  float: right;
  text-align: right; }

.preview-image img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 400px; }

.metric-order-helper {
  background-color: #fff;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.7);
  padding: 4px 8px 3px;
  font-size: 0.9rem;
  list-style: none; }
  .metric-order-helper .metric-address {
    float: right;
    font-size: 0.8rem;
    color: #555; }
  .metric-order-helper .metric-actions {
    display: none; }

#preview-list-video {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100px;
  height: 80px;
  z-index: 10; }
  #preview-list-video img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%; }

.list-group-video {
  margin-bottom: 40px !important; }
  .list-group-video .list-group-item {
    position: relative;
    padding: 5px 10px;
    justify-content: space-between;
    display: inline-flex; }

.inplant-user {
  /*
  &.inplant-user-list .user-table,
  &.inplant-user-form {
    padding: 1rem 2rem;
  }



  .rjsf {
    & > .form-group {
      & > label,
      & > .field-description {
        display: none;
      }
    }

    .header {
      margin: 0;
      height: 28px;
    }

    h1 {
      font-size: 1.5rem;
      text-transform: uppercase;
    }

    fieldset {
      .field {
        width: 48%;
        float: left;
        height: 95px;
      }
      .field:nth-child(2n) {
        margin-left: 4%;
      }
    }

    .form-actions {
      margin-top: 50px;
    }

    nav {
      margin-bottom: 3rem;
    }
  }

  .rt-tbody .rt-tr:not(.-padRow) {
    cursor: pointer;
  }

  .breadcrumb {
    padding-left: 0;
    padding-right: 0;
    background: transparent;
    font-size: 0.9em;
  }
*/ }
  .inplant-user.inplant-user-form .form-actions {
    padding: 0 2rem; }
  .inplant-user.inplant-user-form input[type="checkbox"] + span {
    margin-left: 8px; }
  .inplant-user .rt-tr-group {
    cursor: pointer; }

@media (min-width: 768px) {
  .modal-xl {
    width: 80%;
    max-width: 1000px; } }

.ReactTable .rt-tbody {
  will-change: transform; }

@media (min-width: 768px) {
  .modal-xl {
    width: 80%;
    max-width: 1000px; } }

.total-cost-for-lot {
  margin-bottom: 30px; }

.inplant-fe-production .ReactTable .rt-td {
  overflow: visible !important; }

.inplant-fe-production .containerSumPiecesFromRange > dt, .inplant-fe-production dd {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

@charset "UTF-8";
/*
$inplant-theme-colors: (
  brand-primary: #e94e1b,
  brand-secondary: #3f9aaa,
  sidebar-bg: #f9f9f9,
  sidebar-ul-border: #f9f9f9,
  sidebar-1st-level-color: #9b9b9b,
  sidebar-1st-level-active-bg: #ffffff,
  sidebar-1st-level-active-color: #c6c6c6,
  sidebar-1st-level-hover-bg: #e9ecef,
  sidebar-1st-level-hover-color: #9b9b9b,
  sidebar-2nd-level-color: #9b9b9b,
  sidebar-2nd-level-active-bg: #f9f9f9,
  sidebar-2nd-level-active-color: #e94e1b,
  sidebar-2nd-level-hover-bg: #f9f9f9,
  sidebar-2nd-level-hover-color: #c6c6c6,
);

$inplant-base-colors: (
  white: #ffffff,
  gray-100: #f9f9f9,
  gray-200: #efefef,
  gray-300: #dbdbdb,
  gray-400: #c6c6c6,
  gray-500: #afafaf,
  gray-600: #9b9b9b,
  gray-700: #828282,
  gray-800: #686868,
  gray-900: #474747,
  black: #222222,
);
*/
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #e94e1b;
  --secondary: #3f9aaa;
  --success: #6b9c14;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Roboto Condensed", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Roboto Condensed", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #e94e1b;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #a73610;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "\2014\A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #f9cdbf;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #f4a388;
}

.table-hover .table-primary:hover {
  background-color: #f7bba8;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #f7bba8;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #c9e3e7;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #9bcad3;
}

.table-hover .table-secondary:hover {
  background-color: #b7dadf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #b7dadf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #d6e3bd;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #b2cc85;
}

.table-hover .table-success:hover {
  background-color: #cbdbab;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #cbdbab;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #6e6e6e;
  border-color: #818181;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #6e6e6e;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #818181;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control, .mv-input-group__form-control,
.mv-form-control, textarea {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control, .mv-input-group__form-control,
.mv-form-control, textarea {
    transition: none;
  }
}
.form-control::-ms-expand, .mv-input-group__form-control::-ms-expand,
.mv-form-control::-ms-expand, textarea::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus, .mv-input-group__form-control:focus,
.mv-form-control:focus, textarea:focus {
  color: #495057;
  background-color: #fff;
  border-color: #f4a88f;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(233, 78, 27, 0.25);
}
.form-control::-webkit-input-placeholder, .mv-input-group__form-control::-webkit-input-placeholder, .mv-form-control::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::placeholder, .mv-input-group__form-control::placeholder,
.mv-form-control::placeholder, textarea::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .mv-input-group__form-control:disabled,
.mv-form-control:disabled, textarea:disabled, .form-control[readonly], [readonly].mv-input-group__form-control,
[readonly].mv-form-control, textarea[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value, select.mv-input-group__form-control:focus::-ms-value,
select.mv-form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select[size].mv-input-group__form-control,
select[size].mv-form-control, select.form-control[multiple], select[multiple].mv-input-group__form-control,
select[multiple].mv-form-control {
  height: auto;
}

textarea.form-control, textarea.mv-input-group__form-control,
textarea.mv-form-control, textarea {
  height: auto;
}

.form-group, .mv-form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #6b9c14;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(107, 156, 20, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:valid, .was-validated .mv-input-group__form-control:valid,
.was-validated .mv-form-control:valid, .was-validated textarea:valid, .form-control.is-valid, .is-valid.mv-input-group__form-control,
.is-valid.mv-form-control, textarea.is-valid {
  border-color: #6b9c14;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%236b9c14' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .was-validated .mv-input-group__form-control:valid:focus,
.was-validated .mv-form-control:valid:focus, .was-validated textarea:valid:focus, .form-control.is-valid:focus, .is-valid.mv-input-group__form-control:focus,
.is-valid.mv-form-control:focus, textarea.is-valid:focus {
  border-color: #6b9c14;
  box-shadow: 0 0 0 0.2rem rgba(107, 156, 20, 0.25);
}
.was-validated .form-control:valid ~ .valid-feedback, .was-validated .mv-input-group__form-control:valid ~ .valid-feedback,
.was-validated .mv-form-control:valid ~ .valid-feedback, .was-validated textarea:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip,
.was-validated .mv-input-group__form-control:valid ~ .valid-tooltip,
.was-validated .mv-form-control:valid ~ .valid-tooltip,
.was-validated textarea:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback, .is-valid.mv-input-group__form-control ~ .valid-feedback,
.is-valid.mv-form-control ~ .valid-feedback, textarea.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip,
.is-valid.mv-input-group__form-control ~ .valid-tooltip,
.is-valid.mv-form-control ~ .valid-tooltip,
textarea.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated textarea.form-control:valid, .was-validated textarea:valid, textarea.form-control.is-valid, textarea.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #6b9c14;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%236b9c14' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #6b9c14;
  box-shadow: 0 0 0 0.2rem rgba(107, 156, 20, 0.25);
}
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #6b9c14;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #6b9c14;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #6b9c14;
}
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #8ac91a;
  background-color: #8ac91a;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(107, 156, 20, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #6b9c14;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #6b9c14;
}
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #6b9c14;
  box-shadow: 0 0 0 0.2rem rgba(107, 156, 20, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:invalid, .was-validated .mv-input-group__form-control:invalid,
.was-validated .mv-form-control:invalid, .was-validated textarea:invalid, .form-control.is-invalid, .is-invalid.mv-input-group__form-control,
.is-invalid.mv-form-control, textarea.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .was-validated .mv-input-group__form-control:invalid:focus,
.was-validated .mv-form-control:invalid:focus, .was-validated textarea:invalid:focus, .form-control.is-invalid:focus, .is-invalid.mv-input-group__form-control:focus,
.is-invalid.mv-form-control:focus, textarea.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .mv-input-group__form-control:invalid ~ .invalid-feedback,
.was-validated .mv-form-control:invalid ~ .invalid-feedback, .was-validated textarea:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip,
.was-validated .mv-input-group__form-control:invalid ~ .invalid-tooltip,
.was-validated .mv-form-control:invalid ~ .invalid-tooltip,
.was-validated textarea:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback, .is-invalid.mv-input-group__form-control ~ .invalid-feedback,
.is-invalid.mv-form-control ~ .invalid-feedback, textarea.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.is-invalid.mv-input-group__form-control ~ .invalid-tooltip,
.is-invalid.mv-form-control ~ .invalid-tooltip,
textarea.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated textarea.form-control:invalid, .was-validated textarea:invalid, textarea.form-control.is-invalid, textarea.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group, .form-inline .mv-form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control, .form-inline .mv-input-group__form-control,
.form-inline .mv-form-control, .form-inline textarea {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group, .form-inline .mv-input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn, .mv-icon-btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn, .mv-icon-btn {
    transition: none;
  }
}
.btn:hover, .mv-icon-btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .mv-icon-btn:focus, .btn.focus, .focus.mv-icon-btn {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(233, 78, 27, 0.25);
}
.btn.disabled, .disabled.mv-icon-btn, .btn:disabled, .mv-icon-btn:disabled {
  opacity: 0.65;
}
a.btn.disabled, a.disabled.mv-icon-btn,
fieldset:disabled a.btn,
fieldset:disabled a.mv-icon-btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #e94e1b;
  border-color: #e94e1b;
}
.btn-primary:hover {
  color: #fff;
  background-color: #ca4114;
  border-color: #bf3d12;
}
.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(236, 105, 61, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #e94e1b;
  border-color: #e94e1b;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary.mv-breadcrumb__item--active:not(:disabled):not(.disabled), .show > .btn-primary.dropdown-toggle, .mv-show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #bf3d12;
  border-color: #b33911;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary.mv-breadcrumb__item--active:not(:disabled):not(.disabled):focus, .show > .btn-primary.dropdown-toggle:focus, .mv-show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(236, 105, 61, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #3f9aaa;
  border-color: #3f9aaa;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #35818e;
  border-color: #317885;
}
.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 169, 183, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #3f9aaa;
  border-color: #3f9aaa;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary.mv-breadcrumb__item--active:not(:disabled):not(.disabled), .show > .btn-secondary.dropdown-toggle, .mv-show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #317885;
  border-color: #2e707b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary.mv-breadcrumb__item--active:not(:disabled):not(.disabled):focus, .show > .btn-secondary.dropdown-toggle:focus, .mv-show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 169, 183, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #6b9c14;
  border-color: #6b9c14;
}
.btn-success:hover {
  color: #fff;
  background-color: #547a10;
  border-color: #4c6f0e;
}
.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(129, 171, 55, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #6b9c14;
  border-color: #6b9c14;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .btn-success.mv-breadcrumb__item--active:not(:disabled):not(.disabled), .show > .btn-success.dropdown-toggle, .mv-show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #4c6f0e;
  border-color: #44630d;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .btn-success.mv-breadcrumb__item--active:not(:disabled):not(.disabled):focus, .show > .btn-success.dropdown-toggle:focus, .mv-show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(129, 171, 55, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .btn-info.mv-breadcrumb__item--active:not(:disabled):not(.disabled), .show > .btn-info.dropdown-toggle, .mv-show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .btn-info.mv-breadcrumb__item--active:not(:disabled):not(.disabled):focus, .show > .btn-info.dropdown-toggle:focus, .mv-show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .btn-warning.mv-breadcrumb__item--active:not(:disabled):not(.disabled), .show > .btn-warning.dropdown-toggle, .mv-show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .btn-warning.mv-breadcrumb__item--active:not(:disabled):not(.disabled):focus, .show > .btn-warning.dropdown-toggle:focus, .mv-show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .btn-danger.mv-breadcrumb__item--active:not(:disabled):not(.disabled), .show > .btn-danger.dropdown-toggle, .mv-show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .btn-danger.mv-breadcrumb__item--active:not(:disabled):not(.disabled):focus, .show > .btn-danger.dropdown-toggle:focus, .mv-show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .btn-light.mv-breadcrumb__item--active:not(:disabled):not(.disabled), .show > .btn-light.dropdown-toggle, .mv-show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .btn-light.mv-breadcrumb__item--active:not(:disabled):not(.disabled):focus, .show > .btn-light.dropdown-toggle:focus, .mv-show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .btn-dark.mv-breadcrumb__item--active:not(:disabled):not(.disabled), .show > .btn-dark.dropdown-toggle, .mv-show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .btn-dark.mv-breadcrumb__item--active:not(:disabled):not(.disabled):focus, .show > .btn-dark.dropdown-toggle:focus, .mv-show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #e94e1b;
  border-color: #e94e1b;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #e94e1b;
  border-color: #e94e1b;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(233, 78, 27, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #e94e1b;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary.mv-breadcrumb__item--active:not(:disabled):not(.disabled), .show > .btn-outline-primary.dropdown-toggle, .mv-show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #e94e1b;
  border-color: #e94e1b;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary.mv-breadcrumb__item--active:not(:disabled):not(.disabled):focus, .show > .btn-outline-primary.dropdown-toggle:focus, .mv-show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(233, 78, 27, 0.5);
}

.btn-outline-secondary {
  color: #3f9aaa;
  border-color: #3f9aaa;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #3f9aaa;
  border-color: #3f9aaa;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 154, 170, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #3f9aaa;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary.mv-breadcrumb__item--active:not(:disabled):not(.disabled), .show > .btn-outline-secondary.dropdown-toggle, .mv-show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #3f9aaa;
  border-color: #3f9aaa;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary.mv-breadcrumb__item--active:not(:disabled):not(.disabled):focus, .show > .btn-outline-secondary.dropdown-toggle:focus, .mv-show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 154, 170, 0.5);
}

.btn-outline-success {
  color: #6b9c14;
  border-color: #6b9c14;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #6b9c14;
  border-color: #6b9c14;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(107, 156, 20, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #6b9c14;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success.mv-breadcrumb__item--active:not(:disabled):not(.disabled), .show > .btn-outline-success.dropdown-toggle, .mv-show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #6b9c14;
  border-color: #6b9c14;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .btn-outline-success.mv-breadcrumb__item--active:not(:disabled):not(.disabled):focus, .show > .btn-outline-success.dropdown-toggle:focus, .mv-show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(107, 156, 20, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .btn-outline-info.mv-breadcrumb__item--active:not(:disabled):not(.disabled), .show > .btn-outline-info.dropdown-toggle, .mv-show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .btn-outline-info.mv-breadcrumb__item--active:not(:disabled):not(.disabled):focus, .show > .btn-outline-info.dropdown-toggle:focus, .mv-show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning.mv-breadcrumb__item--active:not(:disabled):not(.disabled), .show > .btn-outline-warning.dropdown-toggle, .mv-show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .btn-outline-warning.mv-breadcrumb__item--active:not(:disabled):not(.disabled):focus, .show > .btn-outline-warning.dropdown-toggle:focus, .mv-show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger.mv-breadcrumb__item--active:not(:disabled):not(.disabled), .show > .btn-outline-danger.dropdown-toggle, .mv-show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .btn-outline-danger.mv-breadcrumb__item--active:not(:disabled):not(.disabled):focus, .show > .btn-outline-danger.dropdown-toggle:focus, .mv-show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .btn-outline-light.mv-breadcrumb__item--active:not(:disabled):not(.disabled), .show > .btn-outline-light.dropdown-toggle, .mv-show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .btn-outline-light.mv-breadcrumb__item--active:not(:disabled):not(.disabled):focus, .show > .btn-outline-light.dropdown-toggle:focus, .mv-show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark.mv-breadcrumb__item--active:not(:disabled):not(.disabled), .show > .btn-outline-dark.dropdown-toggle, .mv-show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .btn-outline-dark.mv-breadcrumb__item--active:not(:disabled):not(.disabled):focus, .show > .btn-outline-dark.dropdown-toggle:focus, .mv-show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #e94e1b;
  text-decoration: none;
}
.btn-link:hover {
  color: #a73610;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn, .btn-group-lg > .mv-icon-btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn, .btn-group-sm > .mv-icon-btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade, .mv-fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade, .mv-fade {
    transition: none;
  }
}
.fade:not(.show):not(.mv-show), .mv-fade:not(.show):not(.mv-show) {
  opacity: 0;
}

.collapse:not(.show):not(.mv-show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active, .dropdown-item.mv-breadcrumb__item--active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #e94e1b;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show, .dropdown-menu.mv-show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn, .btn-group > .mv-icon-btn,
.btn-group-vertical > .btn,
.btn-group-vertical > .mv-icon-btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover, .btn-group > .mv-icon-btn:hover,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .mv-icon-btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .mv-icon-btn:focus, .btn-group > .btn:active, .btn-group > .mv-icon-btn:active, .btn-group > .btn.active, .btn-group > .active.mv-icon-btn, .btn-group > .btn.mv-breadcrumb__item--active, .btn-group > .mv-breadcrumb__item--active.mv-icon-btn,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .mv-icon-btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .mv-icon-btn:active,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .active.mv-icon-btn,
.btn-group-vertical > .btn.mv-breadcrumb__item--active,
.btn-group-vertical > .mv-breadcrumb__item--active.mv-icon-btn {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group, .btn-toolbar .mv-input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child), .btn-group > .mv-icon-btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .mv-icon-btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn-group:not(:last-child) > .mv-icon-btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child), .btn-group > .mv-icon-btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn-group:not(:first-child) > .mv-icon-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .btn-group-sm > .mv-icon-btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .btn-group-lg > .mv-icon-btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn, .btn-group-vertical > .mv-icon-btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .mv-icon-btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .mv-icon-btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn-group:not(:last-child) > .mv-icon-btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .mv-icon-btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .mv-icon-btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn, .btn-group-toggle > .mv-icon-btn,
.btn-group-toggle > .btn-group > .btn,
.btn-group-toggle > .btn-group > .mv-icon-btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio], .btn-group-toggle > .mv-icon-btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .mv-icon-btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group, .mv-input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control, .mv-input-group > .form-control, .input-group > .mv-input-group__form-control, .mv-input-group > .mv-input-group__form-control,
.input-group > .mv-form-control,
.mv-input-group > .mv-form-control, .input-group > textarea, .mv-input-group > textarea,
.input-group > .form-control-plaintext,
.mv-input-group > .form-control-plaintext,
.input-group > .custom-select,
.mv-input-group > .custom-select,
.input-group > .custom-file,
.mv-input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control, .mv-input-group > .form-control + .form-control, .input-group > .mv-input-group__form-control + .form-control, .mv-input-group > .mv-input-group__form-control + .form-control,
.input-group > .mv-form-control + .form-control,
.mv-input-group > .mv-form-control + .form-control, .input-group > .form-control + .mv-input-group__form-control, .mv-input-group > .form-control + .mv-input-group__form-control, .input-group > .mv-input-group__form-control + .mv-input-group__form-control, .mv-input-group > .mv-input-group__form-control + .mv-input-group__form-control,
.input-group > .mv-form-control + .mv-input-group__form-control,
.mv-input-group > .mv-form-control + .mv-input-group__form-control,
.input-group > .form-control + .mv-form-control,
.mv-input-group > .form-control + .mv-form-control,
.input-group > .mv-input-group__form-control + .mv-form-control,
.mv-input-group > .mv-input-group__form-control + .mv-form-control,
.input-group > .mv-form-control + .mv-form-control,
.mv-input-group > .mv-form-control + .mv-form-control, .input-group > textarea + .form-control, .mv-input-group > textarea + .form-control, .input-group > textarea + .mv-input-group__form-control, .mv-input-group > textarea + .mv-input-group__form-control,
.input-group > textarea + .mv-form-control,
.mv-input-group > textarea + .mv-form-control, .input-group > .form-control + textarea, .mv-input-group > .form-control + textarea, .input-group > .mv-input-group__form-control + textarea, .mv-input-group > .mv-input-group__form-control + textarea,
.input-group > .mv-form-control + textarea,
.mv-input-group > .mv-form-control + textarea, .input-group > textarea + textarea, .mv-input-group > textarea + textarea,
.input-group > .form-control + .custom-select,
.mv-input-group > .form-control + .custom-select,
.input-group > .mv-input-group__form-control + .custom-select,
.mv-input-group > .mv-input-group__form-control + .custom-select,
.input-group > .mv-form-control + .custom-select,
.mv-input-group > .mv-form-control + .custom-select,
.input-group > textarea + .custom-select,
.mv-input-group > textarea + .custom-select,
.input-group > .form-control + .custom-file,
.mv-input-group > .form-control + .custom-file,
.input-group > .mv-input-group__form-control + .custom-file,
.mv-input-group > .mv-input-group__form-control + .custom-file,
.input-group > .mv-form-control + .custom-file,
.mv-input-group > .mv-form-control + .custom-file,
.input-group > textarea + .custom-file,
.mv-input-group > textarea + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.mv-input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .mv-input-group__form-control,
.mv-input-group > .form-control-plaintext + .mv-input-group__form-control,
.input-group > .form-control-plaintext + .mv-form-control,
.mv-input-group > .form-control-plaintext + .mv-form-control,
.input-group > .form-control-plaintext + textarea,
.mv-input-group > .form-control-plaintext + textarea,
.input-group > .form-control-plaintext + .custom-select,
.mv-input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.mv-input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.mv-input-group > .custom-select + .form-control,
.input-group > .custom-select + .mv-input-group__form-control,
.mv-input-group > .custom-select + .mv-input-group__form-control,
.input-group > .custom-select + .mv-form-control,
.mv-input-group > .custom-select + .mv-form-control,
.input-group > .custom-select + textarea,
.mv-input-group > .custom-select + textarea,
.input-group > .custom-select + .custom-select,
.mv-input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.mv-input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.mv-input-group > .custom-file + .form-control,
.input-group > .custom-file + .mv-input-group__form-control,
.mv-input-group > .custom-file + .mv-input-group__form-control,
.input-group > .custom-file + .mv-form-control,
.mv-input-group > .custom-file + .mv-form-control,
.input-group > .custom-file + textarea,
.mv-input-group > .custom-file + textarea,
.input-group > .custom-file + .custom-select,
.mv-input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file,
.mv-input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus, .mv-input-group > .form-control:focus, .input-group > .mv-input-group__form-control:focus, .mv-input-group > .mv-input-group__form-control:focus,
.input-group > .mv-form-control:focus,
.mv-input-group > .mv-form-control:focus, .input-group > textarea:focus, .mv-input-group > textarea:focus,
.input-group > .custom-select:focus,
.mv-input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label,
.mv-input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus, .mv-input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child), .mv-input-group > .form-control:not(:last-child), .input-group > .mv-input-group__form-control:not(:last-child), .mv-input-group > .mv-input-group__form-control:not(:last-child),
.input-group > .mv-form-control:not(:last-child),
.mv-input-group > .mv-form-control:not(:last-child), .input-group > textarea:not(:last-child), .mv-input-group > textarea:not(:last-child),
.input-group > .custom-select:not(:last-child),
.mv-input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child), .mv-input-group > .form-control:not(:first-child), .input-group > .mv-input-group__form-control:not(:first-child), .mv-input-group > .mv-input-group__form-control:not(:first-child),
.input-group > .mv-form-control:not(:first-child),
.mv-input-group > .mv-form-control:not(:first-child), .input-group > textarea:not(:first-child), .mv-input-group > textarea:not(:first-child),
.input-group > .custom-select:not(:first-child),
.mv-input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file, .mv-input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .mv-input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after, .mv-input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label, .mv-input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend, .mv-input-group__prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn, .mv-input-group__prepend .btn, .input-group-prepend .mv-icon-btn, .mv-input-group__prepend .mv-icon-btn,
.input-group-append .btn,
.input-group-append .mv-icon-btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus, .mv-input-group__prepend .btn:focus, .input-group-prepend .mv-icon-btn:focus, .mv-input-group__prepend .mv-icon-btn:focus,
.input-group-append .btn:focus,
.input-group-append .mv-icon-btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn, .mv-input-group__prepend .btn + .btn, .input-group-prepend .mv-icon-btn + .btn, .mv-input-group__prepend .mv-icon-btn + .btn, .input-group-prepend .btn + .mv-icon-btn, .mv-input-group__prepend .btn + .mv-icon-btn, .input-group-prepend .mv-icon-btn + .mv-icon-btn, .mv-input-group__prepend .mv-icon-btn + .mv-icon-btn,
.input-group-prepend .btn + .input-group-text,
.mv-input-group__prepend .btn + .input-group-text,
.input-group-prepend .btn + .mv-input-group__icon,
.mv-input-group__prepend .btn + .mv-input-group__icon,
.input-group-prepend .mv-icon-btn + .input-group-text,
.mv-input-group__prepend .mv-icon-btn + .input-group-text,
.input-group-prepend .mv-icon-btn + .mv-input-group__icon,
.mv-input-group__prepend .mv-icon-btn + .mv-input-group__icon,
.input-group-prepend .input-group-text + .input-group-text,
.mv-input-group__prepend .input-group-text + .input-group-text,
.input-group-prepend .mv-input-group__icon + .input-group-text,
.mv-input-group__prepend .mv-input-group__icon + .input-group-text,
.input-group-prepend .input-group-text + .mv-input-group__icon,
.mv-input-group__prepend .input-group-text + .mv-input-group__icon,
.input-group-prepend .mv-input-group__icon + .mv-input-group__icon,
.mv-input-group__prepend .mv-input-group__icon + .mv-input-group__icon,
.input-group-prepend .input-group-text + .btn,
.mv-input-group__prepend .input-group-text + .btn,
.input-group-prepend .mv-input-group__icon + .btn,
.mv-input-group__prepend .mv-input-group__icon + .btn,
.input-group-prepend .input-group-text + .mv-icon-btn,
.mv-input-group__prepend .input-group-text + .mv-icon-btn,
.input-group-prepend .mv-input-group__icon + .mv-icon-btn,
.mv-input-group__prepend .mv-input-group__icon + .mv-icon-btn,
.input-group-append .btn + .btn,
.input-group-append .mv-icon-btn + .btn,
.input-group-append .btn + .mv-icon-btn,
.input-group-append .mv-icon-btn + .mv-icon-btn,
.input-group-append .btn + .input-group-text,
.input-group-append .btn + .mv-input-group__icon,
.input-group-append .mv-icon-btn + .input-group-text,
.input-group-append .mv-icon-btn + .mv-input-group__icon,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .mv-input-group__icon + .input-group-text,
.input-group-append .input-group-text + .mv-input-group__icon,
.input-group-append .mv-input-group__icon + .mv-input-group__icon,
.input-group-append .input-group-text + .btn,
.input-group-append .mv-input-group__icon + .btn,
.input-group-append .input-group-text + .mv-icon-btn,
.input-group-append .mv-input-group__icon + .mv-icon-btn {
  margin-left: -1px;
}

.input-group-prepend, .mv-input-group__prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text, .mv-input-group__icon {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio], .mv-input-group__icon input[type=radio],
.input-group-text input[type=checkbox],
.mv-input-group__icon input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea), .input-group-lg > .mv-input-group__form-control:not(textarea),
.input-group-lg > .mv-form-control:not(textarea), .input-group-lg > textarea:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control, .input-group-lg > .mv-input-group__form-control,
.input-group-lg > .mv-form-control, .input-group-lg > textarea,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .mv-input-group__prepend > .input-group-text,
.input-group-lg > .input-group-prepend > .mv-input-group__icon,
.input-group-lg > .mv-input-group__prepend > .mv-input-group__icon,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-append > .mv-input-group__icon,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .mv-input-group__prepend > .btn,
.input-group-lg > .input-group-prepend > .mv-icon-btn,
.input-group-lg > .mv-input-group__prepend > .mv-icon-btn,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .mv-icon-btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea), .input-group-sm > .mv-input-group__form-control:not(textarea),
.input-group-sm > .mv-form-control:not(textarea), .input-group-sm > textarea:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control, .input-group-sm > .mv-input-group__form-control,
.input-group-sm > .mv-form-control, .input-group-sm > textarea,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .mv-input-group__prepend > .input-group-text,
.input-group-sm > .input-group-prepend > .mv-input-group__icon,
.input-group-sm > .mv-input-group__prepend > .mv-input-group__icon,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-append > .mv-input-group__icon,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .mv-input-group__prepend > .btn,
.input-group-sm > .input-group-prepend > .mv-icon-btn,
.input-group-sm > .mv-input-group__prepend > .mv-icon-btn,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .mv-icon-btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn, .input-group > .mv-input-group__prepend > .btn, .mv-input-group > .input-group-prepend > .btn, .mv-input-group > .mv-input-group__prepend > .btn, .input-group > .input-group-prepend > .mv-icon-btn, .input-group > .mv-input-group__prepend > .mv-icon-btn, .mv-input-group > .input-group-prepend > .mv-icon-btn, .mv-input-group > .mv-input-group__prepend > .mv-icon-btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .mv-input-group__prepend > .input-group-text,
.mv-input-group > .input-group-prepend > .input-group-text,
.mv-input-group > .mv-input-group__prepend > .input-group-text,
.input-group > .input-group-prepend > .mv-input-group__icon,
.input-group > .mv-input-group__prepend > .mv-input-group__icon,
.mv-input-group > .input-group-prepend > .mv-input-group__icon,
.mv-input-group > .mv-input-group__prepend > .mv-input-group__icon,
.input-group > .input-group-append:not(:last-child) > .btn,
.mv-input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .mv-icon-btn,
.mv-input-group > .input-group-append:not(:last-child) > .mv-icon-btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.mv-input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .mv-input-group__icon,
.mv-input-group > .input-group-append:not(:last-child) > .mv-input-group__icon,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.mv-input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .mv-icon-btn:not(:last-child):not(.dropdown-toggle),
.mv-input-group > .input-group-append:last-child > .mv-icon-btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
.mv-input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
.input-group > .input-group-append:last-child > .mv-input-group__icon:not(:last-child),
.mv-input-group > .input-group-append:last-child > .mv-input-group__icon:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn, .mv-input-group > .input-group-append > .btn, .input-group > .input-group-append > .mv-icon-btn, .mv-input-group > .input-group-append > .mv-icon-btn,
.input-group > .input-group-append > .input-group-text,
.mv-input-group > .input-group-append > .input-group-text,
.input-group > .input-group-append > .mv-input-group__icon,
.mv-input-group > .input-group-append > .mv-input-group__icon,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .mv-input-group__prepend:not(:first-child) > .btn,
.mv-input-group > .input-group-prepend:not(:first-child) > .btn,
.mv-input-group > .mv-input-group__prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .mv-icon-btn,
.input-group > .mv-input-group__prepend:not(:first-child) > .mv-icon-btn,
.mv-input-group > .input-group-prepend:not(:first-child) > .mv-icon-btn,
.mv-input-group > .mv-input-group__prepend:not(:first-child) > .mv-icon-btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .mv-input-group__prepend:not(:first-child) > .input-group-text,
.mv-input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.mv-input-group > .mv-input-group__prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .mv-input-group__icon,
.input-group > .mv-input-group__prepend:not(:first-child) > .mv-input-group__icon,
.mv-input-group > .input-group-prepend:not(:first-child) > .mv-input-group__icon,
.mv-input-group > .mv-input-group__prepend:not(:first-child) > .mv-input-group__icon,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .mv-input-group__prepend:first-child > .btn:not(:first-child),
.mv-input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.mv-input-group > .mv-input-group__prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .mv-icon-btn:not(:first-child),
.input-group > .mv-input-group__prepend:first-child > .mv-icon-btn:not(:first-child),
.mv-input-group > .input-group-prepend:first-child > .mv-icon-btn:not(:first-child),
.mv-input-group > .mv-input-group__prepend:first-child > .mv-icon-btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child),
.input-group > .mv-input-group__prepend:first-child > .input-group-text:not(:first-child),
.mv-input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child),
.mv-input-group > .mv-input-group__prepend:first-child > .input-group-text:not(:first-child),
.input-group > .input-group-prepend:first-child > .mv-input-group__icon:not(:first-child),
.input-group > .mv-input-group__prepend:first-child > .mv-input-group__icon:not(:first-child),
.mv-input-group > .input-group-prepend:first-child > .mv-input-group__icon:not(:first-child),
.mv-input-group > .mv-input-group__prepend:first-child > .mv-input-group__icon:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #e94e1b;
  background-color: #e94e1b;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(233, 78, 27, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #f4a88f;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #f9ccbe;
  border-color: #f9ccbe;
}
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #e94e1b;
  background-color: #e94e1b;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(233, 78, 27, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(233, 78, 27, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(233, 78, 27, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
          transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(233, 78, 27, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
          appearance: none;
}
.custom-select:focus {
  border-color: #f4a88f;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(233, 78, 27, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #f4a88f;
  box-shadow: 0 0 0 0.2rem rgba(233, 78, 27, 0.25);
}
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
          appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(233, 78, 27, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(233, 78, 27, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(233, 78, 27, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #e94e1b;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
          appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #f9ccbe;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #e94e1b;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #f9ccbe;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #e94e1b;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #f9ccbe;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-link.mv-breadcrumb__item--active,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-item.mv-show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active, .nav-pills .nav-link.mv-breadcrumb__item--active,
.nav-pills .show > .nav-link,
.nav-pills .mv-show > .nav-link {
  color: #fff;
  background-color: #e94e1b;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active, .tab-content > .mv-breadcrumb__item--active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .mv-show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .mv-breadcrumb__item--active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.mv-show,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.mv-breadcrumb__item--active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .mv-show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .mv-breadcrumb__item--active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.mv-show,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.mv-breadcrumb__item--active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}
.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}
.accordion > .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card .card-header {
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item, .mv-breadcrumb__item + .breadcrumb-item, .breadcrumb-item + .mv-breadcrumb__item, .mv-breadcrumb__item + .mv-breadcrumb__item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before, .mv-breadcrumb__item + .breadcrumb-item::before, .breadcrumb-item + .mv-breadcrumb__item::before, .mv-breadcrumb__item + .mv-breadcrumb__item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before, .mv-breadcrumb__item + .breadcrumb-item:hover::before, .breadcrumb-item + .mv-breadcrumb__item:hover::before, .mv-breadcrumb__item + .mv-breadcrumb__item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before, .mv-breadcrumb__item + .breadcrumb-item:hover::before, .breadcrumb-item + .mv-breadcrumb__item:hover::before, .mv-breadcrumb__item + .mv-breadcrumb__item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active, .breadcrumb-item.mv-breadcrumb__item--active, .active.mv-breadcrumb__item, .mv-breadcrumb__item.mv-breadcrumb__item--active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #e94e1b;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #a73610;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(233, 78, 27, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link, .page-item.mv-breadcrumb__item--active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #e94e1b;
  border-color: #e94e1b;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge, .mv-icon-btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #e94e1b;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #bf3d12;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(233, 78, 27, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #3f9aaa;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #317885;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(63, 154, 170, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #6b9c14;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #4c6f0e;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(107, 156, 20, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #79290e;
  background-color: #fbdcd1;
  border-color: #f9cdbf;
}
.alert-primary hr {
  border-top-color: #f7bba8;
}
.alert-primary .alert-link {
  color: #4b1a09;
}

.alert-secondary {
  color: #215058;
  background-color: #d9ebee;
  border-color: #c9e3e7;
}
.alert-secondary hr {
  border-top-color: #b7dadf;
}
.alert-secondary .alert-link {
  color: #132e33;
}

.alert-success {
  color: #38510a;
  background-color: #e1ebd0;
  border-color: #d6e3bd;
}
.alert-success hr {
  border-top-color: #cbdbab;
}
.alert-success .alert-link {
  color: #192404;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #e94e1b;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
            animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active, .list-group-item.mv-breadcrumb__item--active {
  z-index: 2;
  color: #fff;
  background-color: #e94e1b;
  border-color: #e94e1b;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal .list-group-item {
  margin-right: -1px;
  margin-bottom: 0;
}
.list-group-horizontal .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal .list-group-item:last-child {
  margin-right: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.list-group-item-primary {
  color: #79290e;
  background-color: #f9cdbf;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #79290e;
  background-color: #f7bba8;
}
.list-group-item-primary.list-group-item-action.active, .list-group-item-primary.list-group-item-action.mv-breadcrumb__item--active {
  color: #fff;
  background-color: #79290e;
  border-color: #79290e;
}

.list-group-item-secondary {
  color: #215058;
  background-color: #c9e3e7;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #215058;
  background-color: #b7dadf;
}
.list-group-item-secondary.list-group-item-action.active, .list-group-item-secondary.list-group-item-action.mv-breadcrumb__item--active {
  color: #fff;
  background-color: #215058;
  border-color: #215058;
}

.list-group-item-success {
  color: #38510a;
  background-color: #d6e3bd;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #38510a;
  background-color: #cbdbab;
}
.list-group-item-success.list-group-item-action.active, .list-group-item-success.list-group-item-action.mv-breadcrumb__item--active {
  color: #fff;
  background-color: #38510a;
  border-color: #38510a;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active, .list-group-item-info.list-group-item-action.mv-breadcrumb__item--active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active, .list-group-item-warning.list-group-item-action.mv-breadcrumb__item--active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active, .list-group-item-danger.list-group-item-action.mv-breadcrumb__item--active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active, .list-group-item-light.list-group-item-action.mv-breadcrumb__item--active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active, .list-group-item-dark.list-group-item-action.mv-breadcrumb__item--active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
          appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show, .toast.mv-show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal, .modal-open .mv-modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal, .mv-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog, .mv-modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog, .modal.mv-fade .modal-dialog, .modal.fade .mv-modal-dialog, .modal.mv-fade .mv-modal-dialog, .fade.mv-modal .modal-dialog, .mv-modal.mv-fade .modal-dialog, .fade.mv-modal .mv-modal-dialog, .mv-modal.mv-fade .mv-modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
          transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog, .modal.mv-fade .modal-dialog, .modal.fade .mv-modal-dialog, .modal.mv-fade .mv-modal-dialog, .fade.mv-modal .modal-dialog, .mv-modal.mv-fade .modal-dialog, .fade.mv-modal .mv-modal-dialog, .mv-modal.mv-fade .mv-modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog, .modal.mv-show .modal-dialog, .modal.show .mv-modal-dialog, .modal.mv-show .mv-modal-dialog, .show.mv-modal .modal-dialog, .mv-modal.mv-show .modal-dialog, .show.mv-modal .mv-modal-dialog, .mv-modal.mv-show .mv-modal-dialog {
  -webkit-transform: none;
          transform: none;
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content, .modal-dialog-scrollable .mv-modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header, .modal-dialog-scrollable .mv-modal-header,
.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .mv-modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body, .modal-dialog-scrollable .mv-modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content, .modal-dialog-centered.modal-dialog-scrollable .mv-modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content, .mv-modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade, .modal-backdrop.mv-fade {
  opacity: 0;
}
.modal-backdrop.show, .modal-backdrop.mv-show {
  opacity: 0.5;
}

.modal-header, .mv-modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-header .close, .mv-modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body, .mv-modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer, .mv-modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.modal-footer > :not(:first-child), .mv-modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child), .mv-modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog, .mv-modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content, .modal-dialog-scrollable .mv-modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Roboto Condensed", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show, .tooltip.mv-show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Roboto Condensed", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc((0.5rem + 1px) * -1);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active, .carousel-item.mv-breadcrumb__item--active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right,
.carousel-item-right.mv-breadcrumb__item--active {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left,
.carousel-item-left.mv-breadcrumb__item--active {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none;
}
.carousel-fade .carousel-item.active, .carousel-fade .carousel-item.mv-breadcrumb__item--active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left, .carousel-fade .carousel-item-left.mv-breadcrumb__item--active,
.carousel-fade .active.carousel-item-right,
.carousel-fade .carousel-item-right.mv-breadcrumb__item--active {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left, .carousel-fade .carousel-item-left.mv-breadcrumb__item--active,
.carousel-fade .active.carousel-item-right,
.carousel-fade .carousel-item-right.mv-breadcrumb__item--active {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active, .carousel-indicators .mv-breadcrumb__item--active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
          animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow 0.75s linear infinite;
          animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #e94e1b !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #bf3d12 !important;
}

.bg-secondary {
  background-color: #3f9aaa !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #317885 !important;
}

.bg-success {
  background-color: #6b9c14 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #4c6f0e !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #e94e1b !important;
}

.border-secondary {
  border-color: #3f9aaa !important;
}

.border-success {
  border-color: #6b9c14 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #e94e1b !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #a73610 !important;
}

.text-secondary {
  color: #3f9aaa !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #2a6772 !important;
}

.text-success {
  color: #6b9c14 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #3c580b !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn):not(.mv-icon-btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/*
$sizes: (
        25: mv-percentage(25%),
        50: mv-percentage(50%),
        75: mv-percentage(75%),
        100: mv-percentage(100%),
);*/
/* $sidebar-collapsed-submenu-shadow: 2px 1px 10px rgb(0 0 0 / 10%);
 */
@-webkit-keyframes iconLeftToRight {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  50% {
    -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}
@keyframes iconLeftToRight {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  50% {
    -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}
@-webkit-keyframes iconRightToLeft {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  50% {
    -webkit-transform: translateX(80%);
            transform: translateX(80%);
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}
@keyframes iconRightToLeft {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  50% {
    -webkit-transform: translateX(80%);
            transform: translateX(80%);
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}
.mv-container {
  padding: 8px;
}

.mv-flex {
  display: flex;
  margin-bottom: 0px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
}
.mv-flex--inline {
  display: inline-flex;
}
.mv-flex > .mv-flex {
  --self-margin: calc(8px + var(--parent-margin));
}
.mv-flex--none {
  --parent-margin: calc(0px - 0);
  grid-gap: 0;
  gap: 0;
}
.mv-flex--none > .mv-flex {
  --self-margin: calc(0 + var(--parent-margin));
}
.mv-flex--tiny {
  --parent-margin: calc(0px - 4px);
  grid-gap: 4px;
  gap: 4px;
}
.mv-flex--tiny > .mv-flex {
  --self-margin: calc(4px + var(--parent-margin));
}
.mv-flex--sm {
  --parent-margin: calc(0px - 8px);
  grid-gap: 8px;
  gap: 8px;
}
.mv-flex--sm > .mv-flex {
  --self-margin: calc(8px + var(--parent-margin));
}
.mv-flex--md {
  --parent-margin: calc(0px - 16px);
  grid-gap: 16px;
  gap: 16px;
}
.mv-flex--md > .mv-flex {
  --self-margin: calc(16px + var(--parent-margin));
}
.mv-flex--lg {
  --parent-margin: calc(0px - 32px);
  grid-gap: 32px;
  gap: 32px;
}
.mv-flex--lg > .mv-flex {
  --self-margin: calc(32px + var(--parent-margin));
}
.mv-flex--xlg {
  --parent-margin: calc(0px - 48px);
  grid-gap: 48px;
  gap: 48px;
}
.mv-flex--xlg > .mv-flex {
  --self-margin: calc(48px + var(--parent-margin));
}
.mv-flex.mv-flex--rounded-borders-none {
  border-radius: 0;
}
.mv-flex.mv-flex--rounded-borders-tiny {
  border-radius: 0.07rem;
}
.mv-flex.mv-flex--rounded-borders-sm {
  border-radius: 0.14rem;
}
.mv-flex.mv-flex--rounded-borders-md {
  border-radius: 0.28rem;
}
.mv-flex.mv-flex--rounded-borders-lg {
  border-radius: 0.56rem;
}
.mv-flex.mv-flex--rounded-borders-xlg {
  border-radius: 0.84rem;
}
.mv-flex > .mv-flex-item {
  flex: 1 1;
}
.mv-flex.mv-flex--row {
  flex-direction: row;
}
.mv-flex.mv-flex--column {
  flex-direction: column;
}
.mv-flex.mv-flex--nowrap {
  flex-wrap: nowrap;
}
.mv-flex.mv-flex--wrap {
  flex-wrap: wrap;
}
.mv-flex.mv-flex--justify-start {
  justify-content: flex-start;
}
.mv-flex.mv-flex--justify-end {
  justify-content: flex-end;
}
.mv-flex.mv-flex--justify-center {
  justify-content: center;
}
.mv-flex.mv-flex--justify-between {
  justify-content: space-between;
}
.mv-flex.mv-flex--justify-around {
  justify-content: space-around;
}
.mv-flex.mv-flex--justify-evenly {
  justify-content: space-evenly;
}
.mv-flex.mv-flex--align-items-start {
  align-items: flex-start;
}
.mv-flex.mv-flex--align-items-end {
  align-items: flex-end;
}
.mv-flex.mv-flex--align-items-center {
  align-items: center;
}
.mv-flex.mv-flex--align-items-stretch {
  align-items: stretch;
}
.mv-flex.mv-flex--align-items-baseline {
  align-items: baseline;
}
.mv-flex.mv-flex--align-content-start {
  align-content: flex-start;
}
.mv-flex.mv-flex--align-content-end {
  align-content: flex-end;
}
.mv-flex.mv-flex--align-content-center {
  align-content: center;
}
.mv-flex.mv-flex--align-content-between {
  align-content: space-between;
}
.mv-flex.mv-flex--align-content-around {
  align-content: space-around;
}
.mv-flex.mv-flex--align-content-stretch {
  align-content: stretch;
}
.mv-flex > .mv-flex-item--0 {
  flex: 0 1;
}
.mv-flex > .mv-flex-item--1 {
  flex: 1 1;
}
.mv-flex > .mv-flex-item--2 {
  flex: 2 1;
}
.mv-flex > .mv-flex-item--3 {
  flex: 3 1;
}
.mv-flex > .mv-flex-item--4 {
  flex: 4 1;
}
.mv-flex > .mv-flex-item--5 {
  flex: 5 1;
}
.mv-flex > .mv-flex-item--6 {
  flex: 6 1;
}
.mv-flex > .mv-flex-item--7 {
  flex: 7 1;
}
.mv-flex > .mv-flex-item--8 {
  flex: 8 1;
}
.mv-flex > .mv-flex-item--9 {
  flex: 9 1;
}
.mv-flex > .mv-flex-item--10 {
  flex: 10 1;
}
.mv-flex > .mv-flex-item--11 {
  flex: 11 1;
}
.mv-flex > .mv-flex-item--12 {
  flex: 12 1;
}
.mv-flex > .mv-flex-item--0-0 {
  flex: 0 0;
}
.mv-flex > .mv-flex-item--1-0 {
  flex: 0 1;
}
.mv-flex > .mv-flex-item--2-0 {
  flex: 0 2;
}
.mv-flex > .mv-flex-item--3-0 {
  flex: 0 3;
}
.mv-flex > .mv-flex-item--4-0 {
  flex: 0 4;
}
.mv-flex > .mv-flex-item--5-0 {
  flex: 0 5;
}
.mv-flex > .mv-flex-item--6-0 {
  flex: 0 6;
}
.mv-flex > .mv-flex-item--7-0 {
  flex: 0 7;
}
.mv-flex > .mv-flex-item--8-0 {
  flex: 0 8;
}
.mv-flex > .mv-flex-item--9-0 {
  flex: 0 9;
}
.mv-flex > .mv-flex-item--10-0 {
  flex: 0 10;
}
.mv-flex > .mv-flex-item--11-0 {
  flex: 0 11;
}
.mv-flex > .mv-flex-item--12-0 {
  flex: 0 12;
}
.mv-flex > .mv-flex-item--0-1 {
  flex: 1 0;
}
.mv-flex > .mv-flex-item--1-1 {
  flex: 1 1;
}
.mv-flex > .mv-flex-item--2-1 {
  flex: 1 2;
}
.mv-flex > .mv-flex-item--3-1 {
  flex: 1 3;
}
.mv-flex > .mv-flex-item--4-1 {
  flex: 1 4;
}
.mv-flex > .mv-flex-item--5-1 {
  flex: 1 5;
}
.mv-flex > .mv-flex-item--6-1 {
  flex: 1 6;
}
.mv-flex > .mv-flex-item--7-1 {
  flex: 1 7;
}
.mv-flex > .mv-flex-item--8-1 {
  flex: 1 8;
}
.mv-flex > .mv-flex-item--9-1 {
  flex: 1 9;
}
.mv-flex > .mv-flex-item--10-1 {
  flex: 1 10;
}
.mv-flex > .mv-flex-item--11-1 {
  flex: 1 11;
}
.mv-flex > .mv-flex-item--12-1 {
  flex: 1 12;
}
.mv-flex > .mv-flex-item--0-2 {
  flex: 2 0;
}
.mv-flex > .mv-flex-item--1-2 {
  flex: 2 1;
}
.mv-flex > .mv-flex-item--2-2 {
  flex: 2 2;
}
.mv-flex > .mv-flex-item--3-2 {
  flex: 2 3;
}
.mv-flex > .mv-flex-item--4-2 {
  flex: 2 4;
}
.mv-flex > .mv-flex-item--5-2 {
  flex: 2 5;
}
.mv-flex > .mv-flex-item--6-2 {
  flex: 2 6;
}
.mv-flex > .mv-flex-item--7-2 {
  flex: 2 7;
}
.mv-flex > .mv-flex-item--8-2 {
  flex: 2 8;
}
.mv-flex > .mv-flex-item--9-2 {
  flex: 2 9;
}
.mv-flex > .mv-flex-item--10-2 {
  flex: 2 10;
}
.mv-flex > .mv-flex-item--11-2 {
  flex: 2 11;
}
.mv-flex > .mv-flex-item--12-2 {
  flex: 2 12;
}
.mv-flex > .mv-flex-item--0-3 {
  flex: 3 0;
}
.mv-flex > .mv-flex-item--1-3 {
  flex: 3 1;
}
.mv-flex > .mv-flex-item--2-3 {
  flex: 3 2;
}
.mv-flex > .mv-flex-item--3-3 {
  flex: 3 3;
}
.mv-flex > .mv-flex-item--4-3 {
  flex: 3 4;
}
.mv-flex > .mv-flex-item--5-3 {
  flex: 3 5;
}
.mv-flex > .mv-flex-item--6-3 {
  flex: 3 6;
}
.mv-flex > .mv-flex-item--7-3 {
  flex: 3 7;
}
.mv-flex > .mv-flex-item--8-3 {
  flex: 3 8;
}
.mv-flex > .mv-flex-item--9-3 {
  flex: 3 9;
}
.mv-flex > .mv-flex-item--10-3 {
  flex: 3 10;
}
.mv-flex > .mv-flex-item--11-3 {
  flex: 3 11;
}
.mv-flex > .mv-flex-item--12-3 {
  flex: 3 12;
}
.mv-flex > .mv-flex-item--0-4 {
  flex: 4 0;
}
.mv-flex > .mv-flex-item--1-4 {
  flex: 4 1;
}
.mv-flex > .mv-flex-item--2-4 {
  flex: 4 2;
}
.mv-flex > .mv-flex-item--3-4 {
  flex: 4 3;
}
.mv-flex > .mv-flex-item--4-4 {
  flex: 4 4;
}
.mv-flex > .mv-flex-item--5-4 {
  flex: 4 5;
}
.mv-flex > .mv-flex-item--6-4 {
  flex: 4 6;
}
.mv-flex > .mv-flex-item--7-4 {
  flex: 4 7;
}
.mv-flex > .mv-flex-item--8-4 {
  flex: 4 8;
}
.mv-flex > .mv-flex-item--9-4 {
  flex: 4 9;
}
.mv-flex > .mv-flex-item--10-4 {
  flex: 4 10;
}
.mv-flex > .mv-flex-item--11-4 {
  flex: 4 11;
}
.mv-flex > .mv-flex-item--12-4 {
  flex: 4 12;
}
.mv-flex > .mv-flex-item--0-5 {
  flex: 5 0;
}
.mv-flex > .mv-flex-item--1-5 {
  flex: 5 1;
}
.mv-flex > .mv-flex-item--2-5 {
  flex: 5 2;
}
.mv-flex > .mv-flex-item--3-5 {
  flex: 5 3;
}
.mv-flex > .mv-flex-item--4-5 {
  flex: 5 4;
}
.mv-flex > .mv-flex-item--5-5 {
  flex: 5 5;
}
.mv-flex > .mv-flex-item--6-5 {
  flex: 5 6;
}
.mv-flex > .mv-flex-item--7-5 {
  flex: 5 7;
}
.mv-flex > .mv-flex-item--8-5 {
  flex: 5 8;
}
.mv-flex > .mv-flex-item--9-5 {
  flex: 5 9;
}
.mv-flex > .mv-flex-item--10-5 {
  flex: 5 10;
}
.mv-flex > .mv-flex-item--11-5 {
  flex: 5 11;
}
.mv-flex > .mv-flex-item--12-5 {
  flex: 5 12;
}
.mv-flex > .mv-flex-item--0-6 {
  flex: 6 0;
}
.mv-flex > .mv-flex-item--1-6 {
  flex: 6 1;
}
.mv-flex > .mv-flex-item--2-6 {
  flex: 6 2;
}
.mv-flex > .mv-flex-item--3-6 {
  flex: 6 3;
}
.mv-flex > .mv-flex-item--4-6 {
  flex: 6 4;
}
.mv-flex > .mv-flex-item--5-6 {
  flex: 6 5;
}
.mv-flex > .mv-flex-item--6-6 {
  flex: 6 6;
}
.mv-flex > .mv-flex-item--7-6 {
  flex: 6 7;
}
.mv-flex > .mv-flex-item--8-6 {
  flex: 6 8;
}
.mv-flex > .mv-flex-item--9-6 {
  flex: 6 9;
}
.mv-flex > .mv-flex-item--10-6 {
  flex: 6 10;
}
.mv-flex > .mv-flex-item--11-6 {
  flex: 6 11;
}
.mv-flex > .mv-flex-item--12-6 {
  flex: 6 12;
}
.mv-flex > .mv-flex-item--0-7 {
  flex: 7 0;
}
.mv-flex > .mv-flex-item--1-7 {
  flex: 7 1;
}
.mv-flex > .mv-flex-item--2-7 {
  flex: 7 2;
}
.mv-flex > .mv-flex-item--3-7 {
  flex: 7 3;
}
.mv-flex > .mv-flex-item--4-7 {
  flex: 7 4;
}
.mv-flex > .mv-flex-item--5-7 {
  flex: 7 5;
}
.mv-flex > .mv-flex-item--6-7 {
  flex: 7 6;
}
.mv-flex > .mv-flex-item--7-7 {
  flex: 7 7;
}
.mv-flex > .mv-flex-item--8-7 {
  flex: 7 8;
}
.mv-flex > .mv-flex-item--9-7 {
  flex: 7 9;
}
.mv-flex > .mv-flex-item--10-7 {
  flex: 7 10;
}
.mv-flex > .mv-flex-item--11-7 {
  flex: 7 11;
}
.mv-flex > .mv-flex-item--12-7 {
  flex: 7 12;
}
.mv-flex > .mv-flex-item--0-8 {
  flex: 8 0;
}
.mv-flex > .mv-flex-item--1-8 {
  flex: 8 1;
}
.mv-flex > .mv-flex-item--2-8 {
  flex: 8 2;
}
.mv-flex > .mv-flex-item--3-8 {
  flex: 8 3;
}
.mv-flex > .mv-flex-item--4-8 {
  flex: 8 4;
}
.mv-flex > .mv-flex-item--5-8 {
  flex: 8 5;
}
.mv-flex > .mv-flex-item--6-8 {
  flex: 8 6;
}
.mv-flex > .mv-flex-item--7-8 {
  flex: 8 7;
}
.mv-flex > .mv-flex-item--8-8 {
  flex: 8 8;
}
.mv-flex > .mv-flex-item--9-8 {
  flex: 8 9;
}
.mv-flex > .mv-flex-item--10-8 {
  flex: 8 10;
}
.mv-flex > .mv-flex-item--11-8 {
  flex: 8 11;
}
.mv-flex > .mv-flex-item--12-8 {
  flex: 8 12;
}
.mv-flex > .mv-flex-item--0-9 {
  flex: 9 0;
}
.mv-flex > .mv-flex-item--1-9 {
  flex: 9 1;
}
.mv-flex > .mv-flex-item--2-9 {
  flex: 9 2;
}
.mv-flex > .mv-flex-item--3-9 {
  flex: 9 3;
}
.mv-flex > .mv-flex-item--4-9 {
  flex: 9 4;
}
.mv-flex > .mv-flex-item--5-9 {
  flex: 9 5;
}
.mv-flex > .mv-flex-item--6-9 {
  flex: 9 6;
}
.mv-flex > .mv-flex-item--7-9 {
  flex: 9 7;
}
.mv-flex > .mv-flex-item--8-9 {
  flex: 9 8;
}
.mv-flex > .mv-flex-item--9-9 {
  flex: 9 9;
}
.mv-flex > .mv-flex-item--10-9 {
  flex: 9 10;
}
.mv-flex > .mv-flex-item--11-9 {
  flex: 9 11;
}
.mv-flex > .mv-flex-item--12-9 {
  flex: 9 12;
}
.mv-flex > .mv-flex-item--0-10 {
  flex: 10 0;
}
.mv-flex > .mv-flex-item--1-10 {
  flex: 10 1;
}
.mv-flex > .mv-flex-item--2-10 {
  flex: 10 2;
}
.mv-flex > .mv-flex-item--3-10 {
  flex: 10 3;
}
.mv-flex > .mv-flex-item--4-10 {
  flex: 10 4;
}
.mv-flex > .mv-flex-item--5-10 {
  flex: 10 5;
}
.mv-flex > .mv-flex-item--6-10 {
  flex: 10 6;
}
.mv-flex > .mv-flex-item--7-10 {
  flex: 10 7;
}
.mv-flex > .mv-flex-item--8-10 {
  flex: 10 8;
}
.mv-flex > .mv-flex-item--9-10 {
  flex: 10 9;
}
.mv-flex > .mv-flex-item--10-10 {
  flex: 10 10;
}
.mv-flex > .mv-flex-item--11-10 {
  flex: 10 11;
}
.mv-flex > .mv-flex-item--12-10 {
  flex: 10 12;
}
.mv-flex > .mv-flex-item--0-11 {
  flex: 11 0;
}
.mv-flex > .mv-flex-item--1-11 {
  flex: 11 1;
}
.mv-flex > .mv-flex-item--2-11 {
  flex: 11 2;
}
.mv-flex > .mv-flex-item--3-11 {
  flex: 11 3;
}
.mv-flex > .mv-flex-item--4-11 {
  flex: 11 4;
}
.mv-flex > .mv-flex-item--5-11 {
  flex: 11 5;
}
.mv-flex > .mv-flex-item--6-11 {
  flex: 11 6;
}
.mv-flex > .mv-flex-item--7-11 {
  flex: 11 7;
}
.mv-flex > .mv-flex-item--8-11 {
  flex: 11 8;
}
.mv-flex > .mv-flex-item--9-11 {
  flex: 11 9;
}
.mv-flex > .mv-flex-item--10-11 {
  flex: 11 10;
}
.mv-flex > .mv-flex-item--11-11 {
  flex: 11 11;
}
.mv-flex > .mv-flex-item--12-11 {
  flex: 11 12;
}
.mv-flex > .mv-flex-item--0-12 {
  flex: 12 0;
}
.mv-flex > .mv-flex-item--1-12 {
  flex: 12 1;
}
.mv-flex > .mv-flex-item--2-12 {
  flex: 12 2;
}
.mv-flex > .mv-flex-item--3-12 {
  flex: 12 3;
}
.mv-flex > .mv-flex-item--4-12 {
  flex: 12 4;
}
.mv-flex > .mv-flex-item--5-12 {
  flex: 12 5;
}
.mv-flex > .mv-flex-item--6-12 {
  flex: 12 6;
}
.mv-flex > .mv-flex-item--7-12 {
  flex: 12 7;
}
.mv-flex > .mv-flex-item--8-12 {
  flex: 12 8;
}
.mv-flex > .mv-flex-item--9-12 {
  flex: 12 9;
}
.mv-flex > .mv-flex-item--10-12 {
  flex: 12 10;
}
.mv-flex > .mv-flex-item--11-12 {
  flex: 12 11;
}
.mv-flex > .mv-flex-item--12-12 {
  flex: 12 12;
}
.mv-flex > .mv-flex-item--align-self-start {
  align-self: flex-start;
}
.mv-flex > .mv-flex-item--align-self-end {
  align-self: flex-end;
}
.mv-flex > .mv-flex-item--align-self-center {
  align-self: center;
}
.mv-flex > .mv-flex-item--align-self-stretch {
  align-self: stretch;
}
.mv-flex > .mv-flex-item--align-self-baseline {
  align-self: baseline;
}
.mv-flex > .mv-flex-item--basis-10 {
  flex-basis: 10%;
}
.mv-flex > .mv-flex-item--basis-25 {
  flex-basis: 25%;
}
.mv-flex > .mv-flex-item--basis-30 {
  flex-basis: 30%;
}
.mv-flex > .mv-flex-item--basis-33 {
  flex-basis: 33%;
}
.mv-flex > .mv-flex-item--basis-50 {
  flex-basis: 50%;
}
.mv-flex > .mv-flex-item--basis-75 {
  flex-basis: 75%;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0.5em;
  margin-bottom: 0.25em;
}

h4 {
  font-size: 14px;
  font-weight: bold;
}

h3 {
  font-size: 18px;
  font-weight: bold;
}

h2 {
  font-size: 20px;
  font-weight: normal;
}

h1 {
  font-size: 24px;
  font-weight: bold;
}

p {
  margin: 0;
}
p.light {
  color: #6e6e6e;
}
p.primary {
  color: #e94e1b;
}
p.warning {
  color: #ffc107;
}

section {
  margin-bottom: 1rem;
}

.root .mv-body {
  display: flex;
}
.root #mv-container {
  --parent-margin: 0px;
  padding: 0 2rem;
}

.mv-sidebar, .mv-sidebar--collapsed {
  position: -webkit-sticky;
  position: sticky;
  top: 56px;
  left: 0;
  width: 15%;
  overflow-x: hidden;
  height: calc(100vh - 56px);
  transition: width ease-in 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #eaeaea;
  z-index: 2;
}

.mv-sidebar__closing-button .mv-btn {
  margin: 0 !important;
}
.mv-sidebar__closing-button.mv-sidebar__closing-button-animated:hover svg {
  -webkit-animation: iconLeftToRight 1.5s cubic-bezier(0.18, 0.89, 0.44, 1.58) infinite;
          animation: iconLeftToRight 1.5s cubic-bezier(0.18, 0.89, 0.44, 1.58) infinite;
}

.mv-sidebar__infos {
  margin-top: auto;
  bottom: 0;
}

.mv-sidebar__close {
  display: none;
}

.mv-sidebar__menu {
  list-style: none;
  padding: 0;
}
.mv-sidebar__menu .mv-sidebar__submenu, .mv-sidebar--collapsed .mv-sidebar__menu .mv-sidebar__collapsed-submenu {
  background: #f5f5f5;
  max-height: 0;
  overflow: hidden;
  transition: max-height 400ms ease-in-out;
  font-size: 0.9rem;
}

.mv-sidebar__item, .mv-sidebar--collapsed .mv-sidebar__item--collapsed {
  width: 100%;
}
.mv-sidebar__item > a, .mv-sidebar--collapsed .mv-sidebar__item--collapsed > a {
  display: flex;
  color: #000;
  justify-content: space-between;
  align-items: center;
  width: inherit;
}
.mv-sidebar__item > a:hover, .mv-sidebar--collapsed .mv-sidebar__item--collapsed > a:hover {
  text-decoration: none;
  background: #fff;
}
.mv-sidebar__item > a > *, .mv-sidebar--collapsed .mv-sidebar__item--collapsed > a > * {
  padding: 16px 8px;
}
.mv-sidebar__item .item__label, .mv-sidebar--collapsed .mv-sidebar__item--collapsed .item__label {
  display: flex;
  align-items: center;
}
.mv-sidebar__item .item__label > *, .mv-sidebar--collapsed .mv-sidebar__item--collapsed .item__label > * {
  margin: 0 8px;
}

.mv-sidebar__item--active, .mv-sidebar--collapsed .mv-sidebar__item--collapsed--active {
  background-color: #f5f5f5;
  padding-bottom: 0;
}
.mv-sidebar__item--active .mv-sidebar__submenu, .mv-sidebar__item--active .mv-sidebar--collapsed .mv-sidebar__menu .mv-sidebar__collapsed-submenu, .mv-sidebar--collapsed .mv-sidebar__menu .mv-sidebar__item--active .mv-sidebar__collapsed-submenu, .mv-sidebar--collapsed .mv-sidebar__item--collapsed--active .mv-sidebar__submenu, .mv-sidebar--collapsed .mv-sidebar__item--collapsed--active .mv-sidebar__menu .mv-sidebar__collapsed-submenu, .mv-sidebar--collapsed .mv-sidebar__menu .mv-sidebar__item--collapsed--active .mv-sidebar__collapsed-submenu {
  max-height: 100vh;
}

.mv-sidebar__submenu, .mv-sidebar--collapsed .mv-sidebar__menu .mv-sidebar__collapsed-submenu {
  list-style: none;
  padding: 0;
}
.mv-sidebar__submenu .mv-sidebar__subitem, .mv-sidebar--collapsed .mv-sidebar__menu .mv-sidebar__collapsed-submenu .mv-sidebar__subitem {
  border-left: 4px solid transparent;
}
.mv-sidebar__submenu .mv-sidebar__subitem > a, .mv-sidebar--collapsed .mv-sidebar__menu .mv-sidebar__collapsed-submenu .mv-sidebar__subitem > a {
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px 16px 8px 40px;
}
.mv-sidebar__submenu .mv-sidebar__subitem > a > .mv-badge, .mv-sidebar--collapsed .mv-sidebar__menu .mv-sidebar__collapsed-submenu .mv-sidebar__subitem > a > .mv-badge {
  height: -webkit-min-content;
  height: min-content;
}
.mv-sidebar__submenu .mv-sidebar__subitem > a:hover, .mv-sidebar--collapsed .mv-sidebar__menu .mv-sidebar__collapsed-submenu .mv-sidebar__subitem > a:hover {
  text-decoration: none;
  background: #fff;
}
.mv-sidebar__submenu .mv-sidebar__subitem--active, .mv-sidebar--collapsed .mv-sidebar__menu .mv-sidebar__collapsed-submenu .mv-sidebar__subitem--active {
  border-left: 4px solid #7AB317;
  background: #fff;
}
.mv-sidebar__submenu .mv-sidebar__subitem--active > a, .mv-sidebar--collapsed .mv-sidebar__menu .mv-sidebar__collapsed-submenu .mv-sidebar__subitem--active > a {
  color: #7AB317;
  font-weight: 700;
}

.mv-right-content {
  box-sizing: border-box;
  width: 100%;
  padding: 0 2rem 1.5rem 2rem;
  z-index: 1;
}

.mv-breadcrumb-container {
  margin-bottom: 4px;
}
.mv-breadcrumb-container .mv-navigation__handle {
  display: none;
  margin-right: 8px;
}

.mv-breadcrumb {
  display: flex;
  list-style: none;
  background-color: transparent;
  padding: 10px 0;
  margin: 0;
}

@media (max-width: 1199.98px) {
  .mv-sidebar, .mv-sidebar--collapsed {
    width: 0;
    position: absolute;
    transition: width 1s;
    z-index: 100;
  }
  .mv-sidebar--open {
    width: 80%;
    position: fixed;
  }

  .mv-sidebar__close {
    display: block;
  }
  .mv-sidebar__close button {
    padding: 8px calc(16px - 4px);
    margin-left: 4px;
  }

  .mv-right-content {
    width: 100%;
  }

  .mv-breadcrumb-container {
    display: flex;
    align-items: center;
    align-content: center;
  }
  .mv-breadcrumb-container .mv-navigation__handle {
    display: block;
  }
}
.unknown-page__box {
  vertical-align: middle;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 4px 8px #e94e1b;
  margin-top: 10vh;
  max-width: 90%;
}

.mv-sidebar--collapsed {
  width: 50px;
  overflow-x: visible;
  height: calc(100vh - 56px);
}
.mv-sidebar--collapsed .mv-sidebar__item--collapsed {
  position: relative;
}
.mv-sidebar--collapsed .mv-sidebar__item--collapsed:hover > a {
  background: #fff;
}
.mv-sidebar--collapsed .mv-sidebar__item--collapsed:hover > .mv-sidebar__collapsed-submenu {
  visibility: visible;
  display: block;
}
.mv-sidebar--collapsed .mv-sidebar__item--collapsed--active {
  box-shadow: inset 4px 0px 0px 0px #7AB317;
}
.mv-sidebar--collapsed .mv-sidebar__item--collapsed--active > a {
  color: #7AB317;
  font-weight: 700;
}
.mv-sidebar--collapsed .mv-sidebar__item--collapsed--active > a:hover {
  background: #fff;
}
.mv-sidebar--collapsed .mv-sidebar__menu .mv-sidebar__collapsed-submenu {
  position: absolute;
  max-height: 100vh;
  margin-left: 40px;
  top: 0%;
  white-space: nowrap;
  visibility: hidden;
  display: none;
  padding-left: 10px;
  box-shadow: inset 0 0 0 20px transparent, 10px 6px 10px rgba(0, 0, 0, 0.1);
  background-clip: content-box;
}
.mv-sidebar--collapsed .mv-sidebar__closing-button.mv-sidebar__closing-button-animated:hover svg {
  -webkit-animation: iconRightToLeft 1.5s cubic-bezier(0.18, 0.89, 0.44, 1.58) infinite;
          animation: iconRightToLeft 1.5s cubic-bezier(0.18, 0.89, 0.44, 1.58) infinite;
}

.mv-topbar {
  width: 100%;
  background-color: #7AB317;
  padding: 4px 12px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
  height: 56px;
}

.mv-topbar__logo {
  height: 32px;
}

.mv-topbar__wrapper {
  background: #fff;
  border-radius: 0.28rem;
}
.mv-topbar__wrapper > .alert {
  padding: 8px 6px;
}

.mv-topbar__left > * {
  margin: 0 16px;
}

.vw-25 {
  width: 25vw !important;
}

.vw-50 {
  width: 50vw !important;
}

.vw-75 {
  width: 75vw !important;
}

.vw-80 {
  width: 80vw !important;
}

.vw-85 {
  width: 85vw !important;
}

.vw-90 {
  width: 90vw !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-25 {
  height: 25vh !important;
}

.vh-50 {
  height: 50vh !important;
}

.vh-75 {
  height: 75vh !important;
}

.vh-80 {
  height: 80vh !important;
}

.vh-85 {
  height: 85vh !important;
}

.vh-90 {
  height: 90vh !important;
}

.vh-100 {
  height: 100vh !important;
}

/* restrict button colors at theme buttons colors */
.btn-primary {
  color: #fff;
  background-color: #e94e1b;
  border-color: #e94e1b;
}
.btn-primary:hover {
  color: #fff;
  background-color: #ca4114;
  border-color: #bf3d12;
}
.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(236, 105, 61, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #e94e1b;
  border-color: #e94e1b;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary.mv-breadcrumb__item--active:not(:disabled):not(.disabled), .show > .btn-primary.dropdown-toggle, .mv-show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #bf3d12;
  border-color: #b33911;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary.mv-breadcrumb__item--active:not(:disabled):not(.disabled):focus, .show > .btn-primary.dropdown-toggle:focus, .mv-show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(236, 105, 61, 0.5);
}

.btn-outline-primary {
  color: #e94e1b;
  border-color: #e94e1b;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #e94e1b;
  border-color: #e94e1b;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(233, 78, 27, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #e94e1b;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary.mv-breadcrumb__item--active:not(:disabled):not(.disabled), .show > .btn-outline-primary.dropdown-toggle, .mv-show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #e94e1b;
  border-color: #e94e1b;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary.mv-breadcrumb__item--active:not(:disabled):not(.disabled):focus, .show > .btn-outline-primary.dropdown-toggle:focus, .mv-show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(233, 78, 27, 0.5);
}

.btn-flat-primary {
  padding: 0px;
  color: #e94e1b;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.btn-flat-primary:hover {
  background-color: #fff;
}
.btn-flat-primary:focus, .btn-flat-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(233, 78, 27, 0.5);
}
.btn-flat-primary:disabled:hover {
  color: #e94e1b;
}

.btn-secondary {
  color: #fff;
  background-color: #3f9aaa;
  border-color: #3f9aaa;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #35818e;
  border-color: #317885;
}
.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 169, 183, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #3f9aaa;
  border-color: #3f9aaa;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary.mv-breadcrumb__item--active:not(:disabled):not(.disabled), .show > .btn-secondary.dropdown-toggle, .mv-show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #317885;
  border-color: #2e707b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary.mv-breadcrumb__item--active:not(:disabled):not(.disabled):focus, .show > .btn-secondary.dropdown-toggle:focus, .mv-show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 169, 183, 0.5);
}

.btn-outline-secondary {
  color: #3f9aaa;
  border-color: #3f9aaa;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #3f9aaa;
  border-color: #3f9aaa;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 154, 170, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #3f9aaa;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary.mv-breadcrumb__item--active:not(:disabled):not(.disabled), .show > .btn-outline-secondary.dropdown-toggle, .mv-show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #3f9aaa;
  border-color: #3f9aaa;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary.mv-breadcrumb__item--active:not(:disabled):not(.disabled):focus, .show > .btn-outline-secondary.dropdown-toggle:focus, .mv-show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 154, 170, 0.5);
}

.btn-flat-secondary {
  padding: 0px;
  color: #3f9aaa;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.btn-flat-secondary:hover {
  background-color: #fff;
}
.btn-flat-secondary:focus, .btn-flat-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 154, 170, 0.5);
}
.btn-flat-secondary:disabled:hover {
  color: #3f9aaa;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .btn-danger.mv-breadcrumb__item--active:not(:disabled):not(.disabled), .show > .btn-danger.dropdown-toggle, .mv-show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .btn-danger.mv-breadcrumb__item--active:not(:disabled):not(.disabled):focus, .show > .btn-danger.dropdown-toggle:focus, .mv-show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger.mv-breadcrumb__item--active:not(:disabled):not(.disabled), .show > .btn-outline-danger.dropdown-toggle, .mv-show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .btn-outline-danger.mv-breadcrumb__item--active:not(:disabled):not(.disabled):focus, .show > .btn-outline-danger.dropdown-toggle:focus, .mv-show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-flat-danger {
  padding: 0px;
  color: #dc3545;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.btn-flat-danger:hover {
  background-color: #fff;
}
.btn-flat-danger:focus, .btn-flat-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-flat-danger:disabled:hover {
  color: #dc3545;
}

.btn-brand {
  color: #fff;
  background-color: #7AB317;
  border-color: #7AB317;
}
.btn-brand:hover {
  color: #fff;
  background-color: #639113;
  border-color: #5b8611;
}
.btn-brand:focus, .btn-brand.focus {
  box-shadow: 0 0 0 0.2rem rgba(142, 190, 58, 0.5);
}
.btn-brand.disabled, .btn-brand:disabled {
  color: #fff;
  background-color: #7AB317;
  border-color: #7AB317;
}
.btn-brand:not(:disabled):not(.disabled):active, .btn-brand:not(:disabled):not(.disabled).active, .btn-brand.mv-breadcrumb__item--active:not(:disabled):not(.disabled), .show > .btn-brand.dropdown-toggle, .mv-show > .btn-brand.dropdown-toggle {
  color: #fff;
  background-color: #5b8611;
  border-color: #537b10;
}
.btn-brand:not(:disabled):not(.disabled):active:focus, .btn-brand:not(:disabled):not(.disabled).active:focus, .btn-brand.mv-breadcrumb__item--active:not(:disabled):not(.disabled):focus, .show > .btn-brand.dropdown-toggle:focus, .mv-show > .btn-brand.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(142, 190, 58, 0.5);
}

.btn-outline-brand {
  color: #7AB317;
  border-color: #7AB317;
}
.btn-outline-brand:hover {
  color: #fff;
  background-color: #7AB317;
  border-color: #7AB317;
}
.btn-outline-brand:focus, .btn-outline-brand.focus {
  box-shadow: 0 0 0 0.2rem rgba(122, 179, 23, 0.5);
}
.btn-outline-brand.disabled, .btn-outline-brand:disabled {
  color: #7AB317;
  background-color: transparent;
}
.btn-outline-brand:not(:disabled):not(.disabled):active, .btn-outline-brand:not(:disabled):not(.disabled).active, .btn-outline-brand.mv-breadcrumb__item--active:not(:disabled):not(.disabled), .show > .btn-outline-brand.dropdown-toggle, .mv-show > .btn-outline-brand.dropdown-toggle {
  color: #fff;
  background-color: #7AB317;
  border-color: #7AB317;
}
.btn-outline-brand:not(:disabled):not(.disabled):active:focus, .btn-outline-brand:not(:disabled):not(.disabled).active:focus, .btn-outline-brand.mv-breadcrumb__item--active:not(:disabled):not(.disabled):focus, .show > .btn-outline-brand.dropdown-toggle:focus, .mv-show > .btn-outline-brand.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(122, 179, 23, 0.5);
}

.btn-flat-brand {
  padding: 0px;
  color: #7AB317;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.btn-flat-brand:hover {
  background-color: #fff;
}
.btn-flat-brand:focus, .btn-flat-brand.focus {
  box-shadow: 0 0 0 0.2rem rgba(122, 179, 23, 0.5);
}
.btn-flat-brand:disabled:hover {
  color: #7AB317;
}

/* customization of bootstrap btn */
.mv-btn:not(:disabled) {
  cursor: pointer !important;
}
.mv-btn:not(:disabled) * {
  cursor: pointer !important;
}

.mv-btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
}
.mv-btn__text {
  display: inline;
  margin: 0;
  white-space: nowrap;
}
.mv-btn--icon .mv-btn__icon {
  display: flex;
  align-content: center;
}
.mv-btn__icon:not(:only-child) {
  padding: 0 0.57em 0 0;
}
.mv-btn--icon {
  padding: 0.375rem;
}
.mv-btn--icon.btn-sm, .btn-group-sm > .mv-btn--icon.btn, .btn-group-sm > .mv-btn--icon.mv-icon-btn {
  padding: 0.25rem;
}
.mv-btn--icon.btn-lg, .btn-group-lg > .mv-btn--icon.btn, .btn-group-lg > .mv-btn--icon.mv-icon-btn {
  padding: 0.5rem;
}
.mv-btn--loading > .mv-btn__text, .mv-btn--loading > .mv-btn__icon {
  visibility: hidden;
}
.mv-btn--loading .mv-btn__loader {
  position: absolute;
  left: 50%;
  display: inline-flex;
  align-items: center;
}
.mv-btn--loading .mv-btn__loader .mv-btn__centerer {
  position: relative;
  left: -50%;
}

/* button additional sizes */
.btn-tiny {
  padding: 1px 1px;
  font-size: 0.875rem;
  line-height: 0.875rem;
  border-radius: 0.14rem;
}

/* Dropdown button */
.mv-btn-ddown, .mv-btn-ddown--open {
  /* trick to not shift the layout when bolding inside element */
  /* inner actions styling based on button theme colors */
  /* on loading, all menu is void and spinner in the middle */
  /* on loading, all menu is void and spinner in the middle */
  /* on loading, all menu is void and spinner in the middle */
  /* on loading, all menu is void and spinner in the middle */
}
.mv-btn-ddown__menu {
  position: absolute;
  display: none !important;
  background-color: #fff;
  z-index: 99;
}
.mv-btn-ddown__item::after {
  content: attr(data-text);
  content: attr(data-text)/"";
  height: 0;
  visibility: hidden;
  overflow: hidden;
  -webkit-user-select: none;
          user-select: none;
  pointer-events: none;
  font-weight: 700;
}
@media speech {
  .mv-btn-ddown__item::after {
    display: none;
  }
}
.mv-btn-ddown__icon-select {
  padding-left: 0.75rem;
}
.mv-btn-ddown.mv-btn-ddown--open .btn-outline-primary, .mv-btn-ddown--open .btn-outline-primary {
  background-color: #e94e1b;
  color: #fff;
}
.mv-btn-ddown .btn-primary + .mv-btn-ddown__menu, .mv-btn-ddown--open .btn-primary + .mv-btn-ddown__menu, .mv-btn-ddown--outline .btn-outline-primary + .mv-btn-ddown__menu {
  border-radius: 0.3rem;
  border: none;
  padding: 0;
}
.mv-btn-ddown .btn-primary + .mv-btn-ddown__menu .mv-btn-ddown__item, .mv-btn-ddown--open .btn-primary + .mv-btn-ddown__menu .mv-btn-ddown__item, .mv-btn-ddown--outline .btn-outline-primary + .mv-btn-ddown__menu .mv-btn-ddown__item {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(233, 78, 27, 0.08);
  color: #2c2c2c;
  padding: 0.625rem 1.14rem;
  border: 0.13rem solid transparent;
}
.mv-btn-ddown .btn-primary + .mv-btn-ddown__menu .mv-btn-ddown__item:hover, .mv-btn-ddown--open .btn-primary + .mv-btn-ddown__menu .mv-btn-ddown__item:hover, .mv-btn-ddown--outline .btn-outline-primary + .mv-btn-ddown__menu .mv-btn-ddown__item:hover {
  border: 0.13rem solid #e94e1b;
  color: #e94e1b;
  background-color: rgba(255, 255, 255, 0.7);
  font-weight: bolder;
}
.mv-btn-ddown .btn-primary + .mv-btn-ddown__menu .mv-btn-ddown__item--first, .mv-btn-ddown--open .btn-primary + .mv-btn-ddown__menu .mv-btn-ddown__item--first, .mv-btn-ddown--outline .btn-outline-primary + .mv-btn-ddown__menu .mv-btn-ddown__item--first {
  border-radius: 0.3rem 0.3rem 0 0;
}
.mv-btn-ddown .btn-primary + .mv-btn-ddown__menu .mv-btn-ddown__item--last, .mv-btn-ddown--open .btn-primary + .mv-btn-ddown__menu .mv-btn-ddown__item--last, .mv-btn-ddown--outline .btn-outline-primary + .mv-btn-ddown__menu .mv-btn-ddown__item--last {
  border-radius: 0 0 0.3rem 0.3rem;
}
.mv-btn-ddown .btn-primary + .mv-btn-ddown__menu .mv-btn-ddown__divider, .mv-btn-ddown--open .btn-primary + .mv-btn-ddown__menu .mv-btn-ddown__divider, .mv-btn-ddown--outline .btn-outline-primary + .mv-btn-ddown__menu .mv-btn-ddown__divider {
  margin: 0;
  border-top-width: 0.15rem;
  border-top-color: #fff;
}
.mv-btn-ddown--loading .btn-primary + .mv-btn-ddown__menu .mv-btn-ddown__divider, .mv-btn-ddown--loading .btn-outline-primary + .mv-btn-ddown__menu .mv-btn-ddown__divider {
  border-top-color: rgba(233, 78, 27, 0.08);
}
.mv-btn-ddown--loading .btn-primary + .mv-btn-ddown__menu .mv-btn__loader, .mv-btn-ddown--loading .btn-outline-primary + .mv-btn-ddown__menu .mv-btn__loader {
  top: 50%;
}
.mv-btn-ddown--loading .btn-primary + .mv-btn-ddown__menu .mv-btn__loader .mv-btn__centerer, .mv-btn-ddown--loading .btn-outline-primary + .mv-btn-ddown__menu .mv-btn__loader .mv-btn__centerer {
  top: -9.6px;
}
.mv-btn-ddown--loading .btn-primary + .mv-btn-ddown__menu .mv-btn-ddown__item:hover, .mv-btn-ddown--loading .btn-primary + .mv-btn-ddown__menu, .mv-btn-ddown--loading .btn-outline-primary + .mv-btn-ddown__menu .mv-btn-ddown__item:hover, .mv-btn-ddown--loading .btn-outline-primary + .mv-btn-ddown__menu {
  pointer-events: none;
}
.mv-btn-ddown.mv-btn-ddown--open .btn-outline-secondary, .mv-btn-ddown--open .btn-outline-secondary {
  background-color: #3f9aaa;
  color: #fff;
}
.mv-btn-ddown .btn-secondary + .mv-btn-ddown__menu, .mv-btn-ddown--open .btn-secondary + .mv-btn-ddown__menu, .mv-btn-ddown--outline .btn-outline-secondary + .mv-btn-ddown__menu {
  border-radius: 0.3rem;
  border: none;
  padding: 0;
}
.mv-btn-ddown .btn-secondary + .mv-btn-ddown__menu .mv-btn-ddown__item, .mv-btn-ddown--open .btn-secondary + .mv-btn-ddown__menu .mv-btn-ddown__item, .mv-btn-ddown--outline .btn-outline-secondary + .mv-btn-ddown__menu .mv-btn-ddown__item {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(63, 154, 170, 0.08);
  color: #2c2c2c;
  padding: 0.625rem 1.14rem;
  border: 0.13rem solid transparent;
}
.mv-btn-ddown .btn-secondary + .mv-btn-ddown__menu .mv-btn-ddown__item:hover, .mv-btn-ddown--open .btn-secondary + .mv-btn-ddown__menu .mv-btn-ddown__item:hover, .mv-btn-ddown--outline .btn-outline-secondary + .mv-btn-ddown__menu .mv-btn-ddown__item:hover {
  border: 0.13rem solid #3f9aaa;
  color: #3f9aaa;
  background-color: rgba(255, 255, 255, 0.7);
  font-weight: bolder;
}
.mv-btn-ddown .btn-secondary + .mv-btn-ddown__menu .mv-btn-ddown__item--first, .mv-btn-ddown--open .btn-secondary + .mv-btn-ddown__menu .mv-btn-ddown__item--first, .mv-btn-ddown--outline .btn-outline-secondary + .mv-btn-ddown__menu .mv-btn-ddown__item--first {
  border-radius: 0.3rem 0.3rem 0 0;
}
.mv-btn-ddown .btn-secondary + .mv-btn-ddown__menu .mv-btn-ddown__item--last, .mv-btn-ddown--open .btn-secondary + .mv-btn-ddown__menu .mv-btn-ddown__item--last, .mv-btn-ddown--outline .btn-outline-secondary + .mv-btn-ddown__menu .mv-btn-ddown__item--last {
  border-radius: 0 0 0.3rem 0.3rem;
}
.mv-btn-ddown .btn-secondary + .mv-btn-ddown__menu .mv-btn-ddown__divider, .mv-btn-ddown--open .btn-secondary + .mv-btn-ddown__menu .mv-btn-ddown__divider, .mv-btn-ddown--outline .btn-outline-secondary + .mv-btn-ddown__menu .mv-btn-ddown__divider {
  margin: 0;
  border-top-width: 0.15rem;
  border-top-color: #fff;
}
.mv-btn-ddown--loading .btn-secondary + .mv-btn-ddown__menu .mv-btn-ddown__divider, .mv-btn-ddown--loading .btn-outline-secondary + .mv-btn-ddown__menu .mv-btn-ddown__divider {
  border-top-color: rgba(63, 154, 170, 0.08);
}
.mv-btn-ddown--loading .btn-secondary + .mv-btn-ddown__menu .mv-btn__loader, .mv-btn-ddown--loading .btn-outline-secondary + .mv-btn-ddown__menu .mv-btn__loader {
  top: 50%;
}
.mv-btn-ddown--loading .btn-secondary + .mv-btn-ddown__menu .mv-btn__loader .mv-btn__centerer, .mv-btn-ddown--loading .btn-outline-secondary + .mv-btn-ddown__menu .mv-btn__loader .mv-btn__centerer {
  top: -9.6px;
}
.mv-btn-ddown--loading .btn-secondary + .mv-btn-ddown__menu .mv-btn-ddown__item:hover, .mv-btn-ddown--loading .btn-secondary + .mv-btn-ddown__menu, .mv-btn-ddown--loading .btn-outline-secondary + .mv-btn-ddown__menu .mv-btn-ddown__item:hover, .mv-btn-ddown--loading .btn-outline-secondary + .mv-btn-ddown__menu {
  pointer-events: none;
}
.mv-btn-ddown.mv-btn-ddown--open .btn-outline-danger, .mv-btn-ddown--open .btn-outline-danger {
  background-color: #dc3545;
  color: #fff;
}
.mv-btn-ddown .btn-danger + .mv-btn-ddown__menu, .mv-btn-ddown--open .btn-danger + .mv-btn-ddown__menu, .mv-btn-ddown--outline .btn-outline-danger + .mv-btn-ddown__menu {
  border-radius: 0.3rem;
  border: none;
  padding: 0;
}
.mv-btn-ddown .btn-danger + .mv-btn-ddown__menu .mv-btn-ddown__item, .mv-btn-ddown--open .btn-danger + .mv-btn-ddown__menu .mv-btn-ddown__item, .mv-btn-ddown--outline .btn-outline-danger + .mv-btn-ddown__menu .mv-btn-ddown__item {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(220, 53, 69, 0.08);
  color: #2c2c2c;
  padding: 0.625rem 1.14rem;
  border: 0.13rem solid transparent;
}
.mv-btn-ddown .btn-danger + .mv-btn-ddown__menu .mv-btn-ddown__item:hover, .mv-btn-ddown--open .btn-danger + .mv-btn-ddown__menu .mv-btn-ddown__item:hover, .mv-btn-ddown--outline .btn-outline-danger + .mv-btn-ddown__menu .mv-btn-ddown__item:hover {
  border: 0.13rem solid #dc3545;
  color: #dc3545;
  background-color: rgba(255, 255, 255, 0.7);
  font-weight: bolder;
}
.mv-btn-ddown .btn-danger + .mv-btn-ddown__menu .mv-btn-ddown__item--first, .mv-btn-ddown--open .btn-danger + .mv-btn-ddown__menu .mv-btn-ddown__item--first, .mv-btn-ddown--outline .btn-outline-danger + .mv-btn-ddown__menu .mv-btn-ddown__item--first {
  border-radius: 0.3rem 0.3rem 0 0;
}
.mv-btn-ddown .btn-danger + .mv-btn-ddown__menu .mv-btn-ddown__item--last, .mv-btn-ddown--open .btn-danger + .mv-btn-ddown__menu .mv-btn-ddown__item--last, .mv-btn-ddown--outline .btn-outline-danger + .mv-btn-ddown__menu .mv-btn-ddown__item--last {
  border-radius: 0 0 0.3rem 0.3rem;
}
.mv-btn-ddown .btn-danger + .mv-btn-ddown__menu .mv-btn-ddown__divider, .mv-btn-ddown--open .btn-danger + .mv-btn-ddown__menu .mv-btn-ddown__divider, .mv-btn-ddown--outline .btn-outline-danger + .mv-btn-ddown__menu .mv-btn-ddown__divider {
  margin: 0;
  border-top-width: 0.15rem;
  border-top-color: #fff;
}
.mv-btn-ddown--loading .btn-danger + .mv-btn-ddown__menu .mv-btn-ddown__divider, .mv-btn-ddown--loading .btn-outline-danger + .mv-btn-ddown__menu .mv-btn-ddown__divider {
  border-top-color: rgba(220, 53, 69, 0.08);
}
.mv-btn-ddown--loading .btn-danger + .mv-btn-ddown__menu .mv-btn__loader, .mv-btn-ddown--loading .btn-outline-danger + .mv-btn-ddown__menu .mv-btn__loader {
  top: 50%;
}
.mv-btn-ddown--loading .btn-danger + .mv-btn-ddown__menu .mv-btn__loader .mv-btn__centerer, .mv-btn-ddown--loading .btn-outline-danger + .mv-btn-ddown__menu .mv-btn__loader .mv-btn__centerer {
  top: -9.6px;
}
.mv-btn-ddown--loading .btn-danger + .mv-btn-ddown__menu .mv-btn-ddown__item:hover, .mv-btn-ddown--loading .btn-danger + .mv-btn-ddown__menu, .mv-btn-ddown--loading .btn-outline-danger + .mv-btn-ddown__menu .mv-btn-ddown__item:hover, .mv-btn-ddown--loading .btn-outline-danger + .mv-btn-ddown__menu {
  pointer-events: none;
}
.mv-btn-ddown.mv-btn-ddown--open .btn-outline-brand, .mv-btn-ddown--open .btn-outline-brand {
  background-color: #7AB317;
  color: #fff;
}
.mv-btn-ddown .btn-brand + .mv-btn-ddown__menu, .mv-btn-ddown--open .btn-brand + .mv-btn-ddown__menu, .mv-btn-ddown--outline .btn-outline-brand + .mv-btn-ddown__menu {
  border-radius: 0.3rem;
  border: none;
  padding: 0;
}
.mv-btn-ddown .btn-brand + .mv-btn-ddown__menu .mv-btn-ddown__item, .mv-btn-ddown--open .btn-brand + .mv-btn-ddown__menu .mv-btn-ddown__item, .mv-btn-ddown--outline .btn-outline-brand + .mv-btn-ddown__menu .mv-btn-ddown__item {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(122, 179, 23, 0.08);
  color: #2c2c2c;
  padding: 0.625rem 1.14rem;
  border: 0.13rem solid transparent;
}
.mv-btn-ddown .btn-brand + .mv-btn-ddown__menu .mv-btn-ddown__item:hover, .mv-btn-ddown--open .btn-brand + .mv-btn-ddown__menu .mv-btn-ddown__item:hover, .mv-btn-ddown--outline .btn-outline-brand + .mv-btn-ddown__menu .mv-btn-ddown__item:hover {
  border: 0.13rem solid #7AB317;
  color: #7AB317;
  background-color: rgba(255, 255, 255, 0.7);
  font-weight: bolder;
}
.mv-btn-ddown .btn-brand + .mv-btn-ddown__menu .mv-btn-ddown__item--first, .mv-btn-ddown--open .btn-brand + .mv-btn-ddown__menu .mv-btn-ddown__item--first, .mv-btn-ddown--outline .btn-outline-brand + .mv-btn-ddown__menu .mv-btn-ddown__item--first {
  border-radius: 0.3rem 0.3rem 0 0;
}
.mv-btn-ddown .btn-brand + .mv-btn-ddown__menu .mv-btn-ddown__item--last, .mv-btn-ddown--open .btn-brand + .mv-btn-ddown__menu .mv-btn-ddown__item--last, .mv-btn-ddown--outline .btn-outline-brand + .mv-btn-ddown__menu .mv-btn-ddown__item--last {
  border-radius: 0 0 0.3rem 0.3rem;
}
.mv-btn-ddown .btn-brand + .mv-btn-ddown__menu .mv-btn-ddown__divider, .mv-btn-ddown--open .btn-brand + .mv-btn-ddown__menu .mv-btn-ddown__divider, .mv-btn-ddown--outline .btn-outline-brand + .mv-btn-ddown__menu .mv-btn-ddown__divider {
  margin: 0;
  border-top-width: 0.15rem;
  border-top-color: #fff;
}
.mv-btn-ddown--loading .btn-brand + .mv-btn-ddown__menu .mv-btn-ddown__divider, .mv-btn-ddown--loading .btn-outline-brand + .mv-btn-ddown__menu .mv-btn-ddown__divider {
  border-top-color: rgba(122, 179, 23, 0.08);
}
.mv-btn-ddown--loading .btn-brand + .mv-btn-ddown__menu .mv-btn__loader, .mv-btn-ddown--loading .btn-outline-brand + .mv-btn-ddown__menu .mv-btn__loader {
  top: 50%;
}
.mv-btn-ddown--loading .btn-brand + .mv-btn-ddown__menu .mv-btn__loader .mv-btn__centerer, .mv-btn-ddown--loading .btn-outline-brand + .mv-btn-ddown__menu .mv-btn__loader .mv-btn__centerer {
  top: -9.6px;
}
.mv-btn-ddown--loading .btn-brand + .mv-btn-ddown__menu .mv-btn-ddown__item:hover, .mv-btn-ddown--loading .btn-brand + .mv-btn-ddown__menu, .mv-btn-ddown--loading .btn-outline-brand + .mv-btn-ddown__menu .mv-btn-ddown__item:hover, .mv-btn-ddown--loading .btn-outline-brand + .mv-btn-ddown__menu {
  pointer-events: none;
}
.mv-btn-ddown--loading .mv-btn-ddown__item .mv-btn__text {
  visibility: hidden;
}
.mv-btn-ddown--loading .mv-btn__loader {
  position: absolute;
  left: 50%;
  top: 8px;
}
.mv-btn-ddown--loading .mv-btn__loader .mv-btn__centerer {
  position: relative;
  left: -50%;
}
.mv-btn-ddown--open .mv-btn-ddown__menu {
  display: block !important;
}

.mv-icon-btn:hover {
  background-color: #fff;
}

.bg-primary {
  background-color: #e94e1b !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #bf3d12 !important;
}

.bg-secondary {
  background-color: #3f9aaa !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #317885 !important;
}

.bg-success {
  background-color: #6b9c14 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #4c6f0e !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-cat-1 {
  background-color: #26c0c7 !important;
}

a.bg-cat-1:hover, a.bg-cat-1:focus,
button.bg-cat-1:hover,
button.bg-cat-1:focus {
  background-color: #1e979c !important;
}

.bg-cat-2 {
  background-color: #5151d3 !important;
}

a.bg-cat-2:hover, a.bg-cat-2:focus,
button.bg-cat-2:hover,
button.bg-cat-2:focus {
  background-color: #3131c0 !important;
}

.bg-cat-3 {
  background-color: #e68619 !important;
}

a.bg-cat-3:hover, a.bg-cat-3:focus,
button.bg-cat-3:hover,
button.bg-cat-3:focus {
  background-color: #b86b14 !important;
}

.bg-cat-4 {
  background-color: #D83790 !important;
}

a.bg-cat-4:hover, a.bg-cat-4:focus,
button.bg-cat-4:hover,
button.bg-cat-4:focus {
  background-color: #b82476 !important;
}

.bg-cat-5 {
  background-color: #2680eb !important;
}

a.bg-cat-5:hover, a.bg-cat-5:focus,
button.bg-cat-5:hover,
button.bg-cat-5:focus {
  background-color: #1367cb !important;
}

.bg-cat-6 {
  background-color: #9bec54 !important;
}

a.bg-cat-6:hover, a.bg-cat-6:focus,
button.bg-cat-6:hover,
button.bg-cat-6:focus {
  background-color: #80e726 !important;
}

.bg-cat-7 {
  background-color: #268E6C !important;
}

a.bg-cat-7:hover, a.bg-cat-7:focus,
button.bg-cat-7:hover,
button.bg-cat-7:focus {
  background-color: #1b664d !important;
}

.bg-cat-8 {
  background-color: #B79900 !important;
}

a.bg-cat-8:hover, a.bg-cat-8:focus,
button.bg-cat-8:hover,
button.bg-cat-8:focus {
  background-color: #846e00 !important;
}

.mv-label-value--row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.mv-label-value--column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.mv-label-value--column--fit-content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.mv-label-value__label {
  margin-bottom: 0;
  font-weight: 700;
}
.mv-label-value__label--sm {
  font-size: 0.8rem;
}
.mv-label-value__label--nm {
  font-size: 1rem;
}
.mv-label-value__label--bg {
  font-size: 1.25rem;
}

.mv-text {
  margin-bottom: 0;
}
.mv-text--sm {
  font-size: 0.8rem;
}
.mv-text--nm {
  font-size: 1rem;
}
.mv-text--bg {
  font-size: 1.25rem;
}

.mv-label-value--2-8 .mv-label-value__label {
  flex: 2 1;
}
.mv-label-value--2-8 .mv-label-value__text {
  flex: 8 1;
}

.mv-label-value--3-7 .mv-label-value__label {
  flex: 3 1;
}
.mv-label-value--3-7 .mv-label-value__text {
  flex: 7 1;
}

.mv-label-value--4-6 .mv-label-value__label {
  flex: 4 1;
}
.mv-label-value--4-6 .mv-label-value__text {
  flex: 6 1;
}

.mv-label-value--5-5 .mv-label-value__label {
  flex: 5 1;
}
.mv-label-value--5-5 .mv-label-value__text {
  flex: 5 1;
}

.mv-modal {
  background-color: rgba(44, 44, 44, 0.5);
  z-index: 1000;
  margin: 0px;
}

.mv-modal-dialog {
  max-width: 100%;
}
.mv-modal.mv-fade .mv-modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
          transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .mv-modal.mv-fade .mv-modal-dialog {
    transition: none;
  }
}
.mv-modal.mv-show .mv-modal-dialog {
  -webkit-transform: none;
          transform: none;
}

.mv-fade {
  opacity: 0;
}

.mv-fade.mv-show {
  opacity: 1;
}

.mv-modal-content {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 90vh;
  border: none;
}

.mv-modal-header {
  background-color: #f5f5f5;
  align-items: center;
  border-bottom: none;
}
.mv-modal-header h5 {
  margin: 0;
}
.mv-modal-header .mv-btn.btn-outline-secondary {
  border: none;
}
.mv-modal-header .mv-btn.btn-outline-secondary:hover {
  background-color: transparent;
  color: inherit;
}

.mv-modal-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.mv-modal-footer__left {
  margin: -8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.mv-modal-footer__left > * {
  margin: 8px;
}

.mv-modal-footer__right {
  margin: -8px;
  display: flex;
  justify-content: flex-end;
  align-content: center;
}
.mv-modal-footer__right > * {
  margin: 8px;
}

.alert {
  margin-bottom: 0;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}
.alert-danger a {
  color: #921925;
  text-decoration: underline;
  font-weight: 700;
}

.alert {
  margin-bottom: 0;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}
.alert-warning a {
  color: #a07800;
  text-decoration: underline;
  font-weight: 700;
}

.alert {
  margin-bottom: 0;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}
.alert-info a {
  color: #0c525d;
  text-decoration: underline;
  font-weight: 700;
}

.mv-custom-select {
  -moz-appearance: none;
  -webkit-appearance: none;
}

.mv-form-group {
  display: flex;
  flex-direction: column;
  /* Remove the rounded corners */
  /* Hide the cancel button */
  /* Hide the magnifying glass */
}
.mv-form-group .mv-multiselect {
  text-overflow: ellipsis;
  background-color: #fff;
}
.mv-form-group .mv-multiselect__menu {
  position: absolute;
  visibility: hidden;
  display: block;
  background-color: #fff;
  z-index: 99;
  width: 100%;
  border: 2px solid #b3b3b3;
  border-radius: 4px;
  margin-top: 2px;
}
.mv-form-group .mv-multiselect__menu input,
.mv-form-group .mv-multiselect__menu label {
  margin: 0;
}
.mv-form-group .mv-multiselect--open ~ .mv-multiselect__menu .mv-multiselect__item:not(:first-child) {
  border-top: 2px solid #b3b3b3;
}
.mv-form-group .mv-multiselect--open ~ .mv-multiselect__menu .mv-multiselect__item label {
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
}
.mv-form-group .mv-multiselect--open ~ .mv-multiselect__menu .mv-multiselect__item label:hover,
.mv-form-group .mv-multiselect--open ~ .mv-multiselect__menu .mv-multiselect__item input:checked + label {
  background-color: rgba(233, 78, 27, 0.25);
}
.mv-form-group .mv-multiselect--open ~ .mv-multiselect__menu {
  visibility: visible;
}
.mv-form-group label {
  color: #000;
  line-height: 1;
}
.mv-form-group .required {
  color: #e94e1b;
}
.mv-form-group .form-control:disabled, .mv-form-group .mv-input-group__form-control:disabled,
.mv-form-group .mv-form-control:disabled, .mv-form-group textarea:disabled {
  color: #6e6e6e;
}
.mv-form-group--disabled label {
  color: rgba(0, 0, 0, 0.3);
}
.mv-form-group input::-webkit-calendar-picker-indicator {
  opacity: 0;
  position: absolute;
  top: 0;
  left: -10%;
  width: 110%;
  height: 110%;
  -webkit-appearance: none;
}
.mv-form-group input[type=search] {
  -webkit-appearance: none;
}
.mv-form-group ::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.mv-form-group ::-webkit-search-results-button {
  -webkit-appearance: none;
}

.mv-datepicker-wrapper {
  flex: 1 1 auto;
}

.react-datepicker__tab-loop .mv-datepicker-popper {
  z-index: 1001;
}

.mv-form-file {
  width: -webkit-max-content;
  width: max-content;
  position: relative;
  overflow: hidden;
}
.mv-form-file input[type=file] {
  position: relative;
  margin-top: 24px;
  margin-bottom: 12px;
}
.mv-form-file input[type=file]::file-selector-button {
  display: none;
}
.mv-form-file .mv-btn {
  cursor: pointer;
}

.mv-radio input,
.mv-checkbox input {
  display: none;
}
.mv-radio input:checked + label .icon,
.mv-checkbox input:checked + label .icon {
  color: #e94e1b;
}
.mv-radio input + label,
.mv-checkbox input + label {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.mv-radio input + label .icon,
.mv-checkbox input + label .icon {
  color: #000;
}
.mv-radio input + label p,
.mv-checkbox input + label p {
  margin-left: 8px;
}
.mv-radio input + label .icon--selected,
.mv-checkbox input + label .icon--selected {
  display: none;
}
.mv-radio input + label .icon--unselected,
.mv-checkbox input + label .icon--unselected {
  color: #000;
  display: block;
}
.mv-radio input:checked + label .icon--selected,
.mv-checkbox input:checked + label .icon--selected {
  display: block;
}
.mv-radio input:checked + label .icon--unselected,
.mv-checkbox input:checked + label .icon--unselected {
  display: none;
}
.mv-radio__children,
.mv-checkbox__children {
  margin-left: 24px;
}

.mv-input-group__icon--disabled {
  background-color: #eaeaea;
}

.mv-input-group__form-control:disabled.mv-form-control[readonly],
.mv-form-control:disabled.mv-form-control[readonly] {
  color: #000;
}
.mv-input-group__form-control:disabled.mv-input-group__form-control[readonly],
.mv-form-control:disabled.mv-input-group__form-control[readonly] {
  color: #000;
}

.mv-input-group > .mv-form-control:not(:last-child):only-of-type {
  border-radius: 0 0.3rem 0.3rem 0;
}

.mv-input-group {
  flex-wrap: wrap;
}
.mv-input-group .mv-input-group__form-control:only-of-type {
  border-radius: 0 0.3rem 0.3rem 0;
}
.mv-input-group .input-group-prepend:only-of-type > .mv-input-group__icon, .mv-input-group .mv-input-group__prepend:only-of-type > .mv-input-group__icon {
  border-radius: 0.3rem 0 0 0.3rem;
}
.mv-input-group__form-control:disabled + .input-group-prepend:only-of-type > .mv-input-group__icon, .mv-input-group__form-control:disabled + .mv-input-group__prepend:only-of-type > .mv-input-group__icon {
  background-color: #eaeaea;
}
.mv-input-group .mv-form-control:only-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.mv-form__select {
  position: relative;
  flex-grow: 100;
}
.mv-form__select select:disabled + .mv-select-icon:only-of-type {
  color: #b3b3b3;
}
.mv-form__select select:disabled + .input-group-prepend:only-of-type > .mv-input-group__icon, .mv-form__select select:disabled + .mv-input-group__prepend:only-of-type > .mv-input-group__icon {
  background-color: #eaeaea;
}

.mv-custom-select {
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
}

.mv-form-control-input-container {
  position: relative;
  flex-grow: 100;
}

.mv-input-color > input {
  pointer-events: none;
}

.mv-select__icon,
.mv-form-control__password-icon {
  padding: 0.5rem 1rem 0.5rem 0;
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  right: 0;
  height: 100%;
  color: #6e6e6e;
  cursor: pointer;
}

.mv-select__icon {
  cursor: default;
  pointer-events: none;
}

/* .is-info ~ .info-feedback {
  display: block;
}

.valid-feedback {

}

.invalid-feedback {

} */
.valid-feedback {
  visibility: hidden;
  display: block;
}

.is-valid ~ .valid-feedback {
  visibility: visible;
}

.invalid-feedback {
  visibility: hidden;
  display: block;
}

.is-invalid ~ .invalid-feedback {
  visibility: visible;
}

.info-feedback {
  visibility: hidden;
  display: block;
}

.is-info ~ .info-feedback {
  visibility: visible;
}

.mv-input-group__prepend + div {
  flex: 1 1 auto;
  width: 1%;
}

.planner .planner__head,
.planner .planner__shift-head {
  background-color: #f5f5f5;
  padding: 0.75rem;
}
.planner .planner__head .date-pagination__current h3,
.planner .planner__shift-head .date-pagination__current h3 {
  margin: 0;
}
.planner .planner__shift-head {
  text-align: center;
}
.planner .planner__shift {
  margin-bottom: 40px;
}
.planner .planner__week {
  position: relative;
}
.planner .planner__week .week__grid {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.planner .planner__week .week__grid .grid__line {
  z-index: 1;
  border-bottom: 1px solid #f5f5f5;
}
.planner .planner__slot {
  background-color: rgba(233, 78, 27, 0.16);
  color: #000;
  border-radius: 0.28rem;
  border: none;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.planner .planner__slot:hover:not(.planner__slot--readonly):not(.planner__slot--past) {
  cursor: pointer;
  border: 3px solid #e94e1b;
}
.planner .planner__slot--empty {
  background-color: rgba(255, 193, 7, 0.16);
  color: #ffc107;
  border: 2px dashed #ffc107;
}
.planner .planner__slot--empty:hover:not(.planner__slot--readonly):not(.planner__slot--past) {
  border: 3px dashed #ffc107;
}
.planner .planner__slot--empty:hover:not(.planner__slot--readonly):not(.planner__slot--past) > * {
  -webkit-transform: scale(2);
          transform: scale(2);
  transition: all ease-in 200ms;
}
.planner .planner__slot--empty.planner__slot--confirmed {
  background-color: rgba(255, 193, 7, 0.32);
  border: none;
  color: #000;
}
.planner .planner__slot--empty.planner__slot--confirmed:hover:not(.planner__slot--readonly):not(.planner__slot--past) {
  border: 3px solid #ffc107;
}
.planner .planner__slot--empty.planner__slot--confirmed:hover:not(.planner__slot--readonly):not(.planner__slot--past) > * {
  -webkit-transform: none;
          transform: none;
  transition: none;
}
.planner .planner__slot--confirmed {
  background-color: rgba(233, 78, 27, 0.32);
  border: none;
}
.planner .planner__slot--confirmed:hover:not(.planner__slot--readonly):not(.planner__slot--past) {
  border: 3px solid #e94e1b;
}
.planner .planner__slot--readonly {
  background-color: rgba(110, 110, 110, 0.32);
  border: none;
  color: #000;
}
.planner .planner__slot--readonly.planner__slot--confirmed {
  background-color: rgba(233, 78, 27, 0.16);
  border: none;
}
.planner .planner__slot--readonly.planner__slot--today.planner__slot--confirmed {
  background-color: rgba(233, 78, 27, 0.32);
  border: none;
}
.planner .planner__slot--past {
  color: #000 !important;
  background-color: rgba(110, 110, 110, 0.16) !important;
  border: none !important;
}
.planner .planner__badge {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: #212529;
  text-align: center;
  font-size: 0.8rem;
  border-radius: 0.28rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}
.planner .planner__badge > * {
  margin: 0 0.57em;
}
.planner .planner__badge--today, .planner .planner__badge--confirmed {
  background-color: #e94e1b;
  color: #fff;
  border: 1px solid #e94e1b;
}
.planner .planner__badge--next-confirmed {
  background-color: #eac0b3;
  color: #212529;
  border: 1px solid #eac0b3;
}
.planner .planner__day {
  height: 320px;
  display: flex;
  z-index: 2;
}
.planner .planner__day > * {
  flex: 1 1;
}
.planner .planner__dates button {
  font-size: 12px;
}
.planner .planner__dates h2 {
  padding: 0.25em;
}
.planner .planner__dates .date__today h3 {
  color: #e94e1b;
}
.planner .planner__dates .date__today h2 {
  background-color: #e94e1b;
  color: #fff;
  border-radius: 0.28rem;
}

.mv-calendar__head {
  display: flex;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding: 0.75rem;
  border-radius: 8px;
}

.mv-calendar_view--hidden {
  visibility: hidden;
}
.mv-calendar_day-cell--clickable {
  cursor: pointer;
}

.mv-badge {
  padding: 6px;
  border-radius: 4px;
  border-left-width: 0.245rem;
  border-left-style: solid;
  display: flex;
  flex-direction: column;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  font-size: 0.75rem;
}
.mv-badge > .mv-badge__text > .mv-badge__title {
  text-transform: uppercase;
  font-weight: 700;
}
.mv-badge > .mv-badge__text > .mv-badge__desc {
  font-size: 0.9em;
}

.mv-badge-cat-1 {
  background-color: rgba(38, 192, 199, 0.12);
  border-left-color: #26c0c7;
  color: #1e979c;
}

.mv-badge--calendar.mv-badge-cat-1:hover {
  background-color: rgba(38, 192, 199, 0.4);
  cursor: pointer;
}

.mv-badge--table.mv-badge-cat-1,
.mv-badge--notification.mv-badge-cat-1,
.mv-badge--circle.mv-badge-cat-1 {
  background-color: #26c0c7;
  color: #fff;
}

.mv-badge--sm > .mv-badge__text {
  color: inherit;
}
.mv-badge--sm > .mv-badge__text > .mv-badge__subtext {
  color: inherit;
}

.mv-badge-cat-2 {
  background-color: rgba(81, 81, 211, 0.12);
  border-left-color: #5151d3;
  color: #3131c0;
}

.mv-badge--calendar.mv-badge-cat-2:hover {
  background-color: rgba(81, 81, 211, 0.4);
  cursor: pointer;
}

.mv-badge--table.mv-badge-cat-2,
.mv-badge--notification.mv-badge-cat-2,
.mv-badge--circle.mv-badge-cat-2 {
  background-color: #5151d3;
  color: #fff;
}

.mv-badge--sm > .mv-badge__text {
  color: inherit;
}
.mv-badge--sm > .mv-badge__text > .mv-badge__subtext {
  color: inherit;
}

.mv-badge-cat-3 {
  background-color: rgba(230, 134, 25, 0.12);
  border-left-color: #e68619;
  color: #b86b14;
}

.mv-badge--calendar.mv-badge-cat-3:hover {
  background-color: rgba(230, 134, 25, 0.4);
  cursor: pointer;
}

.mv-badge--table.mv-badge-cat-3,
.mv-badge--notification.mv-badge-cat-3,
.mv-badge--circle.mv-badge-cat-3 {
  background-color: #e68619;
  color: #212529;
}

.mv-badge--sm > .mv-badge__text {
  color: inherit;
}
.mv-badge--sm > .mv-badge__text > .mv-badge__subtext {
  color: inherit;
}

.mv-badge-cat-4 {
  background-color: rgba(216, 55, 144, 0.12);
  border-left-color: #D83790;
  color: #b82476;
}

.mv-badge--calendar.mv-badge-cat-4:hover {
  background-color: rgba(216, 55, 144, 0.4);
  cursor: pointer;
}

.mv-badge--table.mv-badge-cat-4,
.mv-badge--notification.mv-badge-cat-4,
.mv-badge--circle.mv-badge-cat-4 {
  background-color: #D83790;
  color: #fff;
}

.mv-badge--sm > .mv-badge__text {
  color: inherit;
}
.mv-badge--sm > .mv-badge__text > .mv-badge__subtext {
  color: inherit;
}

.mv-badge-cat-5 {
  background-color: rgba(38, 128, 235, 0.12);
  border-left-color: #2680eb;
  color: #1367cb;
}

.mv-badge--calendar.mv-badge-cat-5:hover {
  background-color: rgba(38, 128, 235, 0.4);
  cursor: pointer;
}

.mv-badge--table.mv-badge-cat-5,
.mv-badge--notification.mv-badge-cat-5,
.mv-badge--circle.mv-badge-cat-5 {
  background-color: #2680eb;
  color: #fff;
}

.mv-badge--sm > .mv-badge__text {
  color: inherit;
}
.mv-badge--sm > .mv-badge__text > .mv-badge__subtext {
  color: inherit;
}

.mv-badge-cat-6 {
  background-color: rgba(155, 236, 84, 0.12);
  border-left-color: #9bec54;
  color: #80e726;
}

.mv-badge--calendar.mv-badge-cat-6:hover {
  background-color: rgba(155, 236, 84, 0.4);
  cursor: pointer;
}

.mv-badge--table.mv-badge-cat-6,
.mv-badge--notification.mv-badge-cat-6,
.mv-badge--circle.mv-badge-cat-6 {
  background-color: #9bec54;
  color: #212529;
}

.mv-badge--sm > .mv-badge__text {
  color: inherit;
}
.mv-badge--sm > .mv-badge__text > .mv-badge__subtext {
  color: inherit;
}

.mv-badge-cat-7 {
  background-color: rgba(38, 142, 108, 0.12);
  border-left-color: #268E6C;
  color: #1b664d;
}

.mv-badge--calendar.mv-badge-cat-7:hover {
  background-color: rgba(38, 142, 108, 0.4);
  cursor: pointer;
}

.mv-badge--table.mv-badge-cat-7,
.mv-badge--notification.mv-badge-cat-7,
.mv-badge--circle.mv-badge-cat-7 {
  background-color: #268E6C;
  color: #fff;
}

.mv-badge--sm > .mv-badge__text {
  color: inherit;
}
.mv-badge--sm > .mv-badge__text > .mv-badge__subtext {
  color: inherit;
}

.mv-badge-cat-8 {
  background-color: rgba(183, 153, 0, 0.12);
  border-left-color: #B79900;
  color: #846e00;
}

.mv-badge--calendar.mv-badge-cat-8:hover {
  background-color: rgba(183, 153, 0, 0.4);
  cursor: pointer;
}

.mv-badge--table.mv-badge-cat-8,
.mv-badge--notification.mv-badge-cat-8,
.mv-badge--circle.mv-badge-cat-8 {
  background-color: #B79900;
  color: #fff;
}

.mv-badge--sm > .mv-badge__text {
  color: inherit;
}
.mv-badge--sm > .mv-badge__text > .mv-badge__subtext {
  color: inherit;
}

.mv-badge-primary {
  background-color: rgba(233, 78, 27, 0.12);
  border-left-color: #e94e1b;
  color: #bf3d12;
}

.mv-badge--calendar.mv-badge-primary:hover {
  background-color: rgba(233, 78, 27, 0.4);
  cursor: pointer;
}

.mv-badge--table.mv-badge-primary,
.mv-badge--notification.mv-badge-primary,
.mv-badge--circle.mv-badge-primary {
  background-color: #e94e1b;
  color: #fff;
}

.mv-badge--sm > .mv-badge__text {
  color: inherit;
}
.mv-badge--sm > .mv-badge__text > .mv-badge__subtext {
  color: inherit;
}

.mv-badge-secondary {
  background-color: rgba(63, 154, 170, 0.12);
  border-left-color: #3f9aaa;
  color: #317885;
}

.mv-badge--calendar.mv-badge-secondary:hover {
  background-color: rgba(63, 154, 170, 0.4);
  cursor: pointer;
}

.mv-badge--table.mv-badge-secondary,
.mv-badge--notification.mv-badge-secondary,
.mv-badge--circle.mv-badge-secondary {
  background-color: #3f9aaa;
  color: #fff;
}

.mv-badge--sm > .mv-badge__text {
  color: inherit;
}
.mv-badge--sm > .mv-badge__text > .mv-badge__subtext {
  color: inherit;
}

.mv-badge-success {
  background-color: rgba(107, 156, 20, 0.12);
  border-left-color: #6b9c14;
  color: #4c6f0e;
}

.mv-badge--calendar.mv-badge-success:hover {
  background-color: rgba(107, 156, 20, 0.4);
  cursor: pointer;
}

.mv-badge--table.mv-badge-success,
.mv-badge--notification.mv-badge-success,
.mv-badge--circle.mv-badge-success {
  background-color: #6b9c14;
  color: #fff;
}

.mv-badge--sm > .mv-badge__text {
  color: inherit;
}
.mv-badge--sm > .mv-badge__text > .mv-badge__subtext {
  color: inherit;
}

.mv-badge-danger {
  background-color: rgba(220, 53, 69, 0.12);
  border-left-color: #dc3545;
  color: #bd2130;
}

.mv-badge--calendar.mv-badge-danger:hover {
  background-color: rgba(220, 53, 69, 0.4);
  cursor: pointer;
}

.mv-badge--table.mv-badge-danger,
.mv-badge--notification.mv-badge-danger,
.mv-badge--circle.mv-badge-danger {
  background-color: #dc3545;
  color: #fff;
}

.mv-badge--sm > .mv-badge__text {
  color: inherit;
}
.mv-badge--sm > .mv-badge__text > .mv-badge__subtext {
  color: inherit;
}

.mv-badge-warning {
  background-color: rgba(255, 193, 7, 0.12);
  border-left-color: #ffc107;
  color: #d39e00;
}

.mv-badge--calendar.mv-badge-warning:hover {
  background-color: rgba(255, 193, 7, 0.4);
  cursor: pointer;
}

.mv-badge--table.mv-badge-warning,
.mv-badge--notification.mv-badge-warning,
.mv-badge--circle.mv-badge-warning {
  background-color: #ffc107;
  color: #212529;
}

.mv-badge--sm > .mv-badge__text {
  color: inherit;
}
.mv-badge--sm > .mv-badge__text > .mv-badge__subtext {
  color: inherit;
}

.mv-badge-info {
  background-color: rgba(23, 162, 184, 0.12);
  border-left-color: #17a2b8;
  color: #117a8b;
}

.mv-badge--calendar.mv-badge-info:hover {
  background-color: rgba(23, 162, 184, 0.4);
  cursor: pointer;
}

.mv-badge--table.mv-badge-info,
.mv-badge--notification.mv-badge-info,
.mv-badge--circle.mv-badge-info {
  background-color: #17a2b8;
  color: #fff;
}

.mv-badge--sm > .mv-badge__text {
  color: inherit;
}
.mv-badge--sm > .mv-badge__text > .mv-badge__subtext {
  color: inherit;
}

.mv-badge-brand {
  background-color: rgba(122, 179, 23, 0.12);
  border-left-color: #7AB317;
  color: #5b8611;
}

.mv-badge--calendar.mv-badge-brand:hover {
  background-color: rgba(122, 179, 23, 0.4);
  cursor: pointer;
}

.mv-badge--table.mv-badge-brand,
.mv-badge--notification.mv-badge-brand,
.mv-badge--circle.mv-badge-brand {
  background-color: #7AB317;
  color: #fff;
}

.mv-badge--sm > .mv-badge__text {
  color: inherit;
}
.mv-badge--sm > .mv-badge__text > .mv-badge__subtext {
  color: inherit;
}

.mv-badge-default {
  background-color: rgba(110, 110, 110, 0.12);
  border-left-color: #6e6e6e;
  color: #555555;
}

.mv-badge--calendar.mv-badge-default:hover {
  background-color: rgba(110, 110, 110, 0.4);
  cursor: pointer;
}

.mv-badge--table.mv-badge-default,
.mv-badge--notification.mv-badge-default,
.mv-badge--circle.mv-badge-default {
  background-color: #6e6e6e;
  color: #fff;
}

.mv-badge--sm > .mv-badge__text {
  color: inherit;
}
.mv-badge--sm > .mv-badge__text > .mv-badge__subtext {
  color: inherit;
}

.mv-badge--sm {
  padding: 4px;
  font-size: 0.625rem;
}

.mv-badge--lg {
  padding: 8px;
  font-size: 1rem;
}

.mv-badge--calendar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1 1;
  color: #000;
}
.mv-badge--calendar > .mv-badge__text {
  width: 80%;
}
.mv-badge--calendar > .mv-badge__text > .mv-badge__title {
  text-transform: none;
}
.mv-badge--calendar > .mv-badge__text > .mv-badge__desc {
  color: #2c2c2c;
}
.mv-badge--calendar > .mv-badge__text p {
  max-width: calc(100% - 5px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mv.badge--table,
.mv-badge--circle,
.mv-badge--notification {
  border: none;
  padding: 4px;
  border-radius: 4px;
}

.mv-badge--table {
  padding: 1.5rem 0.625rem;
  border-radius: 0.245rem 0 0 0.245rem;
  min-width: 0;
}

.mv-badge--notification {
  font-weight: 700;
}

.mv-badge--circle {
  border-radius: 1.75rem;
  padding: 6px;
  min-width: 1.25rem;
  min-height: 1.25rem;
}

.mv-treeview-container {
  border: 2px solid #eaeaea;
  border-radius: 4px;
  padding: 24px 16px;
}
.mv-treeview-container__info {
  padding-bottom: 24px;
}
.mv-treeview-container__info-icon-selection, .mv-treeview-container__info-icon-flow {
  width: 32px;
  height: 32px;
  background-color: #eaeaea;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 4px;
}

.mv-treeview--scrollable {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}
.mv-treeview__rootlist {
  padding-left: 0;
}
.mv-treeview ul {
  list-style: none;
  margin-top: 0;
}
.mv-treeview .mv-node .mv-node__subtree {
  flex-basis: 100%;
}
.mv-treeview .mv-node .mv-node__toggle {
  cursor: pointer;
}
.mv-treeview .mv-node .mv-node__toggle,
.mv-treeview .mv-node .mv-node__home {
  flex-basis: 24px;
}
.mv-treeview .mv-node .mv-node__home {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.mv-treeview .mv-node > .mv-node__label,
.mv-treeview .mv-node > .mv-node__arrow-down {
  color: #000;
  background: #fff;
  width: -webkit-max-content;
  width: max-content;
  margin: 4px;
  border-style: solid;
  border-color: #6e6e6e;
  padding: 4px 8px;
  font-weight: 700;
  cursor: pointer;
}
.mv-treeview .mv-node > .mv-node__label.mv-node__label--disable {
  color: #b3b3b3;
}
.mv-treeview .mv-node > .mv-node__label {
  border-radius: 4px 0px 0px 4px;
}
.mv-treeview .mv-node > .mv-node__label {
  border-width: 0.13rem 0.065rem 0.13rem 0.13rem;
  border-style: solid;
  margin-right: 0px;
}
.mv-treeview .mv-node > .mv-node__label--no-arrow {
  border-width: 0.13rem;
  border-radius: 4px;
}
.mv-treeview .mv-node > .mv-node__arrow-down {
  border-radius: 0px 4px 4px 0px;
  border-width: 0.13rem 0.13rem 0.13rem 0.065rem;
  align-content: center;
  align-self: stretch;
  margin-left: 0px;
}
.mv-treeview .mv-node:not(.mv-node--root) ul {
  display: none;
}
.mv-treeview .mv-node > .mv-node__label--selected,
.mv-treeview .mv-node > .mv-node__arrow-down--selected {
  border-color: #e94e1b;
  color: #e94e1b;
  background: rgba(233, 78, 27, 0.18);
}
.mv-treeview .mv-node > .mv-node__label--selected-reverb,
.mv-treeview .mv-node > .mv-node__arrow-down--selected-reverb {
  border-color: transparent;
  color: rgba(233, 78, 27, 0.4);
  background: rgba(233, 78, 27, 0.18);
}
.mv-treeview .mv-node > .mv-node__label--selected-parent,
.mv-treeview .mv-node > .mv-node__arrow-down--selected-parent {
  border-style: solid;
  border-color: #e94e1b;
  color: #000;
  background: #fff;
}
.mv-treeview .mv-node > .mv-node__label--disable.mv-node__label--selected-parent {
  color: #b3b3b3;
}
.mv-treeview .mv-node.mv-node--visible > ul {
  display: block;
}
.mv-treeview .mv-node.mv-node--hidden > ul {
  display: none;
}
.mv-treeview .mv-node__label:hover,
.mv-treeview .mv-node__arrow-down:hover {
  background-color: rgba(233, 78, 27, 0.18);
}
.mv-treeview .mv-node__label--disable:hover,
.mv-treeview .mv-node__arrow-down--disable:hover {
  background-color: #fff;
  cursor: default;
}
.mv-treeview .mv-node--selected-reverb > .mv-node__label:hover,
.mv-treeview .mv-node--selected-reverb > .mv-node__arrow-down:hover {
  border: 0.13rem solid #e94e1b;
  color: #e94e1b;
}

.mv-treeview--ascending .mv-node:hover > .mv-node__label,
.mv-treeview--ascending .mv-node:hover > .mv-node__arrow-down {
  background-color: rgba(233, 78, 27, 0.18);
}
.mv-treeview--ascending .mv-node--selected-reverb:hover > .mv-node__label,
.mv-treeview--ascending .mv-node--selected-reverb:hover > .mv-node__arrow-down {
  border: 0.13rem solid #e94e1b;
  color: #e94e1b;
}

.mv-treeview--descending .mv-node__label:hover,
.mv-treeview--descending .mv-node__arrow-down:hover {
  background-color: rgba(233, 78, 27, 0.18);
}
.mv-treeview--descending .mv-node__label:hover ~ ul .mv-node__label, .mv-treeview--descending .mv-node__label:hover ~ ul .mv-node__arrow-down,
.mv-treeview--descending .mv-node__arrow-down:hover ~ ul .mv-node__label,
.mv-treeview--descending .mv-node__arrow-down:hover ~ ul .mv-node__arrow-down {
  background-color: rgba(233, 78, 27, 0.18);
}
.mv-treeview--descending .mv-node__label:hover ~ ul .mv-node--selected-reverb > .mv-node__label, .mv-treeview--descending .mv-node__label:hover ~ ul .mv-node--selected-reverb > .mv-node__arrow-down,
.mv-treeview--descending .mv-node__arrow-down:hover ~ ul .mv-node--selected-reverb > .mv-node__label,
.mv-treeview--descending .mv-node__arrow-down:hover ~ ul .mv-node--selected-reverb > .mv-node__arrow-down {
  border: 0.13rem solid #e94e1b;
  color: #e94e1b;
}

.mv-treeview .mv-node__subtree {
  box-shadow: inset 2px 0px 0px 0px rgba(44, 44, 44, 0.1);
}

ul.mv-node__subtree {
  -webkit-padding-start: 30px !important;
          padding-inline-start: 30px !important;
  -webkit-margin-start: 10px !important;
          margin-inline-start: 10px !important;
}

.mv-selection-builder {
  display: flex;
  flex-direction: column;
}
.mv-selection-builder .mv-sb__top {
  display: flex;
}
.mv-selection-builder .mv-sb__top > * {
  flex: 1 1;
  border: 0.13rem solid #eaeaea;
  padding: 24px 24px 12px 24px;
  border-bottom: none;
}
.mv-selection-builder .mv-sb__top > *:first-child {
  border-right: none;
  border-top-left-radius: 0.28rem;
}
.mv-selection-builder .mv-sb__top > *:last-child {
  border-top-right-radius: 0.28rem;
}
.mv-selection-builder .mv-sb__bottom {
  display: flex;
}
.mv-selection-builder .mv-sb__bottom > * {
  flex: 1 1;
  border: 0.13rem solid #eaeaea;
  padding: 12px 24px 24px 24px;
  border-top: none;
}
.mv-selection-builder .mv-sb__bottom > *:first-child {
  border-right: none;
  border-bottom-left-radius: 0.28rem;
}
.mv-selection-builder .mv-sb__bottom > *:last-child {
  border-bottom-right-radius: 0.28rem;
}
.mv-selection-builder .selection__radio {
  display: flex;
  flex-wrap: wrap;
}
.mv-selection-builder .selection__filters {
  display: flex;
  flex-wrap: wrap;
}
.mv-selection-builder .summary-element .summary-element__primary-square {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: rgba(233, 78, 27, 0.4);
  margin-right: 8px;
}
.mv-selection-builder .summary-element .summary-element__primary-square--group {
  background-color: rgba(233, 78, 27, 0.16);
}
.mv-selection-builder .summary-element .summary-element__cat-1-square {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: rgba(38, 192, 199, 0.4);
  margin-right: 8px;
}
.mv-selection-builder .summary-element .summary-element__cat-1-square--group {
  background-color: rgba(38, 192, 199, 0.16);
}
.mv-selection-builder .summary-element .summary-element__cat-2-square {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: rgba(81, 81, 211, 0.4);
  margin-right: 8px;
}
.mv-selection-builder .summary-element .summary-element__cat-2-square--group {
  background-color: rgba(81, 81, 211, 0.16);
}
.mv-selection-builder .summary-element .summary-element__cat-3-square {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: rgba(230, 134, 25, 0.4);
  margin-right: 8px;
}
.mv-selection-builder .summary-element .summary-element__cat-3-square--group {
  background-color: rgba(230, 134, 25, 0.16);
}
.mv-selection-builder .summary-element .summary-element__cat-4-square {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: rgba(216, 55, 144, 0.4);
  margin-right: 8px;
}
.mv-selection-builder .summary-element .summary-element__cat-4-square--group {
  background-color: rgba(216, 55, 144, 0.16);
}
.mv-selection-builder .summary-element .summary-element__cat-5-square {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: rgba(38, 128, 235, 0.4);
  margin-right: 8px;
}
.mv-selection-builder .summary-element .summary-element__cat-5-square--group {
  background-color: rgba(38, 128, 235, 0.16);
}
.mv-selection-builder .summary-element .summary-element__cat-6-square {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: rgba(155, 236, 84, 0.4);
  margin-right: 8px;
}
.mv-selection-builder .summary-element .summary-element__cat-6-square--group {
  background-color: rgba(155, 236, 84, 0.16);
}
.mv-selection-builder .summary-element .summary-element__cat-7-square {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: rgba(38, 142, 108, 0.4);
  margin-right: 8px;
}
.mv-selection-builder .summary-element .summary-element__cat-7-square--group {
  background-color: rgba(38, 142, 108, 0.16);
}
.mv-selection-builder .summary-element .summary-element__cat-8-square {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: rgba(183, 153, 0, 0.4);
  margin-right: 8px;
}
.mv-selection-builder .summary-element .summary-element__cat-8-square--group {
  background-color: rgba(183, 153, 0, 0.16);
}
.mv-selection-builder .sb-element {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.13rem solid #cacaca;
  border-radius: 0.28rem;
  padding: 8px 8px;
  margin: 4px;
}
.mv-selection-builder .sb-primary-element {
  background-color: rgba(233, 78, 27, 0.4);
  border: none;
}
.mv-selection-builder .sb-primary-element--group {
  background-color: rgba(233, 78, 27, 0.16);
}
.mv-selection-builder .sb-cat-1-element {
  background-color: rgba(38, 192, 199, 0.4);
  border: none;
}
.mv-selection-builder .sb-cat-1-element--group {
  background-color: rgba(38, 192, 199, 0.16);
}
.mv-selection-builder .sb-cat-2-element {
  background-color: rgba(81, 81, 211, 0.4);
  border: none;
}
.mv-selection-builder .sb-cat-2-element--group {
  background-color: rgba(81, 81, 211, 0.16);
}
.mv-selection-builder .sb-cat-3-element {
  background-color: rgba(230, 134, 25, 0.4);
  border: none;
}
.mv-selection-builder .sb-cat-3-element--group {
  background-color: rgba(230, 134, 25, 0.16);
}
.mv-selection-builder .sb-cat-4-element {
  background-color: rgba(216, 55, 144, 0.4);
  border: none;
}
.mv-selection-builder .sb-cat-4-element--group {
  background-color: rgba(216, 55, 144, 0.16);
}
.mv-selection-builder .sb-cat-5-element {
  background-color: rgba(38, 128, 235, 0.4);
  border: none;
}
.mv-selection-builder .sb-cat-5-element--group {
  background-color: rgba(38, 128, 235, 0.16);
}
.mv-selection-builder .sb-cat-6-element {
  background-color: rgba(155, 236, 84, 0.4);
  border: none;
}
.mv-selection-builder .sb-cat-6-element--group {
  background-color: rgba(155, 236, 84, 0.16);
}
.mv-selection-builder .sb-cat-7-element {
  background-color: rgba(38, 142, 108, 0.4);
  border: none;
}
.mv-selection-builder .sb-cat-7-element--group {
  background-color: rgba(38, 142, 108, 0.16);
}
.mv-selection-builder .sb-cat-8-element {
  background-color: rgba(183, 153, 0, 0.4);
  border: none;
}
.mv-selection-builder .sb-cat-8-element--group {
  background-color: rgba(183, 153, 0, 0.16);
}
.mv-selection-builder .selection__elements .elements__info {
  display: flex;
  align-items: center;
}
.mv-selection-builder .selection__elements .elements__info > * {
  margin-right: 8px;
}
.mv-selection-builder .selection__elements .elements__list {
  max-height: 500px;
  overflow-y: auto;
}
.mv-selection-builder .elements__unselectall {
  text-align: right;
}
.mv-selection-builder .selected__elements .elements__list {
  max-height: 500px;
  overflow-y: auto;
}
.mv-selection-builder .selected__elements .elements__list button {
  padding: 8px 0px;
}

/* 
  You want a simple and fancy tooltip?
  Just copy all [mv-tooltip-content] blocks:
*/
.mv-tooltip__info {
  right: 3%;
  position: absolute;
}
.mv-tooltip__info-top {
  top: 10%;
}
.mv-tooltip__info-bottom {
  top: 10%;
}
.mv-tooltip[mv-tooltip-content] {
  position: relative;
  z-index: 100;
}
.mv-tooltip[mv-tooltip-content]:before, .mv-tooltip[mv-tooltip-content]:after {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  left: 50%;
  bottom: calc(100% + 5px);
  /* 5px is the size of the arrow */
  pointer-events: none;
  transition: 0.2s;
  will-change: transform;
}
.mv-tooltip[mv-tooltip-content]:before {
  content: attr(mv-tooltip-content);
  padding: 10px 18px;
  min-width: 50px;
  max-width: 300px;
  width: -webkit-max-content;
  width: max-content;
  width: -moz-max-content;
  border-radius: 6px;
  font-size: 14px;
  background-color: #2c2c2c;
  background-image: linear-gradient(30deg, rgba(59, 72, 80, 0.44), rgba(59, 68, 75, 0.44), rgba(60, 82, 88, 0.44));
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
  color: #fff;
  text-align: center;
  white-space: pre-wrap;
  -webkit-transform: translate(-50%, -5px) scale(0.5);
          transform: translate(-50%, -5px) scale(0.5);
}
.mv-tooltip[mv-tooltip-content]:after {
  content: "";
  border-style: solid;
  border-width: 5px 5px 0px 5px;
  /* CSS triangle */
  border-color: #2c2c2c transparent transparent transparent;
  transition-duration: 0s;
  /* If the mouse leaves the element,  the transition effects for the tooltip arrow are "turned off" */
  -webkit-transform-origin: top;
          transform-origin: top;
  /* Orientation setting for the slide-down effect */
  -webkit-transform: translateX(-50%) scaleY(0);
          transform: translateX(-50%) scaleY(0);
}
.mv-tooltip[mv-tooltip-content]:hover:before, .mv-tooltip[mv-tooltip-content]:hover:after {
  visibility: visible;
  opacity: 1;
}
.mv-tooltip[mv-tooltip-content]:hover:before {
  transition-delay: 0.3s;
  -webkit-transform: translate(-50%, -5px) scale(1);
          transform: translate(-50%, -5px) scale(1);
}
.mv-tooltip[mv-tooltip-content]:hover:after {
  transition-delay: 0.5s;
  /* Starting after the grow effect */
  transition-duration: 0.2s;
  -webkit-transform: translateX(-50%) scaleY(1);
          transform: translateX(-50%) scaleY(1);
}
.mv-tooltip[mv-tooltip-position=left]:before, .mv-tooltip[mv-tooltip-position=left]:after {
  left: auto;
  right: calc(100% + 5px);
  bottom: 50%;
}
.mv-tooltip[mv-tooltip-position=left]:before {
  -webkit-transform: translate(-5px, 50%) scale(0.5);
          transform: translate(-5px, 50%) scale(0.5);
}
.mv-tooltip[mv-tooltip-position=left]:hover:before {
  -webkit-transform: translate(-5px, 50%) scale(1);
          transform: translate(-5px, 50%) scale(1);
}
.mv-tooltip[mv-tooltip-position=left]:after {
  border-width: 5px 0px 5px 5px;
  border-color: transparent transparent transparent #2c2c2c;
  -webkit-transform-origin: left;
          transform-origin: left;
  -webkit-transform: translateY(50%) scaleX(0);
          transform: translateY(50%) scaleX(0);
}
.mv-tooltip[mv-tooltip-position=left]:hover:after {
  -webkit-transform: translateY(50%) scaleX(1);
          transform: translateY(50%) scaleX(1);
}
.mv-tooltip[mv-tooltip-position=right]:before, .mv-tooltip[mv-tooltip-position=right]:after {
  left: calc(100% + 5px);
  bottom: 50%;
}
.mv-tooltip[mv-tooltip-position=right]:before {
  -webkit-transform: translate(5px, 50%) scale(0.5);
          transform: translate(5px, 50%) scale(0.5);
}
.mv-tooltip[mv-tooltip-position=right]:hover:before {
  -webkit-transform: translate(5px, 50%) scale(1);
          transform: translate(5px, 50%) scale(1);
}
.mv-tooltip[mv-tooltip-position=right]:after {
  border-width: 5px 5px 5px 0px;
  border-color: transparent #2c2c2c transparent transparent;
  -webkit-transform-origin: right;
          transform-origin: right;
  -webkit-transform: translateY(50%) scaleX(0);
          transform: translateY(50%) scaleX(0);
}
.mv-tooltip[mv-tooltip-position=right]:hover:after {
  -webkit-transform: translateY(50%) scaleX(1);
          transform: translateY(50%) scaleX(1);
}
.mv-tooltip[mv-tooltip-position=bottom]:before, .mv-tooltip[mv-tooltip-position=bottom]:after {
  top: calc(100% + 5px);
  bottom: auto;
}
.mv-tooltip[mv-tooltip-position=bottom]:before {
  -webkit-transform: translate(-50%, 5px) scale(0.5);
          transform: translate(-50%, 5px) scale(0.5);
}
.mv-tooltip[mv-tooltip-position=bottom]:hover:before {
  -webkit-transform: translate(-50%, 5px) scale(1);
          transform: translate(-50%, 5px) scale(1);
}
.mv-tooltip[mv-tooltip-position=bottom]:after {
  border-width: 0px 5px 5px 5px;
  border-color: transparent transparent #2c2c2c transparent;
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
}
.mv-tooltip [mv-tooltip-content]:after {
  bottom: calc(100% + 3px);
}
.mv-tooltip [mv-tooltip-content]:after {
  border-width: 7px 7px 0px 7px;
}

.mv-table-filters {
  padding: 24px;
  background-color: #f5f5f5;
  border-radius: 0.28rem;
}
.mv-table-filters .mv-datepicker-popper {
  z-index: 30;
}

.mv-table-navigation .mv-form-group > label, .mv-table-navigation .mv-form-group > .info-feedback {
  display: none;
}
.mv-table-navigation .mv-form-group input {
  width: 80px;
}

.mv-table-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
}

.mv-table {
  width: 100%;
}
.mv-table th {
  position: relative;
}
.mv-table .resizer {
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  cursor: col-resize;
  -webkit-user-select: none;
          user-select: none;
}
.mv-table .resizer:hover,
.mv-table .resizing {
  border-right: 5.6px solid #e94e1b;
}
.mv-table thead tr {
  background-color: #f5f5f5;
}
.mv-table thead .mv-table__heading {
  height: 48px;
  overflow: hidden;
  padding: 8px;
}
.mv-table thead .mv-table__heading .mv-sorted {
  color: #e94e1b;
}
.mv-table tbody tr:nth-child(even) {
  background-color: #f5f5f5;
}
.mv-table tbody tr:nth-child(odd) {
  background-color: #fff;
}
.mv-table tbody td > div {
  height: 72px;
  text-overflow: ellipsis;
}
.mv-table tbody td > div > * {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.mv-table tbody td > div.mv-btn-ddown, .mv-table tbody td > div.mv-btn-ddown--open {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.mv-table tbody td.mv-table__info {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 24px;
}
.mv-table tr {
  border: 2px solid #fff;
  border-radius: 20%;
}
.mv-table td > div,
.mv-table th > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.mv-table td small {
  text-transform: uppercase;
  font-weight: bolder;
}
.mv-table .mv-table__data {
  padding: 8px;
}
.mv-table .mv-table__data--min-content {
  width: 0;
}
.mv-table .mv-table__checkbox > div {
  align-items: center;
  padding: 12px;
}
.mv-table .mv-table__action {
  padding: 12px;
  width: 100%;
  max-width: 100%;
  display: inline-flex;
  align-items: center;
}
.mv-table .mv-table__action > * {
  padding: 8px;
}

.mv-table--sticky-badge-start .mv-table__badge {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 19;
  background-color: inherit;
}
.mv-table--sticky-badge-start th.mv-table__badge {
  z-index: 29;
}

.mv-table--sticky-badge-end .mv-table__badge {
  position: -webkit-sticky;
  position: sticky;
  width: 0;
  display: table-cell;
  right: 0;
  z-index: 19;
  background-color: inherit;
}
.mv-table--sticky-badge-end th.mv-table__badge {
  z-index: 29;
  position: -webkit-sticky;
  position: sticky;
  right: 0;
  background-color: inherit;
}

.mv-table--sticky-checkbox-start .mv-table__checkbox {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 19;
  background-color: inherit;
}
.mv-table--sticky-checkbox-start th.mv-table__checkbox {
  z-index: 29;
}

.mv-table--sticky-checkbox-end .mv-table__checkbox {
  position: -webkit-sticky;
  position: sticky;
  width: 0;
  display: table-cell;
  right: 0;
  z-index: 19;
  background-color: inherit;
}
.mv-table--sticky-checkbox-end th.mv-table__checkbox {
  z-index: 29;
  position: -webkit-sticky;
  position: sticky;
  right: 0;
  background-color: inherit;
}

.mv-table--sticky-action-start .mv-table__action {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 19;
  background-color: inherit;
}
.mv-table--sticky-action-start th.mv-table__action {
  z-index: 29;
}

.mv-table--sticky-action-end .mv-table__action {
  position: -webkit-sticky;
  position: sticky;
  width: 0;
  display: table-cell;
  right: 0;
  z-index: 19;
  background-color: inherit;
}
.mv-table--sticky-action-end th.mv-table__action {
  z-index: 29;
  position: -webkit-sticky;
  position: sticky;
  right: 0;
  background-color: inherit;
}

.mv-table--sticky-badge-start.mv-table--sticky-checkbox-start th.mv-table__checkbox,
.mv-table--sticky-badge-start.mv-table--sticky-checkbox-start .mv-table__checkbox {
  left: 42px;
}

.mv-table--sticky-header thead {
  position: -webkit-sticky;
  position: sticky;
  top: 56px;
  z-index: 29;
}

.mv-primary-tabs .tab-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  color: #e94e1b;
  padding: 12px;
  border-top-left-radius: 0.28rem;
  border-top-right-radius: 0.28rem;
  border: 0.13rem solid transparent;
  border-bottom: none;
}
.mv-primary-tabs .tab-label:hover:not(.mv-primary-tabs .tab-label--active) {
  background-color: rgba(233, 78, 27, 0.16);
  cursor: pointer;
}
.mv-primary-tabs .tab-label--active {
  font-weight: 700;
  border-color: #e94e1b;
}

.mv-secondary-tabs .tab-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  color: #3f9aaa;
  padding: 12px;
  border-top-left-radius: 0.28rem;
  border-top-right-radius: 0.28rem;
  border: 0.13rem solid transparent;
  border-bottom: none;
}
.mv-secondary-tabs .tab-label:hover:not(.mv-secondary-tabs .tab-label--active) {
  background-color: rgba(63, 154, 170, 0.16);
  cursor: pointer;
}
.mv-secondary-tabs .tab-label--active {
  font-weight: 700;
  border-color: #3f9aaa;
}

.mv-tabs__labels {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
}

.mv-tabs__tabs {
  margin-top: 8px;
}
.mv-tabs__tabs .tab {
  display: none;
  width: 100%;
}
.mv-tabs__tabs .tab--active {
  display: block;
}

.mv-spinner {
  color: #7AB317;
}

.mv-draggable-list__item {
  margin: 4px;
}

.draggable :hover {
  cursor: move;
}

.not-draggable :hover {
  cursor: default;
}

.mv-draggable-list__placeholder {
  border-style: dashed;
  border-color: #cacaca;
  border-radius: 8px;
}

.mv-login-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
}

.mv-login {
  margin: auto;
  width: 30%;
  height: 65%;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 5% 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}
.mv-login::-webkit-scrollbar {
  display: none;
}
.mv-login > form {
  width: 100%;
  display: flex;
  align-items: stretch;
  align-content: stretch;
  flex-direction: column;
  grid-row-gap: 16px;
  row-gap: 16px;
}
.mv-login > form .mv-login__logo {
  margin: 0 50px 24px 50px;
}
.mv-login > form > :last-child {
  margin-top: 32px;
}

.mv-anchor-reset {
  text-decoration: none;
}
.mv-anchor-reset:hover {
  text-decoration: none;
}

.disableContent {
  pointer-events: none;
  opacity: 0.4;
}

.mv-collapse__header {
  padding: 13px;
  background-color: #f5f5f5;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.mv-collapse__header h3 {
  margin: 0 0 0 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.mv-collapse__header--light {
  background-color: #fff;
}
.mv-collapse__header--light:hover {
  background-color: rgba(233, 78, 27, 0.16);
}
.mv-collapse__content {
  overflow: hidden;
}
.mv-collapse__content__collapsible {
  margin-top: -100%;
  transition: margin 0.5s cubic-bezier(0, 0.79, 0.58, 1);
  border-left: 2px solid #cacaca;
  padding: 10px 10px 0 20px;
}
.mv-collapse__content__collapsible--show {
  margin: 12px 0 0 22px;
  margin-top: 12px;
}
.mv-collapse__content__collapsible--hide {
  margin-top: -100%;
  transition: margin 0.5s cubic-bezier(0.52, 0.03, 1, 1);
}

.badge {
  border-radius: 0.25rem;
  padding: 0.3rem;
  font-weight: 400;
}
.badge.positive {
  background-color: #21a366;
  background-color: var(--badge-positive-bg);
  color: #ffffff;
  color: var(--white);
}
.badge.informative {
  background-color: #4ca7b6;
  background-color: var(--badge-informative-bg);
  color: #ffffff;
  color: var(--white);
}
.badge.warning {
  background-color: #cd9d1d;
  background-color: var(--badge-warning-bg);
  color: #ffffff;
  color: var(--white);
}
.badge.negative {
  background-color: #E65E1C;
  background-color: var(--badge-negative-bg);
  color: #ffffff;
  color: var(--white);
}
.badge.neutral {
  background-color: #808080;
  background-color: var(--badge-neutral-bg);
  color: #ffffff;
  color: var(--white);
}

.breadcrumbs {
  padding: 1rem 2rem 2rem;
}
.breadcrumbs * ~ *::before {
  content: " / ";
}
.breadcrumbs span {
  color: #6c757d;
  color: var(--gray);
}

.right-detail-view-components label, .left-detail-view-components label, .full-content label {
  font-size: 1rem;
  font-weight: 400;
  color: #6e6e6e;
  color: var(--gray-700);
  margin-bottom: 0;
}

.full-content {
  padding-top: 1.2rem;
}

.full-content > div > label:first-child {
  font-size: 1.25rem;
  font-weight: 600;
}

.left-detail-view {
  padding-top: 1.2rem;
}

.right-detail-view-content {
  border-left: 2px solid #e94e1b;
  border-left: 2px solid var(--primary);
  padding: 1.2rem 0 1.2rem 3rem;
  background-color: #ffded4;
  background-color: var(--primary-100);
}
.right-detail-view-content table {
  margin-bottom: 0;
}
.right-detail-view-content table tr .label {
  font-size: 1rem;
  font-weight: 400;
  color: #6e6e6e;
  color: var(--gray-700);
  margin-bottom: 0;
}
.right-detail-view-content table tr td {
  padding-bottom: 0;
  padding-top: 0;
}
.right-detail-view-content table tr td:first-child {
  padding-left: 0;
}

.table-filters {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
}
.table-filters label {
  font-size: 0.85rem;
  font-weight: 400;
  color: #6e6e6e;
  color: var(--gray-700);
  margin-bottom: 0;
}
.table-filters .form-group, .table-filters .mv-form-group {
  margin-bottom: 1.5rem;
}
.table-filters .form-group > .form-group, .table-filters .mv-form-group > .form-group, .table-filters .form-group > .mv-form-group, .table-filters .mv-form-group > .mv-form-group {
  margin: 0;
}

.filter {
  padding-right: 15px;
  padding-left: 15px;
  width: 25%;
}
.filter-date {
  width: 15rem;
}
.filter-select, .filter-search {
  width: 25rem;
}
.filter-button {
  padding: 0 15px;
}
.filter .input-group .input-group-prepend, .filter .mv-input-group .input-group-prepend, .filter .input-group .mv-input-group__prepend, .filter .mv-input-group .mv-input-group__prepend {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  color: var(--filters-color);
  text-align: center;
  white-space: nowrap;
  border-left: 1px solid #ced4da;
  border-left: 1px solid var(--filters-border);
  border-top: 1px solid #ced4da;
  border-top: 1px solid var(--filters-border);
  border-bottom: 1px solid #ced4da;
  border-bottom: 1px solid var(--filters-border);
  background-color: #efefef;
  background-color: var(--filters-background);
}
.filter .input-group input, .filter .mv-input-group input, .filter .input-group select, .filter .mv-input-group select {
  border-left: 0;
  border-radius: 0;
}

@media screen and (max-width: 576px) {
  .table-filters {
    flex-direction: column;
    align-items: center;
  }
  .table-filters .w-100-mobile {
    width: 100% !important;
  }
  .table-filters .filter-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.form-group > label, .mv-form-group > label {
  font-size: 1rem;
  font-weight: 400;
  color: #6e6e6e;
  color: var(--gray-700);
  margin-bottom: 0;
}

.add-button {
  padding-bottom: 1rem;
}

.daterangepicker-input:disabled {
  background-color: transparent !important;
}

/* @TODO refactoring: check if needed */
.date-input .input-group-prepend + div, .date-input .mv-input-group__prepend + div {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
}

.form-group input, .mv-form-group input, .form-group select, .mv-form-group select, .form-group textarea, .mv-form-group textarea {
  border-radius: 0;
}
.form-group .custom-file-label, .mv-form-group .custom-file-label {
  border-radius: 0;
}
.form-group .input-group.date-input input, .mv-form-group .input-group.date-input input, .form-group .date-input.mv-input-group input, .mv-form-group .date-input.mv-input-group input {
  border-left: 0;
}
.form-group .input-group .input-group-prepend, .mv-form-group .input-group .input-group-prepend, .form-group .mv-input-group .input-group-prepend, .mv-form-group .mv-input-group .input-group-prepend, .form-group .input-group .mv-input-group__prepend, .mv-form-group .input-group .mv-input-group__prepend, .form-group .mv-input-group .mv-input-group__prepend, .mv-form-group .mv-input-group .mv-input-group__prepend {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  color: var(--filters-color);
  text-align: center;
  white-space: nowrap;
  border-left: 1px solid #ced4da;
  border-left: 1px solid var(--filters-border);
  border-top: 1px solid #ced4da;
  border-top: 1px solid var(--filters-border);
  border-bottom: 1px solid #ced4da;
  border-bottom: 1px solid var(--filters-border);
  background-color: #efefef;
  background-color: var(--filters-background);
}

.multi-select .select-box {
  position: relative;
}
.multi-select .over-select {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.multi-select .checkboxes {
  position: absolute;
  width: 25rem;
  padding-right: 30px;
  z-index: 1;
}
.multi-select .checkboxes-container {
  background-color: #ffffff;
  background-color: var(--white);
  border-left: 1px solid #ced4da;
  border-left: 1px solid var(--filters-border);
  border-right: 1px solid #ced4da;
  border-right: 1px solid var(--filters-border);
  border-bottom: 1px solid #ced4da;
  border-bottom: 1px solid var(--filters-border);
  position: relative;
  top: -1.5rem;
  z-index: 1;
}
.multi-select .checkboxes label {
  display: flex;
  align-items: center;
  z-index: 1;
  width: 100%;
  cursor: pointer;
  padding: 0.25rem 1rem 0.25rem 1rem;
}
.multi-select .checkboxes label input {
  margin: 0.5rem 0.8rem;
  margin-left: 0;
}
.multi-select .checkboxes label:hover {
  color: #e94e1b;
  color: var(--primary);
}

.newasset-btn-bottom {
  margin-top: 2rem;
  margin-right: 1rem;
}

.form-radio input {
  margin: 0 0.4rem;
}
.form-radio-inline {
  display: flex;
  align-items: center;
}
.form-radio-column {
  flex-direction: column;
  padding: 0 0.5rem;
}
.form-radio-label {
  margin: 0;
}
.form-radio-label-right {
  margin-right: 0.8rem;
}
.form-radio-label-left {
  margin-left: 0.8rem;
}
.form-radio-label-top {
  margin-top: 0.8rem;
}
.form-radio-label-bottom {
  margin-bottom: 0.8rem;
}

.react-bootstrap-daterangepicker-container input[readonly],
.react-bootstrap-daterangepicker-container input:disabled {
  background-color: #fff;
}

.button-group-header button:not(:first-child) {
  margin-left: 1rem;
}
.button-group-header.subtitle {
  margin-top: 0.4rem;
}

@media screen and (max-width: 767px) {
  .main-container .page-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .main-container .page-header .fullwidth {
    width: 100%;
  }
  .main-container .page-header hgroup {
    align-self: flex-start;
  }
  .main-container .page-header-container {
    flex-direction: column;
  }
  .main-container .page-header .no-buttons .page-header-top-buttons {
    width: 10%;
    margin-bottom: 0;
  }
  .main-container .page-header .no-buttons hgroup {
    align-self: center;
  }
  .main-container .page-header-top {
    flex-direction: column;
  }
  .main-container .page-header-top-buttons {
    margin-right: 0 !important;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    align-items: center;
  }
  .main-container .page-header-top-buttons button.btn-success {
    height: 2rem;
    font-size: 0.8em;
  }
  .main-container .page-header-bottom-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 1.2rem;
    align-items: center;
  }
  .main-container .page-header-bottom-buttons button {
    width: 100%;
    height: 2rem;
    line-height: 1rem;
  }
}
.loginContainer > form {
  margin: 4rem 0 0 0;
  padding: 3rem;
  border: 1px solid #e1e1e1;
  border: 1px solid var(--gray-300);
  border-radius: 8px;
  width: 320px;
  box-shadow: 0 16px 48px #cacaca;
  box-shadow: 0 16px 48px var(--gray-400);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.loginContainer > form label {
  margin-bottom: 0.25rem;
  color: #6c757d;
  color: var(--gray);
}
.loginContainer > form input:invalid {
  border-color: #dc3545;
  border-color: var(--danger);
}

@media screen and (max-width: 767px) {
  .main-container .content {
    margin: 0.2rem 0;
    padding: 1rem 1rem;
  }
}
.link {
  background: none !important;
  border: none;
  padding: 0 !important;
  text-decoration: underline;
  cursor: pointer;
}
.link.normal {
  color: #e94e1b;
  color: var(--primary);
}
.link.light {
  color: #ffffff;
  color: var(--white);
}
.link.darl {
  color: #000000;
  color: var(--black);
}

.modal-full-width {
  height: calc(100vh - 3.125rem);
  background-color: white;
  position: fixed;
  width: 100vw;
  top: 3.125rem;
  right: 0;
  z-index: 2;
  -webkit-transform: translateX(-100%) translateZ(0);
          transform: translateX(-100%) translateZ(0);
  will-change: transform;
  transition: 0.25s ease-out;
}
.modal-full-width.open {
  -webkit-transform: translateX(0%) translateZ(0);
          transform: translateX(0%) translateZ(0);
}

.modal, .mv-modal {
  /* z-index: -1;*/
  display: flex !important;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: auto;
}

.modal-open .modal, .modal-open .mv-modal {
  z-index: 1050;
}

.modal-dialog, .mv-modal-dialog {
  /* Width */
  max-width: 100%;
  display: inline-block;
}

.modal-content, .mv-modal-content {
  max-height: 90vh;
  overflow: scroll;
}

.modal-full-width-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  overflow: auto;
  padding: 1em;
}

@media (min-width: 1200px) {
  .modal-full-width {
    width: calc(100vw - 15rem) !important;
    left: 15rem;
  }
}
@media (min-width: 576px) {
  .modal-dialog, .mv-modal-dialog {
    max-width: 100vw !important;
    margin: 1.75rem auto;
  }
}
.PlantTreeViewComponent {
  list-style: none;
  padding-left: 0;
  overflow-y: auto;
}
.PlantTreeViewComponent ul {
  list-style: none;
  padding-left: 8px;
  margin-bottom: 4px;
}
.PlantTreeViewComponent > ul {
  padding-left: 0;
}
.PlantTreeViewComponent li {
  position: relative;
  padding: 0 7px;
  border-radius: 3px;
  cursor: pointer;
}
.PlantTreeViewComponent li:not(.has-childres) {
  padding-left: 24px;
}
.PlantTreeViewComponent li.opened > ul {
  max-height: 999em;
}
.PlantTreeViewComponent li.opened > span > svg {
  -webkit-transform: rotateZ(90deg);
          transform: rotateZ(90deg);
}
.PlantTreeViewComponent li.active, .PlantTreeViewComponent li.mv-breadcrumb__item--active {
  background-color: #fee;
}
.PlantTreeViewComponent li > span.icon {
  width: 18px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}
.PlantTreeViewComponent li > span.icon > svg {
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.PlantTreeViewComponent li .not-cliccable {
  cursor: default !important;
}
.PlantTreeViewComponent li:not(.has-childrens) span.icon {
  line-height: 12px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
}
.PlantTreeViewComponent li:not(.has-childrens) span.icon svg {
  width: 5px;
  height: 5px;
  opacity: 0.7;
}
.PlantTreeViewComponent li .name {
  padding: 3px 5px 2px;
  border-radius: 3px;
}
.PlantTreeViewComponent li .name strong {
  font-size: 0.9rem;
}
.PlantTreeViewComponent li.selected > .name {
  background-color: #fcc;
}

.treeview-container ul {
  max-height: 0;
  overflow-y: auto;
}
.treeview-container.height-small > ul {
  max-height: 24rem;
}
.treeview-container.height-medium > ul {
  max-height: 48rem;
}
.treeview-container.height-large > ul {
  max-height: 100%;
}
.treeview-container > .PlantTreeViewComponent > li {
  padding-left: 0;
}

.step {
  padding: 1rem 2rem;
  margin: 0 !important;
}
.step div {
  display: flex;
  align-items: center;
}
.step .dot {
  padding: 0.25em 0.75em;
  border-radius: 100%;
  background-color: #b3b3b3;
  background-color: var(--gray-500);
  font-weight: bold;
  color: white;
}
.step .dot.active, .step .dot.mv-breadcrumb__item--active {
  background-color: #e94e1b;
  background-color: var(--primary);
}
.step .label {
  font-weight: bold;
  margin-left: 1em;
}
.step .hr {
  height: 2px;
  width: 50px;
  margin-left: 1em;
  margin-right: 1em;
  background-color: #e94e1b;
  background-color: var(--primary);
}

.table-row-button-group button:not(:first-child) {
  margin-left: 1rem;
}

.ReactTable {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.ReactTable .rt-tbody .rt-tr {
  align-items: center;
}
.ReactTable .rt-tbody .rt-tr.-odd {
  box-shadow: inset 0 0 0 500px rgba(0, 0, 0, 0.05);
}
.ReactTable .-pagination div .d-flex {
  justify-content: space-between !important;
}

.ReactTable * {
  box-sizing: border-box;
}

.ReactTable .rt-table {
  flex: auto 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
}

.ReactTable .rt-thead {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  -webkit-user-select: none;
  user-select: none;
}

.ReactTable .rt-thead.-headerGroups {
  background: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-thead.-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none;
}

.ReactTable .rt-thead.-filters .rt-th {
  border-right: 1px solid rgba(0, 0, 0, 0.02);
}

.ReactTable .rt-thead.-header {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
}

.ReactTable .rt-thead .rt-tr {
  text-align: center;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6);
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6);
}

.ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  cursor: pointer;
}

.ReactTable .rt-thead .rt-th:last-child,
.ReactTable .rt-thead .rt-td:last-child {
  border-right: 0;
}

.ReactTable .rt-thead .rt-resizable-header {
  overflow: visible;
}

.ReactTable .rt-thead .rt-resizable-header:last-child {
  overflow: hidden;
}

.ReactTable .rt-thead .rt-resizable-header-content {
  overflow: hidden;
  text-overflow: ellipsis;
  color: #6e6e6e;
  color: var(--gray-700);
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 400;
}

.ReactTable .rt-thead .rt-header-pivot {
  border-right-color: #f7f7f7;
}

.ReactTable .rt-thead .rt-header-pivot:after,
.ReactTable .rt-thead .rt-header-pivot:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ReactTable .rt-thead .rt-header-pivot:after {
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #fff;
  border-width: 8px;
  margin-top: -8px;
}

.ReactTable .rt-thead .rt-header-pivot:before {
  border-color: rgba(102, 102, 102, 0);
  border-left-color: #f7f7f7;
  border-width: 10px;
  margin-top: -10px;
}

.ReactTable .rt-tbody {
  flex: 99999 1 auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: 0;
}

.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.02);
}

.ReactTable .rt-tbody .rt-td:last-child {
  border-right: 0;
}

.ReactTable .rt-tbody .rt-expandable {
  cursor: pointer;
  text-overflow: clip;
}

.ReactTable .rt-tr-group {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.ReactTable .rt-tr {
  flex: 1 0 auto;
  display: inline-flex;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
  flex: 1 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 7px 5px;
  overflow: hidden;
  transition: 0.3s ease;
  transition-property: width, min-width, padding, opacity;
}

.ReactTable .rt-th.-hidden,
.ReactTable .rt-td.-hidden {
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0 !important;
}

.ReactTable .rt-expander {
  display: inline-block;
  position: relative;
  margin: 0;
  color: transparent;
  margin: 0 10px;
}

.ReactTable .rt-expander:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-90deg);
  transform: translate(-50%, -50%) rotate(-90deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.8);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
}

.ReactTable .rt-expander.-open:after {
  -webkit-transform: translate(-50%, -50%) rotate(0);
  transform: translate(-50%, -50%) rotate(0);
}

.ReactTable .rt-resizer {
  display: inline-block;
  position: absolute;
  width: 36px;
  top: 0;
  bottom: 0;
  right: -18px;
  cursor: col-resize;
  z-index: 10;
}

.ReactTable .rt-tfoot {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}

.ReactTable .rt-tfoot .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-tfoot .rt-td:last-child {
  border-right: 0;
}

.ReactTable.-striped .rt-tr.-odd {
  background: rgba(0, 0, 0, 0.03);
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: rgba(0, 0, 0, 0.05);
}

.ReactTable .-pagination {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 3px;
}
.ReactTable .-pagination input, .ReactTable .-pagination select {
  background: #fff;
  font-size: inherit;
  font-weight: normal;
  outline: none;
  border-radius: 0;
}
.ReactTable .-pagination .-btn {
  -webkit-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(0, 0, 0, 0.1);
  transition: all 0.1s ease;
  cursor: pointer;
  outline: none;
}
.ReactTable .-pagination .-btn[disabled] {
  opacity: 0.5;
  cursor: default;
}
.ReactTable .-pagination .-btn:not([disabled]):hover {
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}
.ReactTable .-pagination .-next, .ReactTable .-pagination .-previous {
  flex: 1 1;
  text-align: center;
}
.ReactTable .-pagination .-center {
  flex: 1.5 1;
  text-align: center;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
.ReactTable .-pagination .-pageInfo {
  display: inline-block;
  margin: 3px 10px;
  white-space: nowrap;
}
.ReactTable .-pagination .-pageJump {
  display: inline-block;
}
.ReactTable .-pagination .-pageJump input {
  width: 70px;
  text-align: center;
}
.ReactTable .-pagination .-pageSizeOptions {
  margin: 3px 10px;
}

.ReactTable .rt-noData {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  color: rgba(0, 0, 0, 0.5);
}

.ReactTable .-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}

.ReactTable .-loading > div {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  -webkit-transform: translateY(-52%);
  transform: translateY(-52%);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.ReactTable .-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}

.ReactTable .-loading.-active > div {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}

.ReactTable .rt-resizing .rt-th,
.ReactTable .rt-resizing .rt-td {
  transition: none !important;
  cursor: col-resize;
  -webkit-user-select: none;
  user-select: none;
}

/*******************************************************************************
 * CUSTOMIZATION
 ******************************************************************************/
.ReactTable {
  border: none;
}
.ReactTable .rt-thead.-header {
  border-bottom: 1px solid #eaeaea;
  border-bottom: 1px solid var(--gray-200);
  box-shadow: none;
}
.ReactTable .rt-thead.-filters {
  border-bottom: 1px solid #eaeaea;
  border-bottom: 1px solid var(--gray-200);
  background: #f5f5f5;
  background: var(--gray-100);
}
.ReactTable .rt-thead.-filters .rt-th {
  border-right: none;
}
.ReactTable .rt-thead.-filters select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.25;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  appearance: none;
}
.ReactTable .rt-thead.-filters .react-datepicker-wrapper input {
  width: 100%;
}
.ReactTable .rt-thead .rt-tr {
  text-align: left;
  align-items: center;
}
.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  border-right: none;
  box-shadow: none;
  font-weight: 300;
  padding: 0.5rem;
  color: #8e8e8e;
  color: var(--gray-600);
}
.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 #e94e1b;
  box-shadow: inset 0 3px 0 0 var(--primary);
}
.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 #e94e1b;
  box-shadow: inset 0 -3px 0 0 var(--primary);
}
.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: none;
}
.ReactTable .rt-tbody .rt-td {
  border-right: none;
}
.ReactTable .rt-tr {
  align-items: center;
}
.ReactTable .rt-tr.opened-by-self {
  background-color: rgba(107, 156, 20, 0.25) !important;
  background-color: var(--success-transparent) !important;
}
.ReactTable .rt-tr.opened-by-other-user > div:not(:last-child) {
  opacity: 0.5 !important;
}
.ReactTable .rt-th,
.ReactTable .rt-td {
  padding: 0.5rem;
}
.ReactTable.-striped .rt-tr.-odd {
  background: #f5f5f5;
  background: var(--gray-100);
}
.ReactTable .table-sub-component {
  margin: 0.5rem 0 0 2.7rem;
}

.list-group-item.opened-by-self {
  background-color: rgba(107, 156, 20, 0.25) !important;
  background-color: var(--success-transparent) !important;
}
.list-group-item.opened-by-other-user {
  opacity: 0.5 !important;
}

.ReactTable.mv4iot-fe-checklist-archive-table .rt-table {
  overflow: visible;
}

.pagination-bottom {
  margin-top: 2rem;
}

.tabulated-text table td {
  padding-left: 0;
  padding-bottom: 0;
  padding-top: 0;
}
.tabulated-text table tr td:first-child {
  font-weight: 600;
  color: #4b4b4b;
  color: var(--gray-800);
  margin-bottom: 0;
}
.tabulated-text h3 {
  font-weight: 500;
  font-size: 1.5rem;
}

.mnemonicinput.input {
  position: relative;
  width: 100%;
}
.mnemonicinput.la-ball-scale {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 1.5rem;
}
.mnemonicinput.suggestion-list {
  list-style: none;
  display: block;
  position: absolute;
  margin: 0;
  padding: 0;
  width: 91%;
  font-size: 90%;
  border: 1px solid #eee;
  background: #fff;
  z-index: 10;
}
.mnemonicinput.suggestion-list li {
  padding: 0.25rem 0.5rem;
}
.mnemonicinput.suggestion-list li:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.mnemonicinput.suggestion-list li.highlight {
  background: #e94e1b;
  background: var(--primary);
  color: #ffffff;
  color: var(--white);
}

/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.la-ball-scale,
.la-ball-scale > div {
  position: relative;
  box-sizing: border-box;
}

.la-ball-scale {
  display: block;
  font-size: 0;
  color: #ccc;
}

.la-ball-scale.la-dark {
  color: #333;
}

.la-ball-scale > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}

.la-ball-scale {
  width: 32px;
  height: 32px;
}

.la-ball-scale > div {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  opacity: 0;
  -webkit-animation: ball-scale 1s 0s ease-in-out infinite;
  animation: ball-scale 1s 0s ease-in-out infinite;
}

.la-ball-scale.la-sm {
  width: 16px;
  height: 16px;
}

.la-ball-scale.la-sm > div {
  width: 16px;
  height: 16px;
}

.la-ball-scale.la-2x {
  width: 64px;
  height: 64px;
}

.la-ball-scale.la-2x > div {
  width: 64px;
  height: 64px;
}

.la-ball-scale.la-3x {
  width: 96px;
  height: 96px;
}

.la-ball-scale.la-3x > div {
  width: 96px;
  height: 96px;
}

/*
 * Animation
 */
@-webkit-keyframes ball-scale {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes ball-scale {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
:root {
  --brand-primary: #e94e1b;
  --brand-primary-desaturated-12: #d9562b;
  --brand-primary-desaturated-25: #ca5e3a;
  --brand-primary-desaturated-50: #ab6e5a;
  --brand-primary-desaturated-75: #8b7d79;
  --brand-primary-darken-12: #b33911;
  --brand-primary-darken-25: #79270c;
  --brand-primary-darken-50: #050100;
  --brand-primary-darken-75: black;
  --brand-secondary: #3f9aaa;
  --brand-secondary-desaturated-12: #4e909b;
  --brand-secondary-desaturated-25: #5c868d;
  --brand-secondary-desaturated-50: #757575;
  --brand-secondary-desaturated-75: #757575;
  --brand-secondary-darken-12: #2e707b;
  --brand-secondary-darken-25: #1d464d;
  --brand-secondary-darken-50: black;
  --brand-secondary-darken-75: black;
  --primary-100: #ffded4;
  --primary-100-desaturated-12: #fcdfd7;
  --primary-100-desaturated-25: #fae1d9;
  --primary-100-desaturated-50: #f4e4df;
  --primary-100-desaturated-75: #efe7e4;
  --primary-100-darken-12: #ffad94;
  --primary-100-darken-25: #ff7c55;
  --primary-100-darken-50: #d43100;
  --primary-100-darken-75: #551400;
  --secondary: #3f9aaa;
  --secondary-desaturated-12: #4e909b;
  --secondary-desaturated-25: #5c868d;
  --secondary-desaturated-50: #757575;
  --secondary-desaturated-75: #757575;
  --secondary-darken-12: #2e707b;
  --secondary-darken-25: #1d464d;
  --secondary-darken-50: black;
  --secondary-darken-75: black;
  --top-bar-bg: #fff;
  --top-bar-bg-desaturated-12: white;
  --top-bar-bg-desaturated-25: white;
  --top-bar-bg-desaturated-50: white;
  --top-bar-bg-desaturated-75: white;
  --top-bar-bg-darken-12: #dfdfdf;
  --top-bar-bg-darken-25: #bfbfbf;
  --top-bar-bg-darken-50: gray;
  --top-bar-bg-darken-75: #404040;
  --top-bar-border-color: #e9ecef;
  --top-bar-border-color-desaturated-12: #ebeced;
  --top-bar-border-color-desaturated-25: #ececec;
  --top-bar-border-color-desaturated-50: #ececec;
  --top-bar-border-color-desaturated-75: #ececec;
  --top-bar-border-color-darken-12: #c4ccd4;
  --top-bar-border-color-darken-25: #9facb9;
  --top-bar-border-color-darken-50: #5b6d7e;
  --top-bar-border-color-darken-75: #262d34;
  --sidebar-bg: #f9f9f9;
  --sidebar-bg-desaturated-12: #f9f9f9;
  --sidebar-bg-desaturated-25: #f9f9f9;
  --sidebar-bg-desaturated-50: #f9f9f9;
  --sidebar-bg-desaturated-75: #f9f9f9;
  --sidebar-bg-darken-12: #d9d9d9;
  --sidebar-bg-darken-25: #b9b9b9;
  --sidebar-bg-darken-50: #7a7a7a;
  --sidebar-bg-darken-75: #3a3a3a;
  --sidebar-ul-border: #f9f9f9;
  --sidebar-ul-border-desaturated-12: #f9f9f9;
  --sidebar-ul-border-desaturated-25: #f9f9f9;
  --sidebar-ul-border-desaturated-50: #f9f9f9;
  --sidebar-ul-border-desaturated-75: #f9f9f9;
  --sidebar-ul-border-darken-12: #d9d9d9;
  --sidebar-ul-border-darken-25: #b9b9b9;
  --sidebar-ul-border-darken-50: #7a7a7a;
  --sidebar-ul-border-darken-75: #3a3a3a;
  --sidebar-1st-level-color: #9b9b9b;
  --sidebar-1st-level-color-desaturated-12: #9b9b9b;
  --sidebar-1st-level-color-desaturated-25: #9b9b9b;
  --sidebar-1st-level-color-desaturated-50: #9b9b9b;
  --sidebar-1st-level-color-desaturated-75: #9b9b9b;
  --sidebar-1st-level-color-darken-12: #7b7b7b;
  --sidebar-1st-level-color-darken-25: #5b5b5b;
  --sidebar-1st-level-color-darken-50: #1c1c1c;
  --sidebar-1st-level-color-darken-75: black;
  --sidebar-1st-level-active-bg: #ffffff;
  --sidebar-1st-level-active-bg-desaturated-12: white;
  --sidebar-1st-level-active-bg-desaturated-25: white;
  --sidebar-1st-level-active-bg-desaturated-50: white;
  --sidebar-1st-level-active-bg-desaturated-75: white;
  --sidebar-1st-level-active-bg-darken-12: #dfdfdf;
  --sidebar-1st-level-active-bg-darken-25: #bfbfbf;
  --sidebar-1st-level-active-bg-darken-50: gray;
  --sidebar-1st-level-active-bg-darken-75: #404040;
  --sidebar-1st-level-active-color: #c6c6c6;
  --sidebar-1st-level-active-color-desaturated-12: #c6c6c6;
  --sidebar-1st-level-active-color-desaturated-25: #c6c6c6;
  --sidebar-1st-level-active-color-desaturated-50: #c6c6c6;
  --sidebar-1st-level-active-color-desaturated-75: #c6c6c6;
  --sidebar-1st-level-active-color-darken-12: #a6a6a6;
  --sidebar-1st-level-active-color-darken-25: #868686;
  --sidebar-1st-level-active-color-darken-50: #474747;
  --sidebar-1st-level-active-color-darken-75: #070707;
  --sidebar-1st-level-hover-bg: #e9ecef;
  --sidebar-1st-level-hover-bg-desaturated-12: #ebeced;
  --sidebar-1st-level-hover-bg-desaturated-25: #ececec;
  --sidebar-1st-level-hover-bg-desaturated-50: #ececec;
  --sidebar-1st-level-hover-bg-desaturated-75: #ececec;
  --sidebar-1st-level-hover-bg-darken-12: #c4ccd4;
  --sidebar-1st-level-hover-bg-darken-25: #9facb9;
  --sidebar-1st-level-hover-bg-darken-50: #5b6d7e;
  --sidebar-1st-level-hover-bg-darken-75: #262d34;
  --sidebar-1st-level-hover-color: #9b9b9b;
  --sidebar-1st-level-hover-color-desaturated-12: #9b9b9b;
  --sidebar-1st-level-hover-color-desaturated-25: #9b9b9b;
  --sidebar-1st-level-hover-color-desaturated-50: #9b9b9b;
  --sidebar-1st-level-hover-color-desaturated-75: #9b9b9b;
  --sidebar-1st-level-hover-color-darken-12: #7b7b7b;
  --sidebar-1st-level-hover-color-darken-25: #5b5b5b;
  --sidebar-1st-level-hover-color-darken-50: #1c1c1c;
  --sidebar-1st-level-hover-color-darken-75: black;
  --sidebar-2nd-level-color: #9b9b9b;
  --sidebar-2nd-level-color-desaturated-12: #9b9b9b;
  --sidebar-2nd-level-color-desaturated-25: #9b9b9b;
  --sidebar-2nd-level-color-desaturated-50: #9b9b9b;
  --sidebar-2nd-level-color-desaturated-75: #9b9b9b;
  --sidebar-2nd-level-color-darken-12: #7b7b7b;
  --sidebar-2nd-level-color-darken-25: #5b5b5b;
  --sidebar-2nd-level-color-darken-50: #1c1c1c;
  --sidebar-2nd-level-color-darken-75: black;
  --sidebar-2nd-level-active-bg: #f9f9f9;
  --sidebar-2nd-level-active-bg-desaturated-12: #f9f9f9;
  --sidebar-2nd-level-active-bg-desaturated-25: #f9f9f9;
  --sidebar-2nd-level-active-bg-desaturated-50: #f9f9f9;
  --sidebar-2nd-level-active-bg-desaturated-75: #f9f9f9;
  --sidebar-2nd-level-active-bg-darken-12: #d9d9d9;
  --sidebar-2nd-level-active-bg-darken-25: #b9b9b9;
  --sidebar-2nd-level-active-bg-darken-50: #7a7a7a;
  --sidebar-2nd-level-active-bg-darken-75: #3a3a3a;
  --sidebar-2nd-level-active-color: #e94e1b;
  --sidebar-2nd-level-active-color-desaturated-12: #d9562b;
  --sidebar-2nd-level-active-color-desaturated-25: #ca5e3a;
  --sidebar-2nd-level-active-color-desaturated-50: #ab6e5a;
  --sidebar-2nd-level-active-color-desaturated-75: #8b7d79;
  --sidebar-2nd-level-active-color-darken-12: #b33911;
  --sidebar-2nd-level-active-color-darken-25: #79270c;
  --sidebar-2nd-level-active-color-darken-50: #050100;
  --sidebar-2nd-level-active-color-darken-75: black;
  --sidebar-2nd-level-hover-bg: #f9f9f9;
  --sidebar-2nd-level-hover-bg-desaturated-12: #f9f9f9;
  --sidebar-2nd-level-hover-bg-desaturated-25: #f9f9f9;
  --sidebar-2nd-level-hover-bg-desaturated-50: #f9f9f9;
  --sidebar-2nd-level-hover-bg-desaturated-75: #f9f9f9;
  --sidebar-2nd-level-hover-bg-darken-12: #d9d9d9;
  --sidebar-2nd-level-hover-bg-darken-25: #b9b9b9;
  --sidebar-2nd-level-hover-bg-darken-50: #7a7a7a;
  --sidebar-2nd-level-hover-bg-darken-75: #3a3a3a;
  --sidebar-2nd-level-hover-color: #c6c6c6;
  --sidebar-2nd-level-hover-color-desaturated-12: #c6c6c6;
  --sidebar-2nd-level-hover-color-desaturated-25: #c6c6c6;
  --sidebar-2nd-level-hover-color-desaturated-50: #c6c6c6;
  --sidebar-2nd-level-hover-color-desaturated-75: #c6c6c6;
  --sidebar-2nd-level-hover-color-darken-12: #a6a6a6;
  --sidebar-2nd-level-hover-color-darken-25: #868686;
  --sidebar-2nd-level-hover-color-darken-50: #474747;
  --sidebar-2nd-level-hover-color-darken-75: #070707;
  --badge-positive-bg: #21a366;
  --badge-positive-bg-desaturated-12: #2d9765;
  --badge-positive-bg-desaturated-25: #3a8b64;
  --badge-positive-bg-desaturated-50: #527263;
  --badge-positive-bg-desaturated-75: #626262;
  --badge-positive-bg-darken-12: #166e45;
  --badge-positive-bg-darken-25: #0c3924;
  --badge-positive-bg-darken-50: black;
  --badge-positive-bg-darken-75: black;
  --badge-informative-bg: #4ca7b6;
  --badge-informative-bg-desaturated-12: #5c9ca6;
  --badge-informative-bg-desaturated-25: #6c9097;
  --badge-informative-bg-desaturated-50: #818181;
  --badge-informative-bg-desaturated-75: #818181;
  --badge-informative-bg-darken-12: #387e8a;
  --badge-informative-bg-darken-25: #26555d;
  --badge-informative-bg-darken-50: #010202;
  --badge-informative-bg-darken-75: black;
  --badge-warning-bg: #cd9d1d;
  --badge-warning-bg-desaturated-12: #be962c;
  --badge-warning-bg-desaturated-25: #b0903a;
  --badge-warning-bg-desaturated-50: #938258;
  --badge-warning-bg-desaturated-75: #757575;
  --badge-warning-bg-darken-12: #957215;
  --badge-warning-bg-darken-25: #5d470d;
  --badge-warning-bg-darken-50: black;
  --badge-warning-bg-darken-75: black;
  --badge-negative-bg: #E65E1C;
  --badge-negative-bg-desaturated-12: #d6632c;
  --badge-negative-bg-desaturated-25: #c7693c;
  --badge-negative-bg-desaturated-50: #a7745b;
  --badge-negative-bg-desaturated-75: #887f7b;
  --badge-negative-bg-darken-12: #af4613;
  --badge-negative-bg-darken-25: #762f0d;
  --badge-negative-bg-darken-50: #030100;
  --badge-negative-bg-darken-75: black;
  --badge-neutral-bg: #808080;
  --badge-neutral-bg-desaturated-12: gray;
  --badge-neutral-bg-desaturated-25: gray;
  --badge-neutral-bg-desaturated-50: gray;
  --badge-neutral-bg-desaturated-75: gray;
  --badge-neutral-bg-darken-12: #606060;
  --badge-neutral-bg-darken-25: #404040;
  --badge-neutral-bg-darken-50: #010101;
  --badge-neutral-bg-darken-75: black;
  --white: #ffffff;
  --gray-100: #f5f5f5;
  --gray-200: #eaeaea;
  --gray-300: #e1e1e1;
  --gray-400: #cacaca;
  --gray-500: #b3b3b3;
  --gray-600: #8e8e8e;
  --gray-700: #6e6e6e;
  --gray-800: #4b4b4b;
  --gray-900: #2c2c2c;
  --green-100: #BBE5B3;
  --green-500: #50B83C;
  --green-1000: #108043;
  --teal-100: #B7ECEC;
  --teal-500: #47C1BF;
  --teal-1000: #00848E;
  --yellow-100: #fef6dd;
  --yellow-500: #EEC200;
  --yellow-1000: #8A6116;
  --red-100: #fff2f3;
  --red-500: #DE3618;
  --red-1000: #BF0711;
  --black: #000000;
  --success-transparent: rgba(107, 156, 20, 0.25);
  --table-dark-bg: #6e6e6e;
  --table-dark-border-color: lighten($table-dark-bg, 7.5%);
  --topbar-height: 3.125rem;
  --sidebar-width: 15rem;
  --sidebar-transition: all 0.3s ease-in-out;
  --font-family-base: $font-family-sans-serif;
  --body-bg: ;
  --primary: #e94e1b;
  --secondary: #3f9aaa;
  --filters-color: #495057;
  --filters-border: #ced4da;
  --filters-background: #efefef;
  --success: #6b9c14;
  --warning: ;
  --opened-by-user: red;
}

.mv4iot-fe-maintenance .treeview-container > .PlantTreeViewComponent > li {
  width: 45rem;
}

.mv4iot-fe-maintenance .row-highlighted-overdue.-odd {
  background-color: #f1dede !important;
}
.mv4iot-fe-maintenance .row-highlighted-overdue.-odd:hover {
  background-color: #e2baba !important;
}

.mv4iot-fe-maintenance .row-highlighted-overdue.-even {
  background-color: #ffefef !important;
}
.mv4iot-fe-maintenance .row-highlighted-overdue.-even:hover {
  background-color: #e2baba !important;
}

.spinner {
  color: #e1e1e1;
  color: var(--gray-300);
}

.list-group-item-action {
  cursor: pointer;
}

.modal, .mv-modal {
  display: block;
  background: rgba(#000000, 0.5);
  background: rgba(var(--black), 0.5);
  overflow: auto;
}

.noty_theme__bootstrap-v4.noty_bar .noty_body {
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
}

option:disabled {
  color: #ccc;
}

.topbar .profile-toggler {
  color: #6e6e6e;
  color: var(--gray-700);
}

table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
}

table.dataTable td,
table.dataTable th {
  box-sizing: content-box;
}

table.dataTable td.dataTables_empty,
table.dataTable th.dataTables_empty {
  text-align: center;
}

table.dataTable.nowrap th,
table.dataTable.nowrap td {
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_length label {
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_length select {
  width: 75px;
  display: inline-block;
}

div.dataTables_wrapper div.dataTables_filter label {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
}

div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5em;
  display: inline-block;
  width: auto;
}

div.dataTables_wrapper div.dataTables_info {
  padding-top: 0.85em;
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_paginate {
  margin: 0;
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 2px 0;
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  margin-top: -26px;
  text-align: center;
  padding: 1em 0;
}

table.dataTable thead > tr > th.sorting_asc,
table.dataTable thead > tr > th.sorting_desc,
table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting {
  padding-right: 30px;
}

table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
  outline: none;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 0.9em;
  display: block;
  opacity: 0.3;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  right: 1em;
  content: "\2191";
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  right: 0.5em;
  content: "\2193";
}

table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:after {
  opacity: 1;
}

table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  opacity: 0;
}

div.dataTables_scrollHead table.dataTable {
  margin-bottom: 0 !important;
}

div.dataTables_scrollBody table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

div.dataTables_scrollBody table thead .sorting:after,
div.dataTables_scrollBody table thead .sorting_asc:after,
div.dataTables_scrollBody table thead .sorting_desc:after {
  display: none;
}

div.dataTables_scrollBody table tbody tr:first-child th,
div.dataTables_scrollBody table tbody tr:first-child td {
  border-top: none;
}

div.dataTables_scrollFoot > .dataTables_scrollFootInner {
  box-sizing: content-box;
}

div.dataTables_scrollFoot > .dataTables_scrollFootInner > table {
  margin-top: 0 !important;
  border-top: none;
}

table.dataTable.table-sm > thead > tr > th {
  padding-right: 20px;
}

table.dataTable.table-sm .sorting:before,
table.dataTable.table-sm .sorting_asc:before,
table.dataTable.table-sm .sorting_desc:before {
  top: 5px;
  right: 0.85em;
}

table.dataTable.table-sm .sorting:after,
table.dataTable.table-sm .sorting_asc:after,
table.dataTable.table-sm .sorting_desc:after {
  top: 5px;
}

table.table-bordered.dataTable th,
table.table-bordered.dataTable td {
  border-left-width: 0;
}

table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable td:last-child,
table.table-bordered.dataTable td:last-child {
  border-right-width: 0;
}

table.table-bordered.dataTable tbody th,
table.table-bordered.dataTable tbody td {
  border-bottom-width: 0;
}

div.dataTables_scrollHead table.table-bordered {
  border-bottom-width: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row {
  margin: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:first-child {
  padding-left: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:last-child {
  padding-right: 0;
}

.table-subtitle-td p {
  margin-bottom: 0;
  line-height: 1;
}
.table-subtitle-td small {
  color: #757575;
}

.disabled-light:disabled {
  background-color: #ffffff;
  background-color: var(--white);
  color: #6e6e6e;
  color: var(--gray-700);
  border-color: #6e6e6e;
  border-color: var(--gray-700);
}

.btn, .mv-icon-btn {
  border-radius: 0;
}

input, select, button {
  border-radius: 0 !important;
}

.pointer {
  cursor: pointer;
}
.pointer:hover {
  color: #e94e1b !important;
  color: var(--primary) !important;
}

.main-container header {
  padding: 1.5rem 2rem 1rem 2rem;
}

h1 .title {
  font-weight: 400;
}

.form-actions {
  align-items: end;
}
.form-actions button:not(:first-child) {
  margin-left: 1rem;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  border-color: #ccc;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  margin-left: -4px;
  position: absolute;
  width: 0;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-top: none;
  border-bottom-color: #f0f0f0;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  top: 0;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  bottom: 0;
  margin-bottom: -8px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
  border-bottom: none;
  border-top-color: #fff;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
  bottom: 0;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
  bottom: -1px;
  border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}

.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}
.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}
.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}
.react-datepicker-popper[data-placement^=bottom] {
  padding-top: 10px;
}
.react-datepicker-popper[data-placement=bottom-end] .react-datepicker__triangle, .react-datepicker-popper[data-placement=top-end] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}
.react-datepicker-popper[data-placement^=top] {
  padding-bottom: 10px;
}
.react-datepicker-popper[data-placement^=right] {
  padding-left: 8px;
}
.react-datepicker-popper[data-placement^=right] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}
.react-datepicker-popper[data-placement^=left] {
  padding-right: 8px;
}
.react-datepicker-popper[data-placement^=left] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}
.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}
.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 2px;
  padding: 0;
  border: none;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  overflow: hidden;
}
.react-datepicker__navigation--previous {
  left: 2px;
}
.react-datepicker__navigation--next {
  right: 2px;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 85px;
}
.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__navigation--years-previous {
  top: 4px;
}
.react-datepicker__navigation--years-upcoming {
  top: -4px;
}
.react-datepicker__navigation:hover *::before {
  border-color: #a6a6a6;
}

.react-datepicker__navigation-icon {
  position: relative;
  top: -1px;
  font-size: 20px;
  width: 0;
}
.react-datepicker__navigation-icon--next {
  left: -2px;
}
.react-datepicker__navigation-icon--next::before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  left: -7px;
}
.react-datepicker__navigation-icon--previous {
  right: -2px;
}
.react-datepicker__navigation-icon--previous::before {
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
  right: -7px;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  margin: 0.4rem;
  text-align: center;
}
.react-datepicker__year-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 180px;
}
.react-datepicker__year .react-datepicker__year-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}
.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time] {
  -moz-appearance: textfield;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 85px;
}
.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -72px;
  top: 0;
}
.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  box-sizing: content-box;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-names {
  margin-bottom: -8px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}
.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
  background-color: #1d5d90;
}
.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none;
}
.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  font-weight: bold;
}
.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}
.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
  background-color: #32be3f;
}
.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1,
.react-datepicker__year-text--highlighted-custom-1 {
  color: magenta;
}
.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2,
.react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #1d5d90;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #2a87d0;
  color: #fff;
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #1d5d90;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range) {
  background-color: rgba(33, 107, 165, 0.5);
}
.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range) {
  background-color: #f0f0f0;
  color: #000;
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
}
.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background-color: transparent;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #216ba5;
}
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background-color: #f0f0f0;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
  position: relative;
}
.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}
.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
  right: -16px;
  top: 0;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
}
.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}
.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}
.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #216ba5;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "\D7";
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}
.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}
@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}
.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.noty_layout_mixin, #noty_layout__centerRight, #noty_layout__centerLeft, #noty_layout__center, #noty_layout__bottomRight, #noty_layout__bottomCenter, #noty_layout__bottomLeft, #noty_layout__bottom, #noty_layout__topRight, #noty_layout__topCenter, #noty_layout__topLeft, #noty_layout__top {
  position: fixed;
  margin: 0;
  padding: 0;
  z-index: 9999999;
  -webkit-transform: translateZ(0) scale(1, 1);
          transform: translateZ(0) scale(1, 1);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  filter: blur(0);
  -webkit-filter: blur(0);
  max-width: 90%;
}

#noty_layout__top {
  top: 0;
  left: 5%;
  width: 90%;
}

#noty_layout__topLeft {
  top: 20px;
  left: 20px;
  width: 325px;
}

#noty_layout__topCenter {
  top: 5%;
  left: 50%;
  width: 325px;
  -webkit-transform: translate(calc(-50% - .5px)) translateZ(0) scale(1, 1);
          transform: translate(calc(-50% - .5px)) translateZ(0) scale(1, 1);
}

#noty_layout__topRight {
  top: 20px;
  right: 20px;
  width: 325px;
}

#noty_layout__bottom {
  bottom: 0;
  left: 5%;
  width: 90%;
}

#noty_layout__bottomLeft {
  bottom: 20px;
  left: 20px;
  width: 325px;
}

#noty_layout__bottomCenter {
  bottom: 5%;
  left: 50%;
  width: 325px;
  -webkit-transform: translate(calc(-50% - .5px)) translateZ(0) scale(1, 1);
          transform: translate(calc(-50% - .5px)) translateZ(0) scale(1, 1);
}

#noty_layout__bottomRight {
  bottom: 20px;
  right: 20px;
  width: 325px;
}

#noty_layout__center {
  top: 50%;
  left: 50%;
  width: 325px;
  -webkit-transform: translate(calc(-50% - .5px), calc(-50% - .5px)) translateZ(0) scale(1, 1);
          transform: translate(calc(-50% - .5px), calc(-50% - .5px)) translateZ(0) scale(1, 1);
}

#noty_layout__centerLeft {
  top: 50%;
  left: 20px;
  width: 325px;
  -webkit-transform: translate(0, calc(-50% - .5px)) translateZ(0) scale(1, 1);
          transform: translate(0, calc(-50% - .5px)) translateZ(0) scale(1, 1);
}

#noty_layout__centerRight {
  top: 50%;
  right: 20px;
  width: 325px;
  -webkit-transform: translate(0, calc(-50% - .5px)) translateZ(0) scale(1, 1);
          transform: translate(0, calc(-50% - .5px)) translateZ(0) scale(1, 1);
}

.noty_progressbar {
  display: none;
}

.noty_has_timeout.noty_has_progressbar .noty_progressbar {
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 100%;
  background-color: #646464;
  opacity: 0.2;
  filter: alpha(opacity=10);
}

.noty_bar {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate(0, 0) translateZ(0) scale(1, 1);
  transform: translate(0, 0) scale(1, 1);
  -webkit-font-smoothing: subpixel-antialiased;
  overflow: hidden;
}

.noty_effects_open {
  opacity: 0;
  -webkit-transform: translate(50%);
          transform: translate(50%);
  -webkit-animation: noty_anim_in 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
          animation: noty_anim_in 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.noty_effects_close {
  -webkit-animation: noty_anim_out 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
          animation: noty_anim_out 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.noty_fix_effects_height {
  -webkit-animation: noty_anim_height 75ms ease-out;
          animation: noty_anim_height 75ms ease-out;
}

.noty_close_with_click {
  cursor: pointer;
}

.noty_close_button {
  position: absolute;
  top: 2px;
  right: 2px;
  font-weight: bold;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.noty_close_button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.noty_modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 10000;
  opacity: 0.3;
  left: 0;
  top: 0;
}

.noty_modal.noty_modal_open {
  opacity: 0;
  -webkit-animation: noty_modal_in 0.3s ease-out;
          animation: noty_modal_in 0.3s ease-out;
}

.noty_modal.noty_modal_close {
  -webkit-animation: noty_modal_out 0.3s ease-out;
          animation: noty_modal_out 0.3s ease-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes noty_modal_in {
  100% {
    opacity: 0.3;
  }
}

@keyframes noty_modal_in {
  100% {
    opacity: 0.3;
  }
}
@-webkit-keyframes noty_modal_out {
  100% {
    opacity: 0;
  }
}
@keyframes noty_modal_out {
  100% {
    opacity: 0;
  }
}
@keyframes noty_modal_out {
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes noty_anim_in {
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
    opacity: 1;
  }
}
@keyframes noty_anim_in {
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
    opacity: 1;
  }
}
@-webkit-keyframes noty_anim_out {
  100% {
    -webkit-transform: translate(50%);
            transform: translate(50%);
    opacity: 0;
  }
}
@keyframes noty_anim_out {
  100% {
    -webkit-transform: translate(50%);
            transform: translate(50%);
    opacity: 0;
  }
}
@-webkit-keyframes noty_anim_height {
  100% {
    height: 0;
  }
}
@keyframes noty_anim_height {
  100% {
    height: 0;
  }
}
.noty_theme__bootstrap-v4.noty_bar {
  margin: 4px 0;
  overflow: hidden;
  position: relative;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.noty_theme__bootstrap-v4.noty_bar .noty_body {
  padding: 0.75rem 1.25rem;
}
.noty_theme__bootstrap-v4.noty_bar .noty_buttons {
  padding: 10px;
}
.noty_theme__bootstrap-v4.noty_bar .noty_close_button {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 0.5;
  background: transparent;
}
.noty_theme__bootstrap-v4.noty_bar .noty_close_button:hover {
  background: transparent;
  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=50);
  opacity: 0.75;
}

.noty_theme__bootstrap-v4.noty_type__alert,
.noty_theme__bootstrap-v4.noty_type__notification {
  background-color: #fff;
  color: inherit;
}

.noty_theme__bootstrap-v4.noty_type__warning {
  background-color: #fcf8e3;
  color: #8a6d3b;
  border-color: #faebcc;
}

.noty_theme__bootstrap-v4.noty_type__error {
  background-color: #f2dede;
  color: #a94442;
  border-color: #ebccd1;
}

.noty_theme__bootstrap-v4.noty_type__info,
.noty_theme__bootstrap-v4.noty_type__information {
  background-color: #d9edf7;
  color: #31708f;
  border-color: #bce8f1;
}

.noty_theme__bootstrap-v4.noty_type__success {
  background-color: #dff0d8;
  color: #3c763d;
  border-color: #d6e9c6;
}

.daterangepicker {
  position: absolute;
  color: inherit;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 278px;
  max-width: none;
  padding: 0;
  margin-top: 7px;
  top: 100px;
  left: 20px;
  z-index: 3001;
  display: none;
  font-family: arial;
  font-size: 15px;
  line-height: 1em;
}

.daterangepicker:before, .daterangepicker:after {
  position: absolute;
  display: inline-block;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: "";
}

.daterangepicker:before {
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc;
}

.daterangepicker:after {
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
}

.daterangepicker.opensleft:before {
  right: 9px;
}

.daterangepicker.opensleft:after {
  right: 10px;
}

.daterangepicker.openscenter:before {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.openscenter:after {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.opensright:before {
  left: 9px;
}

.daterangepicker.opensright:after {
  left: 10px;
}

.daterangepicker.drop-up {
  margin-top: -7px;
}

.daterangepicker.drop-up:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #ccc;
}

.daterangepicker.drop-up:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid #fff;
}

.daterangepicker.single .daterangepicker .ranges, .daterangepicker.single .drp-calendar {
  float: none;
}

.daterangepicker.single .drp-selected {
  display: none;
}

.daterangepicker.show-calendar .drp-calendar {
  display: block;
}

.daterangepicker.show-calendar .drp-buttons {
  display: block;
}

.daterangepicker.auto-apply .drp-buttons {
  display: none;
}

.daterangepicker .drp-calendar {
  display: none;
  max-width: 270px;
}

.daterangepicker .drp-calendar.left {
  padding: 8px 0 8px 8px;
}

.daterangepicker .drp-calendar.right {
  padding: 8px;
}

.daterangepicker .drp-calendar.single .calendar-table {
  border: none;
}

.daterangepicker .calendar-table .next span, .daterangepicker .calendar-table .prev span {
  color: #fff;
  border: solid black;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px;
}

.daterangepicker .calendar-table .next span {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.daterangepicker .calendar-table .prev span {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  min-width: 32px;
  width: 32px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;
}

.daterangepicker .calendar-table {
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #fff;
}

.daterangepicker .calendar-table table {
  width: 100%;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
}

.daterangepicker td.available:hover, .daterangepicker th.available:hover {
  background-color: #eee;
  border-color: transparent;
  color: inherit;
}

.daterangepicker td.week, .daterangepicker th.week {
  font-size: 80%;
  color: #ccc;
}

.daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
  background-color: #fff;
  border-color: transparent;
  color: #999;
}

.daterangepicker td.in-range {
  background-color: #ebf4f8;
  border-color: transparent;
  color: #000;
  border-radius: 0;
}

.daterangepicker td.start-date {
  border-radius: 4px 0 0 4px;
}

.daterangepicker td.end-date {
  border-radius: 0 4px 4px 0;
}

.daterangepicker td.start-date.end-date {
  border-radius: 4px;
}

.daterangepicker td.active, .daterangepicker td.mv-breadcrumb__item--active, .daterangepicker td.active:hover {
  background-color: #357ebd;
  border-color: transparent;
  color: #fff;
}

.daterangepicker th.month {
  width: auto;
}

.daterangepicker td.disabled, .daterangepicker option.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through;
}

.daterangepicker select.monthselect, .daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}

.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}

.daterangepicker select.yearselect {
  width: 40%;
}

.daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect, .daterangepicker select.ampmselect {
  width: 50px;
  margin: 0 auto;
  background: #eee;
  border: 1px solid #eee;
  padding: 2px;
  outline: 0;
  font-size: 12px;
}

.daterangepicker .calendar-time {
  text-align: center;
  margin: 4px auto 0 auto;
  line-height: 30px;
  position: relative;
}

.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed;
}

.daterangepicker .drp-buttons {
  clear: both;
  text-align: right;
  padding: 8px;
  border-top: 1px solid #ddd;
  display: none;
  line-height: 12px;
  vertical-align: middle;
}

.daterangepicker .drp-selected {
  display: inline-block;
  font-size: 12px;
  padding-right: 8px;
}

.daterangepicker .drp-buttons .btn, .daterangepicker .drp-buttons .mv-icon-btn {
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
}

.daterangepicker.show-ranges.single.rtl .drp-calendar.left {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.single.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker.show-ranges.rtl .drp-calendar.right {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker .ranges {
  float: none;
  text-align: left;
  margin: 0;
}

.daterangepicker.show-calendar .ranges {
  margin-top: 8px;
}

.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

.daterangepicker .ranges li {
  font-size: 12px;
  padding: 8px 12px;
  cursor: pointer;
}

.daterangepicker .ranges li:hover {
  background-color: #eee;
}

.daterangepicker .ranges li.active, .daterangepicker .ranges li.mv-breadcrumb__item--active {
  background-color: #08c;
  color: #fff;
}

/*  Larger Screen Styling */
@media (min-width: 564px) {
  .daterangepicker {
    width: auto;
  }

  .daterangepicker .ranges ul {
    width: 140px;
  }

  .daterangepicker.single .ranges ul {
    width: 100%;
  }

  .daterangepicker.single .drp-calendar.left {
    clear: none;
  }

  .daterangepicker.single .ranges, .daterangepicker.single .drp-calendar {
    float: left;
  }

  .daterangepicker {
    direction: ltr;
    text-align: left;
  }

  .daterangepicker .drp-calendar.left {
    clear: left;
    margin-right: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .daterangepicker .drp-calendar.right {
    margin-left: 0;
  }

  .daterangepicker .drp-calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    padding-right: 8px;
  }

  .daterangepicker .ranges, .daterangepicker .drp-calendar {
    float: left;
  }
}
@media (min-width: 730px) {
  .daterangepicker .ranges {
    width: auto;
  }

  .daterangepicker .ranges {
    float: left;
  }

  .daterangepicker.rtl .ranges {
    float: right;
  }

  .daterangepicker .drp-calendar.left {
    clear: none !important;
  }
}
#fetch-wrapper-noty-container .noty_body {
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
}

body {
  overflow-x: hidden;
}

#widget-area-topbar-right {
  margin-left: 2rem;
}

.topbar,
.sidebar-topbar {
  height: 3.125rem;
  height: var(--topbar-height);
  background-color: #fff;
  background-color: var(--top-bar-bg);
  color: #f5f5f5;
  color: var(--gray-100);
}

.topbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 1rem;
  z-index: 30;
  border-bottom: 1px solid #e9ecef;
  border-bottom: 1px solid var(--top-bar-border-color);
}
@media (min-width: 1200px) {
  .topbar {
    padding-left: 15rem;
    padding-left: var(--sidebar-width);
  }
}
.topbar .profile-toggler:focus, .topbar .profile-toggler:hover {
  color: #cacaca;
  color: var(--gray-400);
  text-decoration: none;
}

.topbar-user-image {
  width: 2.25rem;
  margin-top: -0.125rem;
  border-radius: 50%;
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 31;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  transition: var(--sidebar-transition);
  opacity: 0;
}
.sidebar-overlay.is-open {
  opacity: 1;
}

.sidebar-panel {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 15rem;
  width: var(--sidebar-width);
  background-color: #f9f9f9;
  background-color: var(--sidebar-bg);
  transition: all 0.3s ease-in-out;
  transition: var(--sidebar-transition);
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  overflow: hidden;
  z-index: 32;
}
@media (min-width: 1200px) {
  .sidebar-panel {
    -webkit-backface-visibility: visible;
            backface-visibility: visible;
    -webkit-transform: translateX(0) translateZ(0);
            transform: translateX(0) translateZ(0);
  }
}
.sidebar-panel.is-open {
  -webkit-backface-visibility: visible;
          backface-visibility: visible;
  -webkit-transform: translateX(0) translateZ(0);
          transform: translateX(0) translateZ(0);
}

.sidebar-logo {
  display: block;
  width: 100%;
  height: 3.125rem;
  height: var(--topbar-height);
  line-height: 3.125rem;
  line-height: var(--topbar-height);
  padding: 0 1rem;
  background-color: #fff;
  background-color: var(--top-bar-bg);
  font-size: 1.25rem;
  color: #ffffff;
  color: var(--white);
  border-bottom: 1px solid #e9ecef;
  border-bottom: 1px solid var(--top-bar-border-color);
}
.sidebar-logo:hover {
  color: #ffffff;
  color: var(--white);
}

#sideBar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
#sideBar li {
  padding: 0;
}
#sideBar aside a {
  font-weight: 400;
  padding: 0.5rem 1rem;
  text-decoration: none;
}
#sideBar aside a .icon {
  width: 2rem;
}
#sideBar aside a .nav-item-image {
  height: 1.5rem;
}
#sideBar .sidebar-menu > li {
  width: 100%;
}
#sideBar .sidebar-menu > li:hover {
  background-color: #e9ecef;
  background-color: var(--sidebar-1st-level-hover-bg);
  color: #9b9b9b;
  color: var(--sidebar-1st-level-hover-color);
}
#sideBar .sidebar-menu > li.active, #sideBar .sidebar-menu > li.mv-breadcrumb__item--active {
  background-color: #ffffff;
  background-color: var(--sidebar-1st-level-active-bg);
}
#sideBar .sidebar-menu > li.active > ul, #sideBar .sidebar-menu > li.mv-breadcrumb__item--active > ul {
  background-color: #f9f9f9;
  background-color: var(--sidebar-bg);
}
#sideBar .sidebar-menu-li > a,
#sideBar .sidebar-menu > li > a {
  padding: 0.5rem 1rem 0.5rem 0.6rem;
  color: #9b9b9b;
  color: var(--sidebar-1st-level-color);
}
#sideBar .sidebar-menu-li > a:hover,
#sideBar .sidebar-menu > li > a:hover {
  color: #9b9b9b;
  color: var(--sidebar-1st-level-hover-color);
}
#sideBar .sidebar-menu-li > a.active, #sideBar .sidebar-menu-li > a.mv-breadcrumb__item--active,
#sideBar .sidebar-menu > li > a.active,
#sideBar .sidebar-menu > li > a.mv-breadcrumb__item--active {
  background-color: #ffffff;
  background-color: var(--sidebar-1st-level-active-bg);
}
#sideBar .sidebar-menu-submenu > li {
  padding-left: 2.15rem;
  font-size: 0.9rem;
  background-color: var(--sidebar-2nd-level-bg);
}
#sideBar .sidebar-menu-submenu > li.active, #sideBar .sidebar-menu-submenu > li.mv-breadcrumb__item--active {
  border-left: 4px solid #e94e1b;
  border-left: 4px solid var(--primary);
  background-color: #f9f9f9;
  background-color: var(--sidebar-2nd-level-active-bg);
}
#sideBar .sidebar-menu-submenu > li.active a, #sideBar .sidebar-menu-submenu > li.mv-breadcrumb__item--active a {
  color: #e94e1b;
  color: var(--sidebar-2nd-level-active-color);
}
#sideBar .sidebar-menu-submenu > li:hover {
  background-color: #f9f9f9;
  background-color: var(--sidebar-2nd-level-hover-bg);
}
#sideBar .sidebar-menu-submenu > li > a {
  color: #9b9b9b;
  color: var(--sidebar-2nd-level-color);
  padding: 0.3rem 1rem 0.3rem 0.5rem;
}
#sideBar .sidebar-menu-submenu > li > a:hover {
  color: #c6c6c6;
  color: var(--sidebar-2nd-level-hover-color);
}

.sidebar-toggler {
  position: fixed;
  background: transparent;
  color: #cacaca;
  color: var(--gray-400);
  border-radius: 0;
  z-index: 31;
}
.sidebar-toggler:hover {
  background: #f5f5f5;
  background: var(--gray-100);
}
@media (min-width: 1200px) {
  .sidebar-toggler {
    display: none;
  }
}

.main-container {
  min-height: 100vh;
  padding-top: 3.125rem;
  padding-top: var(--topbar-height);
  transition: all 0.3s ease-in-out;
  transition: var(--sidebar-transition);
  background-color: #ffffff;
  background-color: var(--white);
}
.main-container.is-mobile {
  padding-top: 1rem;
}
@media (min-width: 1200px) {
  .main-container {
    padding-left: 15rem;
    padding-left: var(--sidebar-width);
  }
  .main-container.is-fullwidth {
    padding-left: 0;
  }
}
.main-container header {
  padding: 1rem 2rem;
}
.main-container header h1 {
  font-size: 1.5rem;
  letter-spacing: -0.025em;
  margin: 0;
}
.main-container header h1 small {
  display: block;
  font-size: 60%;
  line-height: 1.25;
  margin-top: 0.25em;
}
.main-container .content {
  margin: 0 2rem;
  padding: 0 0 2rem 0;
}
@media screen and (max-width: 767px) {
  .main-container .content {
    margin: 1rem;
    padding: 1rem;
  }
}

.main-content {
  padding: 1rem 1rem 3rem;
}
@media (min-width: 1200px) {
  .main-content {
    padding-left: 2rem;
  }
}

@media print {
  #topBar,
.sidebar-toggler {
    display: none !important;
  }

  .col-print-1 {
    width: 8% !important;
    flex: 0 0 8% !important;
    max-width: 8% !important;
    float: left !important;
  }

  .col-print-2 {
    width: 16% !important;
    flex: 0 0 16% !important;
    max-width: 16% !important;
    float: left !important;
  }

  .col-print-3 {
    width: 25% !important;
    flex: 0 0 25% !important;
    max-width: 25% !important;
    float: left !important;
  }

  .col-print-4 {
    width: 33% !important;
    flex: 0 0 33% !important;
    max-width: 33% !important;
    float: left !important;
  }

  .col-print-5 {
    width: 42% !important;
    flex: 0 0 42% !important;
    max-width: 42% !important;
    float: left !important;
  }

  .col-print-6 {
    width: 50% !important;
    flex: 0 0 50% !important;
    max-width: 50% !important;
    float: left !important;
  }

  .col-print-7 {
    width: 58% !important;
    flex: 0 0 58% !important;
    max-width: 58% !important;
    float: left !important;
  }

  .col-print-8 {
    width: 66% !important;
    flex: 0 0 66% !important;
    max-width: 66% !important;
    float: left !important;
  }

  .col-print-9 {
    width: 75% !important;
    flex: 0 0 75% !important;
    max-width: 75% !important;
    float: left !important;
  }

  .col-print-10 {
    width: 83% !important;
    flex: 0 0 83% !important;
    max-width: 83% !important;
    float: left !important;
  }

  .col-print-11 {
    width: 92% !important;
    flex: 0 0 92% !important;
    max-width: 92% !important;
    float: left !important;
  }

  .col-print-12 {
    width: 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
    float: left !important;
  }
}
.data-tooltips {
  position: relative;
}
.data-tooltips::before {
  content: "";
  position: absolute;
  top: -6px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-width: 4px 6px 0 6px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
  z-index: 100;
  transition: opacity 0.3s;
  opacity: 0;
}
.data-tooltips::after {
  content: attr(data-title);
  position: absolute;
  left: 50%;
  top: -6px;
  -webkit-transform: translateX(-50%) translateY(-100%);
          transform: translateX(-50%) translateY(-100%);
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  color: #fff;
  padding: 4px 2px;
  font-size: 12px;
  min-width: 100px;
  border-radius: 5px;
  pointer-events: none;
  transition: opacity 0.3s;
  opacity: 0;
}
.data-tooltips:hover::before, .data-tooltips:hover::after {
  opacity: 1;
}

.btn-over-tabs {
  top: 8px;
  right: 10px;
}

.vcenter-50h {
  vertical-align: middle;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.error-box {
  font-size: 24px;
  border: 1px solid #eee;
  border-radius: 4px;
  box-shadow: 0 3px 10px rgba(127, 0, 0, 0.2);
  margin-top: 10vh;
  max-width: 90%;
}
.error-box .error-code {
  color: #e94e1b;
  font-size: 2em;
}
.error-box .error-title {
  font-size: 1em;
}
.error-box .error-message {
  font-size: 0.8em;
  color: #555;
}

.cliccable .card-body,
.cliccable .card-body svg.recharts-surface {
  cursor: pointer;
}

.widget-box-rt_measures {
  font-size: 0.8rem;
  position: relative;
  margin-bottom: 1rem;
}
.widget-box-rt_measures ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.widget-box-rt_measures .label {
  font-weight: 700;
  text-transform: uppercase;
}
.widget-box-rt_measures .is-reloading .value,
.widget-box-rt_measures .is-reloading .trend {
  opacity: 0.3;
}
.widget-box-rt_measures .spinner {
  color: #666;
  position: absolute;
  bottom: 5px;
  right: 5px;
}
.widget-box-rt_measures .value,
.widget-box-rt_measures .trend {
  float: right;
}
.widget-box-rt_measures .trend.trend-down {
  color: darkred;
}
.widget-box-rt_measures .trend.trend-up {
  color: darkgreen;
}
.widget-box-rt_measures .trend.trend-equal {
  color: gray;
}
.widget-box-rt_measures .select-interval {
  font-size: 0.8rem;
  line-height: 1;
  padding: 0.1rem 1.5rem 0.1rem 2px;
  height: auto;
  width: auto;
}

#widget-area-sidebar-bottom {
  margin-top: 200px;
  max-height: 20vh;
  margin-left: 15px;
  width: calc(100% - 30px);
}

.daterangepicker {
  box-shadow: rgba(0, 0, 0, 0.3) 0 5px 16px 0px;
}

.ranges li {
  text-transform: uppercase;
}
.ranges li:hover {
  color: #143c79;
}

.widget-box-rt_measures {
  font-size: 0.8rem;
  position: relative;
  margin-bottom: 1rem;
}
.widget-box-rt_measures ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.widget-box-rt_measures .label {
  font-weight: 700;
  text-transform: uppercase;
}
.widget-box-rt_measures .is-reloading .value,
.widget-box-rt_measures .is-reloading .trend {
  opacity: 0.3;
}
.widget-box-rt_measures .spinner {
  color: #666;
  position: absolute;
  bottom: 5px;
  right: 5px;
}
.widget-box-rt_measures .value,
.widget-box-rt_measures .trend {
  float: right;
}
.widget-box-rt_measures .trend.trend-down {
  color: darkred;
}
.widget-box-rt_measures .trend.trend-up {
  color: darkgreen;
}
.widget-box-rt_measures .trend.trend-equal {
  color: gray;
}
.widget-box-rt_measures .select-interval {
  font-size: 0.8rem;
  line-height: 1;
  padding: 0.1rem 1.5rem 0.1rem 2px;
  height: auto;
  width: auto;
}

.calendar .svg-inline--fa.fa-clock-o {
  display: none !important;
}

.version-checker {
  position: absolute;
  bottom: 0.5rem;
  left: 1rem;
  right: 1rem;
  font-size: small;
}
.version-checker h4 {
  font-size: 1rem;
}
.version-checker .mv-col {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}
.version-checker .current-version {
  text-align: center;
  font-style: italic;
  color: #757575;
  color: var(--secondary-desaturated-50);
  opacity: 0.6;
  font-size: 90%;
}

.WidgetEnvironment .react-datepicker-wrapper,
.WidgetEnvironment .react-datepicker__input-container {
  width: 100%;
}
.WidgetEnvironment svg {
  height: 100%;
  width: 100%;
}
.WidgetEnvironment .list-group {
  color: #fff;
  background-color: #768391;
}
.WidgetEnvironment .list-group-item {
  padding: 4px 10px;
  font-size: 0.8rem;
}
.WidgetEnvironment .axis {
  font: 10px sans-serif;
}
.WidgetEnvironment .axis path,
.WidgetEnvironment .axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}
.WidgetEnvironment text {
  fill: #464646;
}
.WidgetEnvironment .axis text {
  font-size: 0.62rem !important;
}
.WidgetEnvironment .chart-container path {
  fill: none;
  stroke-width: 2;
}
.WidgetEnvironment .category,
.WidgetEnvironment .legend {
  transition: opacity 0.3s;
}
.WidgetEnvironment .legend text {
  font-size: 0.65rem;
}
.WidgetEnvironment .cat-disabled,
.WidgetEnvironment .cat-disabled-hover {
  opacity: 0.2;
}
.WidgetEnvironment .cat-enabled-hover {
  opacity: 1;
}
.WidgetEnvironment .filter-trigger {
  cursor: pointer;
}
.WidgetEnvironment #tooltip {
  position: absolute;
  background-color: #fafafa;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  text-align: left;
  transition: opacity 0.3s, left 0.1s, top 0.1s;
  opacity: 0;
}
.WidgetEnvironment #tooltip ul {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #000;
  line-height: 1.5;
  font-size: 0.75rem;
}
.WidgetEnvironment #tooltip ul span {
  padding-left: 20px;
}
.WidgetEnvironment .chart-container .axis--y {
  transition: opacity 0.3s;
  opacity: 0;
}
.WidgetEnvironment .chart-container.columns-num-1 .axis--y {
  opacity: 1;
}
.WidgetEnvironment .time-label {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #ccc;
}
.WidgetEnvironment .nav.nav-tabs {
  margin-bottom: 15px;
}
.WidgetEnvironment .chart-wrapper,
.WidgetEnvironment .chart-wrapper.full-h {
  height: 100% !important;
}
.WidgetEnvironment .recharts-default-tooltip {
  text-align: left;
  font-size: 0.8rem;
}
.WidgetEnvironment .recharts-cartesian-axis-tick-value {
  font-size: 0.7rem;
}
.WidgetEnvironment .recharts-curve.recharts-line-curve {
  transition: opacity 0.3s;
}
.WidgetEnvironment .recharts-curve.recharts-line-curve .recharts-curve.recharts-line-curve {
  transition: opacity 0.3s;
}
.WidgetEnvironment .chart-container .fa-circle-notch path {
  fill: #ccc;
}
.WidgetEnvironment .nav-tabs {
  background-color: #f8f9fa;
  background-color: var(--light);
}
.WidgetEnvironment .nav-tabs .nav-link.active, .WidgetEnvironment .nav-tabs .nav-link.mv-breadcrumb__item--active {
  border-color: #cecece #cecece #fff;
}
.WidgetEnvironment .y-label {
  position: absolute;
  top: -18px;
  left: 0px;
  font-size: 0.8rem;
  width: 50px;
  text-align: right;
}
.WidgetEnvironment .card-footer .legend {
  list-style: none;
  padding: 0;
  margin: 0;
}
.WidgetEnvironment .card-footer .legend li {
  display: inline-block;
}
.WidgetEnvironment .card-footer .legend li + li {
  margin-left: 25px;
}
.WidgetEnvironment .card-footer .legend-color {
  display: inline-block;
  width: 14px;
  height: 10px;
}
.WidgetEnvironment.WidgetH50 .container,
.WidgetEnvironment.WidgetH50 .container-fluid {
  height: 35vh !important;
}
.WidgetEnvironment.WidgetH50 .container.chart-container, .WidgetEnvironment.WidgetH50 .container .chart-container,
.WidgetEnvironment.WidgetH50 .container-fluid.chart-container,
.WidgetEnvironment.WidgetH50 .container-fluid .chart-container {
  height: 100%;
}
.WidgetEnvironment.WidgetH50 .container.chart-container .full-h, .WidgetEnvironment.WidgetH50 .container .chart-container .full-h,
.WidgetEnvironment.WidgetH50 .container-fluid.chart-container .full-h,
.WidgetEnvironment.WidgetH50 .container-fluid .chart-container .full-h {
  height: 100%;
}
.WidgetEnvironment.WidgetH50 .container.chart-container .full-h .mv-row, .WidgetEnvironment.WidgetH50 .container .chart-container .full-h .mv-row,
.WidgetEnvironment.WidgetH50 .container-fluid.chart-container .full-h .mv-row,
.WidgetEnvironment.WidgetH50 .container-fluid .chart-container .full-h .mv-row {
  height: 100%;
}
.WidgetEnvironment.WidgetH50 .container.chart-container .full-h .mv-row .mv-col, .WidgetEnvironment.WidgetH50 .container .chart-container .full-h .mv-row .mv-col,
.WidgetEnvironment.WidgetH50 .container-fluid.chart-container .full-h .mv-row .mv-col,
.WidgetEnvironment.WidgetH50 .container-fluid .chart-container .full-h .mv-row .mv-col {
  height: 100%;
}
.WidgetEnvironment.WidgetH50 .container .full-h,
.WidgetEnvironment.WidgetH50 .container-fluid .full-h {
  height: 100%;
}

.WidgetButtons .container,
.WidgetButtons .container-fluid, .WidgetButtons.WidgetH50 .container, .WidgetButtons.WidgetH50 .container-fluid {
  min-height: 40vh;
  height: auto !important;
}
.WidgetButtons .btn-group-vertical {
  width: 100%;
}
.WidgetButtons .btn-group-vertical .value {
  font-size: 0.9rem;
  width: 40%;
  display: inline-block;
  text-align: left;
  padding-right: 0;
}
.WidgetButtons .btn-group-vertical .label {
  font-size: 0.7rem;
  width: 60%;
  display: inline-block;
  text-align: right;
}
.WidgetButtons .btn-group-vertical .btn, .WidgetButtons .btn-group-vertical .mv-icon-btn {
  padding: 0.375rem 0.5rem;
}
.WidgetButtons .widget-badge .fa {
  font-size: 1rem;
}
.WidgetButtons .widget-badge .card-title {
  font-size: 0.9rem;
  line-height: 1;
}
.WidgetButtons .widget-badge .card-value {
  font-size: 1.2rem;
  line-height: 1;
}
.WidgetButtons .color-green {
  color: #2ca02c;
}
.WidgetButtons .card-text:last-child {
  margin-bottom: 0;
}
.WidgetButtons .svg-inline--fa.fa-leaf {
  width: 80%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  max-height: 30px;
  margin-top: 0 !important;
}
.WidgetButtons .text-wrap {
  white-space: normal;
}
.WidgetButtons .btn-font-small {
  font-size: 0.75rem;
}
.WidgetButtons .card-body .container {
  padding: 0;
}
.WidgetButtons .buttons-section a.button-header {
  color: #333;
  background-color: #f8f9fa;
  display: inline-block;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 3px 5px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.8rem;
}
.WidgetButtons .buttons-section + .buttons-section {
  margin-top: 25px;
}
.WidgetButtons .buttons-section.active a.button-header, .WidgetButtons .buttons-section.mv-breadcrumb__item--active a.button-header {
  margin-bottom: -3px;
  padding-bottom: 6px;
  cursor: default;
  pointer-events: none;
}
.WidgetButtons .buttons-section.active .btn:first-child, .WidgetButtons .buttons-section.mv-breadcrumb__item--active .btn:first-child, .WidgetButtons .buttons-section.active .mv-icon-btn:first-child, .WidgetButtons .buttons-section.mv-breadcrumb__item--active .mv-icon-btn:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.WidgetButtons .buttons-section-content .fa-circle-notch {
  margin-top: 15px;
  font-size: 1.3rem;
  color: #999;
}
@media screen and (max-width: 1600px) {
  .WidgetButtons .btn-group-vertical .btn, .WidgetButtons .btn-group-vertical .mv-icon-btn {
    display: inline-block;
    width: 100%;
  }
  .WidgetButtons .btn-group-vertical .label,
.WidgetButtons .btn-group-vertical .value {
    float: left;
    clear: both;
    width: 100% !important;
  }
  .WidgetButtons .btn-group-vertical .value {
    order: 2;
  }
  .WidgetButtons .btn-group-vertical .label {
    order: 1;
    font-size: 0.7rem !important;
    color: #a9f2ff;
    text-align: left;
  }
}

.section-buttons button {
  text-transform: uppercase;
}
.section-buttons button + button {
  margin-left: 10px;
}

.btn-group-vertical button:last-child {
  margin-bottom: 1rem;
}

.WidgetEnvironment.WidgetH50 .container,
.WidgetEnvironment.WidgetH50 .container-fluid {
  height: 47vh !important;
}

.chart-wrapper,
.chart-wrapper.full-h {
  height: calc(100% - 50px) !important;
}

.widget-badge {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 100%;
  width: 160px !important;
  height: 160px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  margin: 0 auto;
}
.widget-badge .card-body {
  display: flex;
}
.widget-badge .fa-leaf {
  max-height: 50px !important;
}

.WidgetH50 .container,
.WidgetH50 .container-fluid {
  height: 35vh !important;
}
.WidgetH50 .container.chart-container, .WidgetH50 .container .chart-container,
.WidgetH50 .container-fluid.chart-container,
.WidgetH50 .container-fluid .chart-container {
  height: 100%;
}
.WidgetH50 .container.chart-container .full-h, .WidgetH50 .container .chart-container .full-h,
.WidgetH50 .container-fluid.chart-container .full-h,
.WidgetH50 .container-fluid .chart-container .full-h {
  height: 100%;
}
.WidgetH50 .container.chart-container .full-h .mv-row, .WidgetH50 .container .chart-container .full-h .mv-row,
.WidgetH50 .container-fluid.chart-container .full-h .mv-row,
.WidgetH50 .container-fluid .chart-container .full-h .mv-row {
  height: 100%;
}
.WidgetH50 .container.chart-container .full-h .mv-row .mv-col, .WidgetH50 .container .chart-container .full-h .mv-row .mv-col,
.WidgetH50 .container-fluid.chart-container .full-h .mv-row .mv-col,
.WidgetH50 .container-fluid .chart-container .full-h .mv-row .mv-col {
  height: 100%;
}
.WidgetH50 .container .full-h,
.WidgetH50 .container-fluid .full-h {
  height: 100%;
}

.WidgetsOneLine .DashboardWidget .container,
.WidgetsOneLine .DashboardWidget .container-fluid {
  min-height: 50vh !important;
}
.WidgetsOneLine .DashboardWidget .container.chart-container, .WidgetsOneLine .DashboardWidget .container .chart-container,
.WidgetsOneLine .DashboardWidget .container-fluid.chart-container,
.WidgetsOneLine .DashboardWidget .container-fluid .chart-container {
  height: 100%;
}
.WidgetsOneLine .DashboardWidget .container.chart-container .full-h, .WidgetsOneLine .DashboardWidget .container .chart-container .full-h,
.WidgetsOneLine .DashboardWidget .container-fluid.chart-container .full-h,
.WidgetsOneLine .DashboardWidget .container-fluid .chart-container .full-h {
  height: 100%;
}
.WidgetsOneLine .DashboardWidget .container.chart-container .full-h .mv-row, .WidgetsOneLine .DashboardWidget .container .chart-container .full-h .mv-row,
.WidgetsOneLine .DashboardWidget .container-fluid.chart-container .full-h .mv-row,
.WidgetsOneLine .DashboardWidget .container-fluid .chart-container .full-h .mv-row {
  height: 100%;
}
.WidgetsOneLine .DashboardWidget .container.chart-container .full-h .mv-row .mv-col, .WidgetsOneLine .DashboardWidget .container .chart-container .full-h .mv-row .mv-col,
.WidgetsOneLine .DashboardWidget .container-fluid.chart-container .full-h .mv-row .mv-col,
.WidgetsOneLine .DashboardWidget .container-fluid .chart-container .full-h .mv-row .mv-col {
  height: 100%;
}
.WidgetsOneLine .DashboardWidget .container .full-h,
.WidgetsOneLine .DashboardWidget .container-fluid .full-h {
  height: 100%;
}

.DashboardWidget .axis text {
  font-size: 0.62rem !important;
}

.card-header {
  font-size: 0.8rem;
}

.card-footer .legend {
  list-style: none;
  padding: 0;
  margin: 0;
}
.card-footer .legend li {
  display: inline-block;
}
.card-footer .legend li + li {
  margin-left: 25px;
}
.card-footer .legend-color {
  display: inline-block;
  width: 14px;
  height: 10px;
}

#filters-wrapper .card-body {
  padding: 0.45rem;
}

@media (min-width: 768px) {
  .modal-xl {
    width: 80% !important;
    max-width: 1140px !important;
  }
}
.WidgetAvailabilitySingle .tooltip {
  position: absolute;
  background-color: #fafafa;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  text-align: left;
  transition: opacity 0.3s;
}
.WidgetAvailabilitySingle .tooltip ul {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #000;
  line-height: 1.5;
  font-size: 0.75rem;
}
.WidgetAvailabilitySingle .tooltip ul span {
  padding-left: 20px;
}
.WidgetAvailabilitySingle .tooltip .title {
  font-size: 0.85rem;
  border-bottom: 1px solid #333;
  margin-bottom: 5px;
}

.widget-badge {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 100%;
  width: 160px !important;
  height: 160px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  margin: 0 auto;
}
.widget-badge .card-body {
  display: flex;
}
.widget-badge .fa-leaf {
  max-height: 50px !important;
}
.widget-badge .fa {
  font-size: 1rem;
}
.widget-badge .card-title {
  font-size: 0.9rem;
  line-height: 1;
}
.widget-badge .card-value {
  font-size: 1.2rem;
  line-height: 1;
}
.widget-badge .btn-group-vertical {
  width: 100%;
}
.widget-badge .btn-group-vertical .value {
  font-size: 0.9rem;
  width: 50%;
  display: inline-block;
  text-align: left;
  padding-right: 0;
}
.widget-badge .btn-group-vertical .label {
  font-size: 0.75rem;
  width: 50%;
  display: inline-block;
  text-align: right;
}
.widget-badge .btn-group-vertical .btn, .widget-badge .btn-group-vertical .mv-icon-btn {
  padding: 0.375rem 0.5rem;
}
.widget-badge .color-green {
  color: #2ca02c;
}
.widget-badge .card-text:last-child {
  margin-bottom: 0;
}
.widget-badge .svg-inline--fa.fa-leaf {
  width: 80%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  max-height: 30px;
  margin-top: 0 !important;
}
.widget-badge .text-wrap {
  white-space: normal;
}
.widget-badge .btn-font-small {
  font-size: 0.75rem;
}
.widget-badge .card-body .container {
  padding: 0;
}

.WidgetAvailability svg {
  height: 100%;
  width: 100%;
}
.WidgetAvailability .list-group {
  color: #fff;
  background-color: #768391;
}
.WidgetAvailability .list-group-item {
  padding: 4px 10px;
  font-size: 0.8rem;
}
.WidgetAvailability .axis {
  font: 10px sans-serif;
}
.WidgetAvailability .axis path,
.WidgetAvailability .axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}
.WidgetAvailability text {
  fill: var(--graph-text-color);
}
.WidgetAvailability .axis text {
  font-size: 0.5rem !important;
}
.WidgetAvailability .chart-container path {
  stroke: #d0743c !important;
  fill: none;
  stroke-width: 2;
}
.WidgetAvailability .category,
.WidgetAvailability .legend {
  transition: opacity 0.3s;
}
.WidgetAvailability .cat-disabled,
.WidgetAvailability .cat-disabled-hover {
  opacity: 0.2;
}
.WidgetAvailability .cat-enabled-hover {
  opacity: 1;
}
.WidgetAvailability .filter-trigger {
  cursor: pointer;
}
.WidgetAvailability .float-left .legend-color {
  margin-right: 5px;
}
.WidgetAvailability .tooltip {
  position: absolute;
  background-color: #fafafa;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  text-align: left;
  transition: opacity 0.3s;
}
.WidgetAvailability .tooltip ul {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #000;
  line-height: 1.5;
  font-size: 0.75rem;
}
.WidgetAvailability .tooltip ul span {
  padding-left: 20px;
}
.WidgetAvailability .tooltip .title {
  font-size: 0.85rem;
  border-bottom: 1px solid #333;
  margin-bottom: 5px;
}

.WidgetAvailabilityR .recharts-cartesian-axis-tick {
  fill: var(--graph-text-color);
}
.WidgetAvailabilityR .recharts-cartesian-axis-tick text,
.WidgetAvailabilityR .axis text {
  font-size: 0.7rem !important;
}
.WidgetAvailabilityR .recharts-label {
  font-size: 0.7rem;
}
.WidgetAvailabilityR .recharts-default-tooltip {
  background-color: #fafafa;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  text-align: left;
  transition: opacity 0.3s;
}
.WidgetAvailabilityR .recharts-default-tooltip ul {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #000;
  line-height: 1.5;
  font-size: 0.75rem;
}
.WidgetAvailabilityR .recharts-default-tooltip ul span {
  padding-left: 20px;
}
.WidgetAvailabilityR .recharts-default-tooltip .title {
  font-size: 0.85rem;
  border-bottom: 1px solid #333;
  margin-bottom: 5px;
}
.WidgetAvailabilityR .recharts-bar-rectangle path,
.WidgetAvailabilityR .recharts-line path {
  transition: opacity 0.3s;
}
.WidgetAvailabilityR .legend-plannedLoss-color {
  background-color: #66c2a5;
}
.WidgetAvailabilityR .legend-unplannedLoss-color {
  background-color: #fc8d62;
}
.WidgetAvailabilityR .legend {
  transition: opacity 0.3s;
}
.WidgetAvailabilityR .float-left .legend-color {
  margin-right: 5px;
}
.WidgetAvailabilityR .filter-trigger {
  cursor: pointer;
}

/*.WidgetAvailabilityR {
  .recharts-cartesian-axis-tick {
    fill: $graph-text-color;
  }

  .recharts-cartesian-axis-tick,
  .axis {
    text {
      font-size: 0.7rem !important;
    }
  }

  .recharts-label {
    font-size: 0.7rem;
  }

  .recharts-default-tooltip {
    background-color: #fafafa;
    padding: 5px;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    text-align: left;
    transition: opacity 0.3s;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      color: #000;
      line-height: 1.5;
      font-size: 0.75rem;

      span {
        padding-left: 20px;
      }
    }

    .title {
      font-size: 0.85rem;
      border-bottom: 1px solid #333;
      margin-bottom: 5px;
    }
  }

  .recharts-bar-rectangle path,
  .recharts-line path {
    transition: opacity 0.3s;
  }

  .legend-plannedLoss-color {
    background-color: #66c2a5;
  }

  .legend-unplannedLoss-color {
    background-color: #fc8d62;
  }

  .legend {
    transition: opacity 0.3s;
  }

  .float-left .legend-color {
    margin-right: 5px;
  }

  .filter-trigger {
    cursor: pointer;
  }
}*/
svg:not(:root).svg-inline--fa {
  overflow: visible;
}

.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.svg-inline--fa .fa-lg {
  vertical-align: -0.225em;
}
.svg-inline--fa .fa-w-1 {
  width: 0.0625em;
}
.svg-inline--fa .fa-w-2 {
  width: 0.125em;
}
.svg-inline--fa .fa-w-3 {
  width: 0.1875em;
}
.svg-inline--fa .fa-w-4 {
  width: 0.25em;
}
.svg-inline--fa .fa-w-5 {
  width: 0.3125em;
}
.svg-inline--fa .fa-w-6 {
  width: 0.375em;
}
.svg-inline--fa .fa-w-7 {
  width: 0.4375em;
}
.svg-inline--fa .fa-w-8 {
  width: 0.5em;
}
.svg-inline--fa .fa-w-9 {
  width: 0.5625em;
}
.svg-inline--fa .fa-w-10 {
  width: 0.625em;
}
.svg-inline--fa .fa-w-11 {
  width: 0.6875em;
}
.svg-inline--fa .fa-w-12 {
  width: 0.75em;
}
.svg-inline--fa .fa-w-13 {
  width: 0.8125em;
}
.svg-inline--fa .fa-w-14 {
  width: 0.875em;
}
.svg-inline--fa .fa-w-15 {
  width: 0.9375em;
}
.svg-inline--fa .fa-w-16 {
  width: 1em;
}
.svg-inline--fa .fa-w-17 {
  width: 1.0625em;
}
.svg-inline--fa .fa-w-18 {
  width: 1.125em;
}
.svg-inline--fa .fa-w-19 {
  width: 1.1875em;
}
.svg-inline--fa .fa-w-20 {
  width: 1.25em;
}
.svg-inline--fa .fa-pull-left {
  margin-right: 0.3em;
  width: auto;
}
.svg-inline--fa .fa-pull-right {
  margin-left: 0.3em;
  width: auto;
}
.svg-inline--fa .fa-border {
  height: 1.5em;
}
.svg-inline--fa .fa-li {
  width: 2em;
}
.svg-inline--fa .fa-fw {
  width: 1.25em;
}

.fa-layers svg.svg-inline--fa {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

.fa-layers {
  display: inline-block;
  height: 1em;
  position: relative;
  text-align: center;
  vertical-align: -0.125em;
  width: 1em;
}

.fa-layers-text,
.fa-layers-counter {
  display: inline-block;
  position: absolute;
  text-align: center;
}

.fa-layers-text {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

.fa-layers-counter {
  background-color: #ff253a;
  border-radius: 1em;
  color: #fff;
  height: 1.5em;
  line-height: 1;
  max-width: 5em;
  min-width: 1.5em;
  overflow: hidden;
  padding: 0.25em;
  right: 0;
  text-overflow: ellipsis;
  top: 0;
  -webkit-transform: scale(0.25);
  transform: scale(0.25);
  -webkit-transform-origin: top right;
  transform-origin: top right;
}

.fa-layers-bottom-right {
  bottom: 0;
  right: 0;
  top: auto;
  -webkit-transform: scale(0.25);
  transform: scale(0.25);
  -webkit-transform-origin: bottom right;
  transform-origin: bottom right;
}

.fa-layers-bottom-left {
  bottom: 0;
  left: 0;
  right: auto;
  top: auto;
  -webkit-transform: scale(0.25);
  transform: scale(0.25);
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
}

.fa-layers-top-right {
  right: 0;
  top: 0;
  -webkit-transform: scale(0.25);
  transform: scale(0.25);
  -webkit-transform-origin: top right;
  transform-origin: top right;
}

.fa-layers-top-left {
  left: 0;
  right: auto;
  top: 0;
  -webkit-transform: scale(0.25);
  transform: scale(0.25);
  -webkit-transform-origin: top left;
  transform-origin: top left;
}

.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}

.fa-xs {
  font-size: 0.75em;
}

.fa-sm {
  font-size: 0.875em;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}

.fa-border {
  border: solid 0.08em #eee;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: 0.3em;
}

.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: 0.3em;
}

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1);
}

.fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
}

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  -webkit-filter: none;
  filter: none;
}

.fa-stack {
  display: inline-block;
  height: 2em;
  position: relative;
  width: 2em;
}

.fa-stack-1x,
.fa-stack-2x {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
}

.svg-inline--fa.fa-stack-1x {
  height: 1em;
  width: 1em;
}

.svg-inline--fa.fa-stack-2x {
  height: 2em;
  width: 2em;
}

.fa-inverse {
  color: #fff;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.chart-container {
  margin-left: auto;
  margin-right: auto;
}

.axis--x text,
.axis--y text {
  fill: #464646;
  font-size: 3em;
}

#main {
  width: 960px;
}

.axis .domain {
  display: none;
}

.bar {
  fill: steelblue;
}

.bar:hover {
  fill: brown;
}

.axis--x path {
  display: none;
}

.axis-consumption-x text,
.axis-consumption-y text {
  fill: white;
  font-size: 1rem;
}

.legend text {
  fill: white;
  font-size: 1rem;
}

.card-header {
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
}

.WidgetRound svg {
  max-width: 100%;
}
.WidgetRound .power-gauge-label {
  margin-top: -2.4rem;
  margin-bottom: 0;
}
.WidgetRound h3 {
  font-size: 1rem;
}
.WidgetRound h4.power-gauge-label {
  margin-top: -0.5rem;
  margin-bottom: 0;
  font-size: 0.75rem;
  line-height: 0.8;
}
.WidgetRound .single-gauge:not(.single-gauge-big) {
  margin-top: 15px;
}
.WidgetRound .single-gauge-big .gauge-value {
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 1;
}
.WidgetRound .single-gauge .gauge-value {
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 1;
}
.WidgetRound .power-gauge g.arc {
  fill: steelblue;
}
.WidgetRound .power-gauge g.pointer {
  fill: #e85116;
  stroke: #b64011;
}
.WidgetRound .power-gauge g.label text {
  text-anchor: middle;
  font-size: 14px;
  font-weight: bold;
  fill: #666;
}

.WidgetGauges .shadow {
  -webkit-filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.5));
          filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.5));
  box-shadow: none !important;
}

.WidgetRFRVRQ .card-footer .legend {
  list-style: none;
  padding: 0;
  margin: 0;
}
.WidgetRFRVRQ .card-footer .legend li {
  display: inline-block;
}
.WidgetRFRVRQ .card-footer .legend li + li {
  margin-left: 25px;
}
.WidgetRFRVRQ .card-footer .legend-color {
  display: inline-block;
  width: 14px;
  height: 10px;
}
.WidgetRFRVRQ text {
  fill: #464646;
  font-size: 0.8rem;
}
.WidgetRFRVRQ .line {
  fill: transparent;
  opacity: 0.7;
}
.WidgetRFRVRQ .area {
  fill: #1f77b4 !important;
  stroke-width: 0 !important;
}
.WidgetRFRVRQ .chart-container {
  min-height: 200px;
}
.WidgetRFRVRQ .legend-oee-color {
  background-color: #1f77b4;
}
.WidgetRFRVRQ .legend-availability-color {
  background-color: #ff7f0e;
}
.WidgetRFRVRQ .legend-efficency-color {
  background-color: #2ca02c;
}
.WidgetRFRVRQ .legend-quality-color {
  background-color: #d62728;
}

.WidgetPerformance .recharts-cartesian-axis-tick {
  fill: #464646;
}
.WidgetPerformance .recharts-cartesian-axis-tick text,
.WidgetPerformance .axis text {
  font-size: 0.7rem !important;
}
.WidgetPerformance .recharts-label {
  font-size: 0.7rem;
}
.WidgetPerformance .recharts-default-tooltip {
  background-color: #fafafa;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  text-align: left;
  transition: opacity 0.3s;
}
.WidgetPerformance .recharts-default-tooltip ul {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #000;
  line-height: 1.5;
  font-size: 0.75rem;
}
.WidgetPerformance .recharts-default-tooltip ul span {
  padding-left: 20px;
}
.WidgetPerformance .recharts-default-tooltip .title {
  font-size: 0.85rem;
  border-bottom: 1px solid #333;
  margin-bottom: 5px;
}
.WidgetPerformance .recharts-bar-rectangle path {
  transition: opacity 0.3s;
}
.WidgetPerformance .legend-operating-color {
  background-color: #7b6888;
}
.WidgetPerformance .legend-performance-color {
  background-color: #d0743c;
}
.WidgetPerformance .legend {
  transition: opacity 0.3s;
}
.WidgetPerformance .float-left .legend-color {
  margin-right: 5px;
}
.WidgetPerformance .filter-trigger {
  cursor: pointer;
}

.WidgetPlanRun svg {
  height: 100%;
  width: 100%;
}
.WidgetPlanRun .red {
  background-color: #e9967a;
  color: #f0f8ff;
}
.WidgetPlanRun .blue {
  background-color: #add8e6;
  color: #f0f8ff;
}
.WidgetPlanRun text {
  fill: #464646;
}
.WidgetPlanRun .axis text {
  font-size: 0.5rem !important;
}
.WidgetPlanRun .axis--x .tick {
  cursor: pointer;
}
.WidgetPlanRun .axis--x .tick:hover text {
  opacity: 0.5;
}
.WidgetPlanRun .cell {
  min-width: 40px;
  min-height: 20px;
  margin: 5px;
  float: left;
  text-align: center;
  border: #969696 solid thin;
  padding: 5px;
}
.WidgetPlanRun .fixed-cell {
  min-width: 40px;
  min-height: 20px;
  margin: 5px;
  position: fixed;
  text-align: center;
  border: #969696 solid thin;
  padding: 5px;
}
.WidgetPlanRun .h-bar {
  min-height: 15px;
  min-width: 10px;
  background-color: steelblue;
  margin-bottom: 2px;
  font-size: 11px;
  color: #f0f8ff;
  text-align: right;
  padding-right: 2px;
}
.WidgetPlanRun .v-bar {
  min-height: 1px;
  min-width: 30px;
  background-color: #4682b4;
  margin-right: 2px;
  font-size: 10px;
  color: #f0f8ff;
  text-align: center;
  width: 10px;
  display: inline-block;
}
.WidgetPlanRun .baseline {
  height: 1px;
  background-color: black;
}
.WidgetPlanRun .clear {
  clear: both;
}
.WidgetPlanRun .selected {
  background-color: #f08080;
}
.WidgetPlanRun .control-group {
  padding-top: 10px;
  margin: 10px;
}
.WidgetPlanRun .table {
  width: 70%;
}
.WidgetPlanRun .table td,
.WidgetPlanRun th {
  padding: 5px;
}
.WidgetPlanRun .table-header {
  background-color: #00afef;
  font-weight: bold;
}
.WidgetPlanRun .table-row-odd {
  background-color: #f0f8ff;
}
.WidgetPlanRun .table-row-odd {
  background-color: #d3d3d3;
}
.WidgetPlanRun .code {
  display: inline-block;
  font-style: italic;
  background-color: #d3d3d3;
  border: #969696 solid thin;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.WidgetPlanRun .countdown {
  width: 150px;
  height: 150px;
  font-size: 5em;
  font-weight: bold;
}
.WidgetPlanRun .axis path,
.WidgetPlanRun .axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}
.WidgetPlanRun .axis text {
  font: 10px sans-serif;
}
.WidgetPlanRun .axis .grid-line {
  stroke: black;
  shape-rendering: crispEdges;
  stroke-opacity: 0.2;
}
.WidgetPlanRun .line {
  fill: none;
  stroke: steelblue;
  stroke-width: 2;
}
.WidgetPlanRun .dot {
  fill: #464646;
  stroke: steelblue;
}
.WidgetPlanRun .area {
  stroke: none;
  fill: steelblue;
  fill-opacity: 0.2;
}
.WidgetPlanRun .area.area-oee {
  fill-opacity: 0.5;
}
.WidgetPlanRun .pie text {
  fill: #464646;
  font-weight: bold;
}
.WidgetPlanRun .circle {
  stroke: none;
  fill: red;
  fill-opacity: 0.7;
}
.WidgetPlanRun .cross {
  stroke: none;
  fill: blue;
  fill-opacity: 0.7;
}
.WidgetPlanRun .diamond {
  stroke: none;
  fill: green;
  fill-opacity: 0.7;
}
.WidgetPlanRun .square {
  stroke: none;
  fill: yellow;
  fill-opacity: 0.7;
}
.WidgetPlanRun .triangle-down {
  stroke: none;
  fill: blueviolet;
  fill-opacity: 0.7;
}
.WidgetPlanRun .triangle-up {
  stroke: none;
  fill: darkred;
  fill-opacity: 0.7;
}
.WidgetPlanRun .bubble {
  fill-opacity: 0.3;
}
.WidgetPlanRun .bar {
  stroke: none;
  fill: steelblue;
}
.WidgetPlanRun .tooltip {
  position: absolute;
  background-color: #fafafa;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  text-align: left;
  transition: opacity 0.3s, left 0.1s, top 0.1s;
  opacity: 0;
}
.WidgetPlanRun .tooltip ul {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #000;
  line-height: 1.5;
  font-size: 0.75rem;
}
.WidgetPlanRun .tooltip span {
  padding-left: 20px;
}
.WidgetPlanRun .recharts-cartesian-axis-tick {
  fill: #464646;
}
.WidgetPlanRun .recharts-cartesian-axis-tick text,
.WidgetPlanRun .axis text {
  font-size: 0.7rem !important;
}
.WidgetPlanRun .recharts-label {
  font-size: 0.7rem;
}
.WidgetPlanRun .recharts-default-tooltip {
  background-color: #fafafa;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  text-align: left;
  transition: opacity 0.3s;
}
.WidgetPlanRun .recharts-default-tooltip ul {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #000;
  line-height: 1.5;
  font-size: 0.75rem;
}
.WidgetPlanRun .recharts-default-tooltip ul span {
  padding-left: 20px;
}
.WidgetPlanRun .recharts-default-tooltip .title {
  font-size: 0.85rem;
  border-bottom: 1px solid #333;
  margin-bottom: 5px;
}
.WidgetPlanRun .legend-quality-color {
  background-color: #d0743c;
}
.WidgetPlanRun .legend-scrap-color {
  background-color: steelblue;
}

.cliccable {
  cursor: pointer !important;
}

.legend-planned-color {
  background-color: #98abc5;
}

.legend-run-color {
  background-color: #8a89a6;
}

.legend-operating-color {
  background-color: #7b6888;
}

.legend-performance-color {
  background-color: #d0743c;
}

.line-performance {
  stroke: #d0743c !important;
}

.WidgetPlanRunOee .recharts-cartesian-axis-tick {
  fill: #464646;
}
.WidgetPlanRunOee .recharts-cartesian-axis-tick text,
.WidgetPlanRunOee .axis text {
  font-size: 0.7rem !important;
}
.WidgetPlanRunOee .recharts-label {
  font-size: 0.7rem;
}
.WidgetPlanRunOee .recharts-default-tooltip {
  background-color: #fafafa;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  text-align: left;
  transition: opacity 0.3s;
}
.WidgetPlanRunOee .recharts-default-tooltip ul {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #000;
  line-height: 1.5;
  font-size: 0.75rem;
}
.WidgetPlanRunOee .recharts-default-tooltip ul span {
  padding-left: 20px;
}
.WidgetPlanRunOee .recharts-default-tooltip .title {
  font-size: 0.85rem;
  border-bottom: 1px solid #333;
  margin-bottom: 5px;
}
.WidgetPlanRunOee .legend-quality-color {
  background-color: #d0743c;
}
.WidgetPlanRunOee .legend-scrap-color {
  background-color: steelblue;
}
.WidgetPlanRunOee .float-left .legend-color {
  margin-right: 5px;
}

.legend-planned-color {
  background-color: #98abc5;
}

.legend-run-color {
  background-color: #8a89a6;
}

.legend-operating-color {
  background-color: #7b6888;
}

.legend-performance-color {
  background-color: steelblue;
}

.legend-scrap-color {
  background-color: #d0743c;
}

.legend-oee-color {
  background-color: #d0743c;
}

.line-performance {
  stroke: #d0743c !important;
}

.legend-availability-color {
  background-color: #d0743c;
}

.WidgetQuality .recharts-cartesian-axis-tick {
  fill: #464646;
}
.WidgetQuality .recharts-cartesian-axis-tick text,
.WidgetQuality .axis text {
  font-size: 0.7rem !important;
}
.WidgetQuality .recharts-label {
  font-size: 0.7rem;
}
.WidgetQuality .recharts-default-tooltip {
  background-color: #fafafa;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  text-align: left;
  transition: opacity 0.3s;
}
.WidgetQuality .recharts-default-tooltip ul {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #000;
  line-height: 1.5;
  font-size: 0.75rem;
}
.WidgetQuality .recharts-default-tooltip ul span {
  padding-left: 20px;
}
.WidgetQuality .recharts-default-tooltip .title {
  font-size: 0.85rem;
  border-bottom: 1px solid #333;
  margin-bottom: 5px;
}
.WidgetQuality .legend-quality-color {
  background-color: #d0743c;
}
.WidgetQuality .legend-scrap-color {
  background-color: steelblue;
}

.WidgetQualitySingle svg {
  height: 100%;
  width: 100%;
}
.WidgetQualitySingle .list-group {
  color: #fff;
  background-color: #768391;
}
.WidgetQualitySingle .list-group-item {
  padding: 4px 10px;
  font-size: 0.8rem;
}
.WidgetQualitySingle .axis {
  font: 10px sans-serif;
}
.WidgetQualitySingle .axis path,
.WidgetQualitySingle .axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}
.WidgetQualitySingle text {
  fill: #464646;
}
.WidgetQualitySingle .axis text {
  font-size: 0.5rem !important;
}
.WidgetQualitySingle .category,
.WidgetQualitySingle .legend {
  transition: opacity 0.3s;
}
.WidgetQualitySingle .cat-disabled,
.WidgetQualitySingle .cat-disabled-hover {
  opacity: 0.2;
}
.WidgetQualitySingle .cat-enabled-hover {
  opacity: 1;
}
.WidgetQualitySingle .filter-trigger {
  cursor: pointer;
}
.WidgetQualitySingle .float-left .legend-color {
  margin-right: 5px;
}
.WidgetQualitySingle .legend-single-color {
  background-color: steelblue;
  margin-right: 10px;
}
.WidgetQualitySingle .tooltip {
  position: absolute;
  background-color: #fafafa;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  text-align: left;
  transition: opacity 0.3s;
}
.WidgetQualitySingle .tooltip ul {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #000;
  line-height: 1.5;
  font-size: 0.75rem;
}
.WidgetQualitySingle .tooltip ul span {
  padding-left: 20px;
}
.WidgetQualitySingle .tooltip .title {
  font-size: 0.85rem;
  border-bottom: 1px solid #333;
  margin-bottom: 5px;
}

.WidgetQualityDetails svg {
  height: 100%;
  width: 100%;
}
.WidgetQualityDetails .list-group {
  color: #fff;
  background-color: #768391;
}
.WidgetQualityDetails .list-group-item {
  padding: 4px 10px;
  font-size: 0.8rem;
}
.WidgetQualityDetails .axis {
  font: 10px sans-serif;
}
.WidgetQualityDetails .axis path,
.WidgetQualityDetails .axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}
.WidgetQualityDetails text {
  fill: #464646;
}
.WidgetQualityDetails .axis text {
  font-size: 0.5rem !important;
}
.WidgetQualityDetails .chart-container path {
  stroke: #d0743c !important;
  fill: none;
  stroke-width: 2;
}
.WidgetQualityDetails .category,
.WidgetQualityDetails .legend {
  transition: opacity 0.3s;
}
.WidgetQualityDetails .cat-disabled,
.WidgetQualityDetails .cat-disabled-hover {
  opacity: 0.2;
}
.WidgetQualityDetails .cat-enabled-hover {
  opacity: 1;
}
.WidgetQualityDetails .filter-trigger {
  cursor: pointer;
}
.WidgetQualityDetails .float-left .legend-color {
  margin-right: 5px;
}

.plant-over-time .custom-y-tick {
  font-size: 0.75rem;
}
.plant-over-time .recharts-cartesian-axis-tick-value {
  font-size: 0.7rem;
}
.plant-over-time .recharts-brush-texts {
  font-size: 0.7rem;
}
.plant-over-time .recharts-tooltip-wrapper {
  font-size: 0.8rem;
}
.plant-over-time .recharts-tooltip-wrapper .time-label {
  border-bottom: 1px solid #333;
  margin-bottom: 15px !important;
  display: inline-block;
}

.plant-over-time__legend .legend-green-color {
  background-color: green;
}
.plant-over-time__legend .legend-yellow-color {
  background-color: yellow;
}
.plant-over-time__legend .legend-blue-color {
  background-color: blue;
}
.plant-over-time__legend .legend-orange-color {
  background-color: orange;
}
.plant-over-time__legend .legend-red-color {
  background-color: red;
}
.plant-over-time__legend .legend-color-block {
  margin-right: 8px;
  border: 1px solid #ddd;
}
.plant-over-time__legend .legend > div {
  cursor: pointer;
  transition: opacity 0.3s;
}
.plant-over-time__legend .status-off {
  opacity: 0.5;
}
.plant-over-time__legend .status-on {
  opacity: 1;
}

.react-datepicker__navigation-icon::before, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-color: #ccc;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  margin-left: -4px;
  position: absolute;
  width: 0;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-top: none;
  border-bottom-color: #f0f0f0;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  top: 0;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  bottom: 0;
  margin-bottom: -8px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
  border-bottom: none;
  border-top-color: #fff;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
  bottom: 0;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
  bottom: -1px;
  border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}

.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}
.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}
.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}
.react-datepicker-popper[data-placement^=bottom] {
  padding-top: 10px;
}
.react-datepicker-popper[data-placement=bottom-end] .react-datepicker__triangle, .react-datepicker-popper[data-placement=top-end] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}
.react-datepicker-popper[data-placement^=top] {
  padding-bottom: 10px;
}
.react-datepicker-popper[data-placement^=right] {
  padding-left: 8px;
}
.react-datepicker-popper[data-placement^=right] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}
.react-datepicker-popper[data-placement^=left] {
  padding-right: 8px;
}
.react-datepicker-popper[data-placement^=left] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}
.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}
.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 2px;
  padding: 0;
  border: none;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  overflow: hidden;
}
.react-datepicker__navigation--previous {
  left: 2px;
}
.react-datepicker__navigation--next {
  right: 2px;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 85px;
}
.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__navigation--years-previous {
  top: 4px;
}
.react-datepicker__navigation--years-upcoming {
  top: -4px;
}
.react-datepicker__navigation:hover *::before {
  border-color: #a6a6a6;
}

.react-datepicker__navigation-icon {
  position: relative;
  top: -1px;
  font-size: 20px;
  width: 0;
}
.react-datepicker__navigation-icon--next {
  left: -2px;
}
.react-datepicker__navigation-icon--next::before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  left: -7px;
}
.react-datepicker__navigation-icon--previous {
  right: -2px;
}
.react-datepicker__navigation-icon--previous::before {
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
  right: -7px;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  margin: 0.4rem;
  text-align: center;
}
.react-datepicker__year-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 180px;
}
.react-datepicker__year .react-datepicker__year-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}
.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time] {
  -moz-appearance: textfield;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 85px;
}
.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -72px;
  top: 0;
}
.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  box-sizing: content-box;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-names {
  margin-bottom: -8px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}
.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
  background-color: #1d5d90;
}
.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none;
}
.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  font-weight: bold;
}
.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}
.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
  background-color: #32be3f;
}
.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1,
.react-datepicker__year-text--highlighted-custom-1 {
  color: magenta;
}
.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2,
.react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #1d5d90;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #2a87d0;
  color: #fff;
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #1d5d90;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range) {
  background-color: rgba(33, 107, 165, 0.5);
}
.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range) {
  background-color: #f0f0f0;
  color: #000;
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
}
.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background-color: transparent;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #216ba5;
}
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background-color: #f0f0f0;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
  position: relative;
}
.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}
.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
  right: -16px;
  top: 0;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
}
.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}
.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}
.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #216ba5;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "\D7";
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}
.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}
@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}
.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.noty_layout_mixin, #noty_layout__top, #noty_layout__topLeft, #noty_layout__topCenter, #noty_layout__topRight, #noty_layout__bottom, #noty_layout__bottomLeft, #noty_layout__bottomCenter, #noty_layout__bottomRight, #noty_layout__center, #noty_layout__centerLeft, #noty_layout__centerRight {
  position: fixed;
  margin: 0;
  padding: 0;
  z-index: 9999999;
  -webkit-transform: translateZ(0) scale(1, 1);
          transform: translateZ(0) scale(1, 1);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  filter: blur(0);
  -webkit-filter: blur(0);
  max-width: 90%;
}

#noty_layout__top {
  top: 0;
  left: 5%;
  width: 90%;
}

#noty_layout__topLeft {
  top: 20px;
  left: 20px;
  width: 325px;
}

#noty_layout__topCenter {
  top: 5%;
  left: 50%;
  width: 325px;
  -webkit-transform: translate(calc(-50% - .5px)) translateZ(0) scale(1, 1);
          transform: translate(calc(-50% - .5px)) translateZ(0) scale(1, 1);
}

#noty_layout__topRight {
  top: 20px;
  right: 20px;
  width: 325px;
}

#noty_layout__bottom {
  bottom: 0;
  left: 5%;
  width: 90%;
}

#noty_layout__bottomLeft {
  bottom: 20px;
  left: 20px;
  width: 325px;
}

#noty_layout__bottomCenter {
  bottom: 5%;
  left: 50%;
  width: 325px;
  -webkit-transform: translate(calc(-50% - .5px)) translateZ(0) scale(1, 1);
          transform: translate(calc(-50% - .5px)) translateZ(0) scale(1, 1);
}

#noty_layout__bottomRight {
  bottom: 20px;
  right: 20px;
  width: 325px;
}

#noty_layout__center {
  top: 50%;
  left: 50%;
  width: 325px;
  -webkit-transform: translate(calc(-50% - .5px), calc(-50% - .5px)) translateZ(0) scale(1, 1);
          transform: translate(calc(-50% - .5px), calc(-50% - .5px)) translateZ(0) scale(1, 1);
}

#noty_layout__centerLeft {
  top: 50%;
  left: 20px;
  width: 325px;
  -webkit-transform: translate(0, calc(-50% - .5px)) translateZ(0) scale(1, 1);
          transform: translate(0, calc(-50% - .5px)) translateZ(0) scale(1, 1);
}

#noty_layout__centerRight {
  top: 50%;
  right: 20px;
  width: 325px;
  -webkit-transform: translate(0, calc(-50% - .5px)) translateZ(0) scale(1, 1);
          transform: translate(0, calc(-50% - .5px)) translateZ(0) scale(1, 1);
}

.noty_progressbar {
  display: none;
}

.noty_has_timeout.noty_has_progressbar .noty_progressbar {
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 100%;
  background-color: #646464;
  opacity: 0.2;
  filter: alpha(opacity=10);
}

.noty_bar {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate(0, 0) translateZ(0) scale(1, 1);
  transform: translate(0, 0) scale(1, 1);
  -webkit-font-smoothing: subpixel-antialiased;
  overflow: hidden;
}

.noty_effects_open {
  opacity: 0;
  -webkit-transform: translate(50%);
          transform: translate(50%);
  -webkit-animation: noty_anim_in 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
          animation: noty_anim_in 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.noty_effects_close {
  -webkit-animation: noty_anim_out 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
          animation: noty_anim_out 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.noty_fix_effects_height {
  -webkit-animation: noty_anim_height 75ms ease-out;
          animation: noty_anim_height 75ms ease-out;
}

.noty_close_with_click {
  cursor: pointer;
}

.noty_close_button {
  position: absolute;
  top: 2px;
  right: 2px;
  font-weight: bold;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.noty_close_button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.noty_modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 10000;
  opacity: 0.3;
  left: 0;
  top: 0;
}

.noty_modal.noty_modal_open {
  opacity: 0;
  -webkit-animation: noty_modal_in 0.3s ease-out;
          animation: noty_modal_in 0.3s ease-out;
}

.noty_modal.noty_modal_close {
  -webkit-animation: noty_modal_out 0.3s ease-out;
          animation: noty_modal_out 0.3s ease-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@keyframes noty_modal_in {
  100% {
    opacity: 0.3;
  }
}
@keyframes noty_modal_out {
  100% {
    opacity: 0;
  }
}
@keyframes noty_modal_out {
  100% {
    opacity: 0;
  }
}
@keyframes noty_anim_in {
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
    opacity: 1;
  }
}
@keyframes noty_anim_out {
  100% {
    -webkit-transform: translate(50%);
            transform: translate(50%);
    opacity: 0;
  }
}
@keyframes noty_anim_height {
  100% {
    height: 0;
  }
}
.noty_theme__bootstrap-v4.noty_bar {
  margin: 4px 0;
  overflow: hidden;
  position: relative;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.noty_theme__bootstrap-v4.noty_bar .noty_body {
  padding: 0.75rem 1.25rem;
}
.noty_theme__bootstrap-v4.noty_bar .noty_buttons {
  padding: 10px;
}
.noty_theme__bootstrap-v4.noty_bar .noty_close_button {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 0.5;
  background: transparent;
}
.noty_theme__bootstrap-v4.noty_bar .noty_close_button:hover {
  background: transparent;
  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=50);
  opacity: 0.75;
}

.noty_theme__bootstrap-v4.noty_type__alert,
.noty_theme__bootstrap-v4.noty_type__notification {
  background-color: #fff;
  color: inherit;
}

.noty_theme__bootstrap-v4.noty_type__warning {
  background-color: #fcf8e3;
  color: #8a6d3b;
  border-color: #faebcc;
}

.noty_theme__bootstrap-v4.noty_type__error {
  background-color: #f2dede;
  color: #a94442;
  border-color: #ebccd1;
}

.noty_theme__bootstrap-v4.noty_type__info,
.noty_theme__bootstrap-v4.noty_type__information {
  background-color: #d9edf7;
  color: #31708f;
  border-color: #bce8f1;
}

.noty_theme__bootstrap-v4.noty_type__success {
  background-color: #dff0d8;
  color: #3c763d;
  border-color: #d6e9c6;
}

.daterangepicker {
  position: absolute;
  color: inherit;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 278px;
  max-width: none;
  padding: 0;
  margin-top: 7px;
  top: 100px;
  left: 20px;
  z-index: 3001;
  display: none;
  font-family: arial;
  font-size: 15px;
  line-height: 1em;
}

.daterangepicker:before, .daterangepicker:after {
  position: absolute;
  display: inline-block;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: "";
}

.daterangepicker:before {
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc;
}

.daterangepicker:after {
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
}

.daterangepicker.opensleft:before {
  right: 9px;
}

.daterangepicker.opensleft:after {
  right: 10px;
}

.daterangepicker.openscenter:before {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.openscenter:after {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.opensright:before {
  left: 9px;
}

.daterangepicker.opensright:after {
  left: 10px;
}

.daterangepicker.drop-up {
  margin-top: -7px;
}

.daterangepicker.drop-up:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #ccc;
}

.daterangepicker.drop-up:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid #fff;
}

.daterangepicker.single .daterangepicker .ranges, .daterangepicker.single .drp-calendar {
  float: none;
}

.daterangepicker.single .drp-selected {
  display: none;
}

.daterangepicker.show-calendar .drp-calendar {
  display: block;
}

.daterangepicker.show-calendar .drp-buttons {
  display: block;
}

.daterangepicker.auto-apply .drp-buttons {
  display: none;
}

.daterangepicker .drp-calendar {
  display: none;
  max-width: 270px;
}

.daterangepicker .drp-calendar.left {
  padding: 8px 0 8px 8px;
}

.daterangepicker .drp-calendar.right {
  padding: 8px;
}

.daterangepicker .drp-calendar.single .calendar-table {
  border: none;
}

.daterangepicker .calendar-table .next span, .daterangepicker .calendar-table .prev span {
  color: #fff;
  border: solid black;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px;
}

.daterangepicker .calendar-table .next span {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.daterangepicker .calendar-table .prev span {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  min-width: 32px;
  width: 32px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;
}

.daterangepicker .calendar-table {
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #fff;
}

.daterangepicker .calendar-table table {
  width: 100%;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
}

.daterangepicker td.available:hover, .daterangepicker th.available:hover {
  background-color: #eee;
  border-color: transparent;
  color: inherit;
}

.daterangepicker td.week, .daterangepicker th.week {
  font-size: 80%;
  color: #ccc;
}

.daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
  background-color: #fff;
  border-color: transparent;
  color: #999;
}

.daterangepicker td.in-range {
  background-color: #ebf4f8;
  border-color: transparent;
  color: #000;
  border-radius: 0;
}

.daterangepicker td.start-date {
  border-radius: 4px 0 0 4px;
}

.daterangepicker td.end-date {
  border-radius: 0 4px 4px 0;
}

.daterangepicker td.start-date.end-date {
  border-radius: 4px;
}

.daterangepicker td.active, .daterangepicker td.mv-breadcrumb__item--active, .daterangepicker td.active:hover {
  background-color: #357ebd;
  border-color: transparent;
  color: #fff;
}

.daterangepicker th.month {
  width: auto;
}

.daterangepicker td.disabled, .daterangepicker option.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through;
}

.daterangepicker select.monthselect, .daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}

.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}

.daterangepicker select.yearselect {
  width: 40%;
}

.daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect, .daterangepicker select.ampmselect {
  width: 50px;
  margin: 0 auto;
  background: #eee;
  border: 1px solid #eee;
  padding: 2px;
  outline: 0;
  font-size: 12px;
}

.daterangepicker .calendar-time {
  text-align: center;
  margin: 4px auto 0 auto;
  line-height: 30px;
  position: relative;
}

.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed;
}

.daterangepicker .drp-buttons {
  clear: both;
  text-align: right;
  padding: 8px;
  border-top: 1px solid #ddd;
  display: none;
  line-height: 12px;
  vertical-align: middle;
}

.daterangepicker .drp-selected {
  display: inline-block;
  font-size: 12px;
  padding-right: 8px;
}

.daterangepicker .drp-buttons .btn, .daterangepicker .drp-buttons .mv-icon-btn {
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
}

.daterangepicker.show-ranges.single.rtl .drp-calendar.left {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.single.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker.show-ranges.rtl .drp-calendar.right {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker .ranges {
  float: none;
  text-align: left;
  margin: 0;
}

.daterangepicker.show-calendar .ranges {
  margin-top: 8px;
}

.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

.daterangepicker .ranges li {
  font-size: 12px;
  padding: 8px 12px;
  cursor: pointer;
}

.daterangepicker .ranges li:hover {
  background-color: #eee;
}

.daterangepicker .ranges li.active, .daterangepicker .ranges li.mv-breadcrumb__item--active {
  background-color: #08c;
  color: #fff;
}

/*  Larger Screen Styling */
@media (min-width: 564px) {
  .daterangepicker {
    width: auto;
  }

  .daterangepicker .ranges ul {
    width: 140px;
  }

  .daterangepicker.single .ranges ul {
    width: 100%;
  }

  .daterangepicker.single .drp-calendar.left {
    clear: none;
  }

  .daterangepicker.single .ranges, .daterangepicker.single .drp-calendar {
    float: left;
  }

  .daterangepicker {
    direction: ltr;
    text-align: left;
  }

  .daterangepicker .drp-calendar.left {
    clear: left;
    margin-right: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .daterangepicker .drp-calendar.right {
    margin-left: 0;
  }

  .daterangepicker .drp-calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    padding-right: 8px;
  }

  .daterangepicker .ranges, .daterangepicker .drp-calendar {
    float: left;
  }
}
@media (min-width: 730px) {
  .daterangepicker .ranges {
    width: auto;
  }

  .daterangepicker .ranges {
    float: left;
  }

  .daterangepicker.rtl .ranges {
    float: right;
  }

  .daterangepicker .drp-calendar.left {
    clear: none !important;
  }
}
#fetch-wrapper-noty-container .noty_body {
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
}

body {
  overflow-x: hidden;
}

#widget-area-topbar-right {
  margin-left: 2rem;
}

.topbar,
.sidebar-topbar {
  height: 3.125rem;
  height: var(--topbar-height);
  background-color: #fff;
  background-color: var(--top-bar-bg);
  color: #f5f5f5;
  color: var(--gray-100);
}

.topbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 1rem;
  z-index: 30;
  border-bottom: 1px solid #e9ecef;
  border-bottom: 1px solid var(--top-bar-border-color);
}
@media (min-width: 1200px) {
  .topbar {
    padding-left: 15rem;
    padding-left: var(--sidebar-width);
  }
}
.topbar .profile-toggler:focus, .topbar .profile-toggler:hover {
  color: #cacaca;
  color: var(--gray-400);
  text-decoration: none;
}

.topbar-user-image {
  width: 2.25rem;
  margin-top: -0.125rem;
  border-radius: 50%;
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 31;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  transition: var(--sidebar-transition);
  opacity: 0;
}
.sidebar-overlay.is-open {
  opacity: 1;
}

.sidebar-panel {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 15rem;
  width: var(--sidebar-width);
  background-color: #f9f9f9;
  background-color: var(--sidebar-bg);
  transition: all 0.3s ease-in-out;
  transition: var(--sidebar-transition);
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  overflow: hidden;
  z-index: 32;
}
@media (min-width: 1200px) {
  .sidebar-panel {
    -webkit-backface-visibility: visible;
            backface-visibility: visible;
    -webkit-transform: translateX(0) translateZ(0);
            transform: translateX(0) translateZ(0);
  }
}
.sidebar-panel.is-open {
  -webkit-backface-visibility: visible;
          backface-visibility: visible;
  -webkit-transform: translateX(0) translateZ(0);
          transform: translateX(0) translateZ(0);
}

.sidebar-logo {
  display: block;
  width: 100%;
  height: 3.125rem;
  height: var(--topbar-height);
  line-height: 3.125rem;
  line-height: var(--topbar-height);
  padding: 0 1rem;
  background-color: #fff;
  background-color: var(--top-bar-bg);
  font-size: 1.25rem;
  color: #ffffff;
  color: var(--white);
  border-bottom: 1px solid #e9ecef;
  border-bottom: 1px solid var(--top-bar-border-color);
}
.sidebar-logo:hover {
  color: #ffffff;
  color: var(--white);
}

#sideBar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
#sideBar li {
  padding: 0;
}
#sideBar aside a {
  font-weight: 400;
  padding: 0.5rem 1rem;
  text-decoration: none;
}
#sideBar aside a .icon {
  width: 2rem;
}
#sideBar aside a .nav-item-image {
  height: 1.5rem;
}
#sideBar .sidebar-menu > li {
  width: 100%;
}
#sideBar .sidebar-menu > li:hover {
  background-color: #e9ecef;
  background-color: var(--sidebar-1st-level-hover-bg);
  color: #9b9b9b;
  color: var(--sidebar-1st-level-hover-color);
}
#sideBar .sidebar-menu > li.active, #sideBar .sidebar-menu > li.mv-breadcrumb__item--active {
  background-color: #ffffff;
  background-color: var(--sidebar-1st-level-active-bg);
}
#sideBar .sidebar-menu > li.active > ul, #sideBar .sidebar-menu > li.mv-breadcrumb__item--active > ul {
  background-color: #f9f9f9;
  background-color: var(--sidebar-bg);
}
#sideBar .sidebar-menu-li > a,
#sideBar .sidebar-menu > li > a {
  padding: 0.5rem 1rem 0.5rem 0.6rem;
  color: #9b9b9b;
  color: var(--sidebar-1st-level-color);
}
#sideBar .sidebar-menu-li > a:hover,
#sideBar .sidebar-menu > li > a:hover {
  color: #9b9b9b;
  color: var(--sidebar-1st-level-hover-color);
}
#sideBar .sidebar-menu-li > a.active, #sideBar .sidebar-menu-li > a.mv-breadcrumb__item--active,
#sideBar .sidebar-menu > li > a.active,
#sideBar .sidebar-menu > li > a.mv-breadcrumb__item--active {
  background-color: #ffffff;
  background-color: var(--sidebar-1st-level-active-bg);
}
#sideBar .sidebar-menu-submenu > li {
  padding-left: 2.15rem;
  font-size: 0.9rem;
  background-color: var(--sidebar-2nd-level-bg);
}
#sideBar .sidebar-menu-submenu > li.active, #sideBar .sidebar-menu-submenu > li.mv-breadcrumb__item--active {
  border-left: 4px solid #e94e1b;
  border-left: 4px solid var(--primary);
  background-color: #f9f9f9;
  background-color: var(--sidebar-2nd-level-active-bg);
}
#sideBar .sidebar-menu-submenu > li.active a, #sideBar .sidebar-menu-submenu > li.mv-breadcrumb__item--active a {
  color: #e94e1b;
  color: var(--sidebar-2nd-level-active-color);
}
#sideBar .sidebar-menu-submenu > li:hover {
  background-color: #f9f9f9;
  background-color: var(--sidebar-2nd-level-hover-bg);
}
#sideBar .sidebar-menu-submenu > li > a {
  color: #9b9b9b;
  color: var(--sidebar-2nd-level-color);
  padding: 0.3rem 1rem 0.3rem 0.5rem;
}
#sideBar .sidebar-menu-submenu > li > a:hover {
  color: #c6c6c6;
  color: var(--sidebar-2nd-level-hover-color);
}

.sidebar-toggler {
  position: fixed;
  background: transparent;
  color: #cacaca;
  color: var(--gray-400);
  border-radius: 0;
  z-index: 31;
}
.sidebar-toggler:hover {
  background: #f5f5f5;
  background: var(--gray-100);
}
@media (min-width: 1200px) {
  .sidebar-toggler {
    display: none;
  }
}

.main-container {
  min-height: 100vh;
  padding-top: 3.125rem;
  padding-top: var(--topbar-height);
  transition: all 0.3s ease-in-out;
  transition: var(--sidebar-transition);
  background-color: #ffffff;
  background-color: var(--white);
}
.main-container.is-mobile {
  padding-top: 1rem;
}
@media (min-width: 1200px) {
  .main-container {
    padding-left: 15rem;
    padding-left: var(--sidebar-width);
  }
  .main-container.is-fullwidth {
    padding-left: 0;
  }
}
.main-container header {
  padding: 1rem 2rem;
}
.main-container header h1 {
  font-size: 1.5rem;
  letter-spacing: -0.025em;
  margin: 0;
}
.main-container header h1 small {
  display: block;
  font-size: 60%;
  line-height: 1.25;
  margin-top: 0.25em;
}
.main-container .content {
  margin: 0 2rem;
  padding: 0 0 2rem 0;
}
@media screen and (max-width: 767px) {
  .main-container .content {
    margin: 1rem;
    padding: 1rem;
  }
}

.main-content {
  padding: 1rem 1rem 3rem;
}
@media (min-width: 1200px) {
  .main-content {
    padding-left: 2rem;
  }
}

@media print {
  #topBar,
.sidebar-toggler {
    display: none !important;
  }

  .col-print-1 {
    width: 8% !important;
    flex: 0 0 8% !important;
    max-width: 8% !important;
    float: left !important;
  }

  .col-print-2 {
    width: 16% !important;
    flex: 0 0 16% !important;
    max-width: 16% !important;
    float: left !important;
  }

  .col-print-3 {
    width: 25% !important;
    flex: 0 0 25% !important;
    max-width: 25% !important;
    float: left !important;
  }

  .col-print-4 {
    width: 33% !important;
    flex: 0 0 33% !important;
    max-width: 33% !important;
    float: left !important;
  }

  .col-print-5 {
    width: 42% !important;
    flex: 0 0 42% !important;
    max-width: 42% !important;
    float: left !important;
  }

  .col-print-6 {
    width: 50% !important;
    flex: 0 0 50% !important;
    max-width: 50% !important;
    float: left !important;
  }

  .col-print-7 {
    width: 58% !important;
    flex: 0 0 58% !important;
    max-width: 58% !important;
    float: left !important;
  }

  .col-print-8 {
    width: 66% !important;
    flex: 0 0 66% !important;
    max-width: 66% !important;
    float: left !important;
  }

  .col-print-9 {
    width: 75% !important;
    flex: 0 0 75% !important;
    max-width: 75% !important;
    float: left !important;
  }

  .col-print-10 {
    width: 83% !important;
    flex: 0 0 83% !important;
    max-width: 83% !important;
    float: left !important;
  }

  .col-print-11 {
    width: 92% !important;
    flex: 0 0 92% !important;
    max-width: 92% !important;
    float: left !important;
  }

  .col-print-12 {
    width: 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
    float: left !important;
  }
}
.data-tooltips {
  position: relative;
}
.data-tooltips::before {
  content: "";
  position: absolute;
  top: -6px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-width: 4px 6px 0 6px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
  z-index: 100;
  transition: opacity 0.3s;
  opacity: 0;
}
.data-tooltips::after {
  content: attr(data-title);
  position: absolute;
  left: 50%;
  top: -6px;
  -webkit-transform: translateX(-50%) translateY(-100%);
          transform: translateX(-50%) translateY(-100%);
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  color: #fff;
  padding: 4px 2px;
  font-size: 12px;
  min-width: 100px;
  border-radius: 5px;
  pointer-events: none;
  transition: opacity 0.3s;
  opacity: 0;
}
.data-tooltips:hover::before, .data-tooltips:hover::after {
  opacity: 1;
}

.btn-over-tabs {
  top: 8px;
  right: 10px;
}

.vcenter-50h {
  vertical-align: middle;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.error-box {
  font-size: 24px;
  border: 1px solid #eee;
  border-radius: 4px;
  box-shadow: 0 3px 10px rgba(127, 0, 0, 0.2);
  margin-top: 10vh;
  max-width: 90%;
}
.error-box .error-code {
  color: #e94e1b;
  font-size: 2em;
}
.error-box .error-title {
  font-size: 1em;
}
.error-box .error-message {
  font-size: 0.8em;
  color: #555;
}

.cliccable .card-body,
.cliccable .card-body svg.recharts-surface {
  cursor: pointer;
}

.widget-box-rt_measures {
  font-size: 0.8rem;
  position: relative;
  margin-bottom: 1rem;
}
.widget-box-rt_measures ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.widget-box-rt_measures .label {
  font-weight: 700;
  text-transform: uppercase;
}
.widget-box-rt_measures .is-reloading .value,
.widget-box-rt_measures .is-reloading .trend {
  opacity: 0.3;
}
.widget-box-rt_measures .spinner {
  color: #666;
  position: absolute;
  bottom: 5px;
  right: 5px;
}
.widget-box-rt_measures .value,
.widget-box-rt_measures .trend {
  float: right;
}
.widget-box-rt_measures .trend.trend-down {
  color: darkred;
}
.widget-box-rt_measures .trend.trend-up {
  color: darkgreen;
}
.widget-box-rt_measures .trend.trend-equal {
  color: gray;
}
.widget-box-rt_measures .select-interval {
  font-size: 0.8rem;
  line-height: 1;
  padding: 0.1rem 1.5rem 0.1rem 2px;
  height: auto;
  width: auto;
}

#widget-area-sidebar-bottom {
  margin-top: 200px;
  max-height: 20vh;
  margin-left: 15px;
  width: calc(100% - 30px);
}

.daterangepicker {
  box-shadow: rgba(0, 0, 0, 0.3) 0 5px 16px 0px;
}

.ranges li {
  text-transform: uppercase;
}
.ranges li:hover {
  color: #143c79;
}

.widget-box-rt_measures {
  font-size: 0.8rem;
  position: relative;
  margin-bottom: 1rem;
}
.widget-box-rt_measures ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.widget-box-rt_measures .label {
  font-weight: 700;
  text-transform: uppercase;
}
.widget-box-rt_measures .is-reloading .value,
.widget-box-rt_measures .is-reloading .trend {
  opacity: 0.3;
}
.widget-box-rt_measures .spinner {
  color: #666;
  position: absolute;
  bottom: 5px;
  right: 5px;
}
.widget-box-rt_measures .value,
.widget-box-rt_measures .trend {
  float: right;
}
.widget-box-rt_measures .trend.trend-down {
  color: darkred;
}
.widget-box-rt_measures .trend.trend-up {
  color: darkgreen;
}
.widget-box-rt_measures .trend.trend-equal {
  color: gray;
}
.widget-box-rt_measures .select-interval {
  font-size: 0.8rem;
  line-height: 1;
  padding: 0.1rem 1.5rem 0.1rem 2px;
  height: auto;
  width: auto;
}

.calendar .svg-inline--fa.fa-clock-o {
  display: none !important;
}

.version-checker {
  position: absolute;
  bottom: 0.5rem;
  left: 1rem;
  right: 1rem;
  font-size: small;
}
.version-checker h4 {
  font-size: 1rem;
}
.version-checker .mv-col {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}
.version-checker .current-version {
  text-align: center;
  font-style: italic;
  color: #757575;
  color: var(--secondary-desaturated-50);
  opacity: 0.6;
  font-size: 90%;
}

/*# sourceMappingURL=index.css.map */

