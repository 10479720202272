.inplant-user {
  /*
  &.inplant-user-list .user-table,
  &.inplant-user-form {
    padding: 1rem 2rem;
  }



  .rjsf {
    & > .form-group {
      & > label,
      & > .field-description {
        display: none;
      }
    }

    .header {
      margin: 0;
      height: 28px;
    }

    h1 {
      font-size: 1.5rem;
      text-transform: uppercase;
    }

    fieldset {
      .field {
        width: 48%;
        float: left;
        height: 95px;
      }
      .field:nth-child(2n) {
        margin-left: 4%;
      }
    }

    .form-actions {
      margin-top: 50px;
    }

    nav {
      margin-bottom: 3rem;
    }
  }

  .rt-tbody .rt-tr:not(.-padRow) {
    cursor: pointer;
  }

  .breadcrumb {
    padding-left: 0;
    padding-right: 0;
    background: transparent;
    font-size: 0.9em;
  }
*/

  &.inplant-user-form {
    .form-actions {
      padding: 0 2rem;
    }

    input[type="checkbox"] + span {
      margin-left: 8px;
    }
  }

  .rt-tr-group {
    cursor: pointer;
  }
}
