//AlarmModal (non usato)
@media (min-width: 768px) {
  .modal-xl {
    width: 80%;
    max-width: 1000px;
  }
}

.ReactTable .rt-tbody {
  will-change: transform;
}


//CostsModal (non usato)
@media (min-width: 768px) {
  .modal-xl {
    width: 80%;
    max-width: 1000px;
  }
}

.total-cost-for-lot {
  margin-bottom: 30px;
}


//List
.inplant-fe-production {
  .ReactTable {
    .rt-td {
       overflow: visible !important;
    }

  }

  .containerSumPiecesFromRange > dt, dd {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

}
