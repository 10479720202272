.inplant-designer-fe {
  .WidgetDesigner {
    display: flex;
    height: 100%;
    flex: 1;

    .breadcrumb {
      padding: 0.15rem 1rem;

      & > span + span::before {
        content: ' > ';
        display: inline-block;
        padding: 0 8px;
        color: #aaa;
      }

      span {
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .cards {
      min-height: 100px;
    }

    table a {
      cursor: pointer;
    }

    .column {
      float: left;
      overflow-x: hidden;
      flex-shrink: 0;
      flex-grow: 1;
      height: 100%;
      transition: opacity 0.3s, width 0.3s;

      &.to-hide {
        width: 0;
      }
    }

    .wrapper {
      height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      flex: 1;
      justify-content: flex-end;
    }

    .wrapper,
    .innerWrapper {
      transition: all 0.3s;
    }

    .innerWrapper {
      display: flex;
    }

    .card-details h4 {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
    }

    .card-data h4 {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  .column-level {
    display: inline-flex;
    width: 150px;
    height: 84vh;
    overflow: hidden;
    transition: width 0.3s;
  }

  .column-level.active {
    width: 550px;
  }

  .column-level-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .column-level-inner-wrapper {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding-right: 20px;
    margin-right: -20px;
    margin-left: auto;
  }

  .card-level {
    width: 100%;
    min-height: 270px;
    transition: all 0.3s;

    .node-has-qrcode {
      position: absolute;
      top: 5px;
      right: 5px;
      color: #555;
      font-size: 1.4rem;

      &:hover {
        color: #212529;
      }
    }
  }

  .card-level.active {
  }

  .column-level.active ~ .column-level {
    opacity: 0;
    visibility: hidden;
  }

  .card-level {
    position: relative;
  }

  .card-level .prev {
    width: 30px;
    height: 100%;
    text-align: center;
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 0;
    z-index: 10;
  }

  .card-level .prev svg {
    width: 10px;
  }

  .card-level {
    border: 1px solid #ddd;
  }

  .card-inner {
    padding: 15px 35px 30px;
  }

  .card-level .prev svg {
    opacity: 0.3;
    transition: opacity 0.3s, transform 0.3s;
  }

  .column-level:not(:nth-child(1)) .card-level .prev svg {
    opacity: 0.3;
  }

  .card-level.active .prev:hover svg {
    opacity: 1;
    transform: translateX(-5px);
  }

  .card-level .prev path {
    stroke-width: 3px;
  }

  .card-actions {
    margin-top: 25px;
    margin-bottom: 0;
    align-self: flex-end;
  }

  .card-inner {
    display: flex;
    width: 100%;
    min-height: 100px;
    flex-direction: column;
    /* flex-grow: 1; */
  }

  .card-level {
    display: flex;
    flex-direction: column;
  }

  .card-details {
    flex-grow: 1;

    .period + .totalHours::before {
      content: ' / ';
    }

    .value {
      float: right;
    }
  }

  .card-inner .list-group-item {
    padding: 0.2rem 0.5rem;
    background-color: transparent;
    font-size: 0.8rem;
  }

  .card-inner .list-group {
    border-top: 1px solid #888;
    border-bottom: 1px solid #888;
  }

  .card-inner span.label {
    font-weight: bold;
    display: inline-block;
    width: 30%;
  }

  .card-level {
    border: 1px solid #ccc !important;
    margin-bottom: 8px;
    border-radius: 3px;
    background: rgb(221, 221, 221);
  }

  .card-actions a + a {
    margin-left: 10px;
  }

  .card-inner h4 {
    padding-bottom: 5px;
    font-size: 1.1rem;
  }

  .column-level .column-level-inner-wrapper {
    opacity: 0.5;
    pointer-events: none;
  }

  .column-level .card-title,
  .column-level .card-details,
  .column-level .card-actions {
    display: none;
  }

  .column-level .card-title {
    p {
      margin-bottom: 0 !important;
    }
  }

  .card-title {
    p {
      margin-bottom: 0 !important;
      font-size: 0.85rem;
    }
  }

  .column-level.active .column-level-inner-wrapper {
    opacity: 1;
    pointer-events: auto;
  }

  .column-level.active .card-title,
  .column-level.active .card-details,
  .column-level.active .card-actions {
    display: flex;
  }

  .column-level-wrapper .column-title {
    font-size: 0.9rem;
    line-height: 1.4rem;
    margin-bottom: 20px;
    margin-left: 33px;
    text-transform: uppercase;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .column-level.active .column-level-wrapper .column-title {
    font-size: 1.4rem;
    line-height: 1.4rem;
    opacity: 1;
  }

  .card-level.active,
  .card-level:hover {
    background: #e9ecef;
  }

  .column-level .prev,
  .column-level .next {
    opacity: 0;
  }

  .column-level.active .prev,
  .column-level.active .next {
    opacity: 0.5;
  }

  .column-level .column-level-wrapper h3::after {
    content: '>';
    display: inline-block;
    width: 20px;
    height: 20px;
    float: right;
    margin-right: 10px;
  }

  .column-level.active .column-level-wrapper h3::after {
    display: none;
  }

  .column-level.active .card-level:hover {
    box-shadow: 0 2px 13px rgba(0, 0, 0, 0.2);
  }

  .WidgetTreeNavigation {
    flex-direction: column;
  }

  .WidgetTreeNavigation .column-wrapper {
    flex-direction: row;
  }

  .WidgetTreeNavigation .breadcrumb {
    width: 100%;
    display: inline-flex;
    height: 24px;
    line-height: 24px;
    margin: 15px 5px 30px;
  }

  .breadcrumb .active + .active::before {
    content: '>';
    display: inline-block;
    width: 40px;
    height: 14px;
    text-align: center;
  }

  .breadcrumb .active {
    cursor: pointer;
  }

  .column-wrapper {
    overflow-x: hidden;
  }

  .card-data {
    opacity: 0;
    transition: opacity 0.3s linear 0.3s, height 0.3s, max-height 0.3s ease-out;
    max-height: 0;
    width: 100%;
    border-top: 1px solid #ccc;
    overflow: hidden;
  }

  .card-data.active {
    max-height: 900px;
    opacity: 1;
  }

  .card-data.active.data-maintenance {
    height: 350px;
  }

  .card-data.active .card-data-inner {
    opacity: 1;
  }

  .card-data.active.loading .card-data-inner {
    opacity: 0 !important;
  }

  .card-data-inner {
    padding: 30px;
  }

  .card-data-inner {
    transition: opacity 0.3s;
  }

  .card-data-inner a {
    color: #555;
  }

  .card-data-inner .small-data {
    font-size: 0.85rem;
  }

  .hidden {
    display: none;
  }

  .card-data td {
    line-height: 1;
    font-size: 0.9rem;
  }

  .table-sm th,
  .table-sm td {
    padding: 0.5rem 0.3rem;
  }

  .card-data a {
    font-weight: bold;
  }

  .card-actions a {
    width: 40px;
  }

  .card-level:last-child {
    margin-bottom: 140px;
  }

  .designer-node-actions {
    list-style: none;
    margin: 0;
    position: absolute;
    border-top: 1px solid #777;
    border-left: 1px solid #777;
    padding: 5px 4px 3px 5px;
    bottom: 0;
    right: 0;
    border-top-left-radius: 4px;
    background-color: #888;

    li {
      display: inline-block;
      width: 20px;
      text-align: center;
      height: 20px;
      margin: 0;
      padding: 0;
      cursor: pointer;

      path {
        fill: white;
      }

      & + li {
        margin-left: 7px;
      }

      &::after {
        content: '';
        display: inline-block;
      }

      &:hover path {
        fill: #7adc7a;
      }
    }

    .action-delete-node:hover path {
      fill: #ff7575;
    }

    a {
      width: 100%;
      display: inline-block;
    }
  }

  .add-sibling-node {
    width: calc(100% - 30px);
    background-color: #c7c7c7;
    margin-top: 15px;
    border: 1px solid #aaa;

    &:hover {
      background-color: #a8e2ba;
    }

    &:focus,
    &:active {
      background-color: #7ea;
    }

    path {
      fill: #333;
    }
  }

  .add-node,
  .edit-node {
    form {
      margin-top: 20px;

      .form-check {
        margin-left: 1rem;
      }
    }
  }

  .top-actions {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .column-level-inner-wrapper > div {
    padding-bottom: 100px;
    height: auto !important;
  }

  .column-actions {
    max-width: 430px;
    display: flex;
  }

  .column-actions a {
    background-color: #c7c7c7;
    margin-top: 15px;
    border: 1px solid #aaa;
    flex-basis: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    transition: all 0.3s !important;
  }

  .column-actions a + a {
    margin-left: 8px;
  }

  .column-actions.multiple a {
    flex-grow: 2 !important;
    flex-shrink: 2 !important;
  }

  .list-folders,
  .list-files {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s;
  }

  .modal-body > .list-group > .list-folders,
  .modal-body > .list-group > .list-folders > .list-folder > .list-group > .list-files {
    max-height: 9999em;
  }

  .modal-body {
    max-height: 70vh;
    overflow-y: auto;
  }

  .list-folder.open > .list-group > .list-folders,
  .list-folder.open > .list-group > .list-files {
    max-height: 9999em;
  }

  .list-folder .svg-inline--fa {
    font-size: 1.2rem;
    margin-right: 15px;
  }

  .list-file-single {
    display: inline-flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
  }

  .list-file-single:hover {
    background-color: #eee;
  }

  .list-folder {
    cursor: pointer;
  }

  .list-file-single.selected {
    background-color: #ffcccc;
  }

  .list-file-single .file-name {
    justify-self: flex-start;
    flex-grow: 1;
  }

  .list-file-single .file-size,
  .list-folder .file-data {
    justify-self: flex-end;
    text-align: right;
  }

  .list-file-single .file-data {
    margin-left: 20px;
  }

  .list-file-single .file-data {
    order: 3;
    width: 20%;
  }

  .list-file-single .file-size {
    order: 2;
    width: 20%;
  }

  .list-group-item {
    padding-right: 0;
  }

  .form-attachments li {
    padding: 5px 10px !important;
    justify-content: space-between;
    display: inline-flex;

    .fa-language {
      position: absolute;
      top: 10px;
      left: -16px;
      z-index: 10;
      transform: rotateZ(90deg);
      color: var(--brand-secondary);
      opacity: 0.7;
    }

    &:hover .fa-language {
      opacity: 1;
    }

    &.list-group-item {
      flex-wrap: wrap;
      padding-bottom: 20px !important;
      background-color: #fff;

      + li {
        border-top: 1px solid #ccc;
      }

      &:hover,
      &:focus {
        box-shadow: 0 2px 10px rgba(0,0,0,0.3);
        z-index:10;
      }

      .file-version {
        flex-basis: 30%;

        input {
          padding-left: 0;
          margin: 0 auto;
          border: none;
          border-bottom: 2px dashed #ccc;
          text-align: left;
          cursor: pointer;


          &:focus {
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
          }

          &::placeholder {
            color: #aaa;
            font-style: italic;
          }
        }
      }

      .file-name {
        font-size: 1rem;
        flex-basis: 70%;
        border-bottom: 1px solid #eee;
      }

      .file-type {
        flex-basis: 30%;
        text-align: right;
        border-bottom: 1px solid #eee;
      }
    }
  }

  .form-attachments .fa-times {
    cursor: pointer;
  }

  .form-attachments .file-name {
    font-size: 0.9rem;
    padding-top: 2px;
  }

  .form-attachments .file-langs {
    margin-left: auto;
    margin-right: 25px;
  }

  .form-attachments .file-list-action,
  .form-media .media-list-action {
    margin-left: 7px;
    margin-right: 7px;
    color: #444;
  }

  .language-select {
    float: right;
    position: relative;

    .fa-globe {
      font-size: 16px;
      cursor: pointer;
      color: #555;
    }
  }

  .language-select ul {
    margin: 0;
    list-style: none;
    border: 1px solid #aaa;
    border-radius: 3px;
    padding: 3px 8px;
    position: absolute;
    background-color: #fff;
    right: 0;
    display: none;
  }

  .copyable {
    cursor: pointer;
  }

  .input-translations {
    float: right;

    span {
      font-size: 0.75rem;
      color: var(--primary);
      cursor: pointer;
      text-transform: lowercase;

      & + span {
        margin-left: 2px;

        &::before {
          content: ' | ';
          margin-right: 2px;
          color: #777;
        }
      }
    }
  }

  .flag-icon {
    width: 1rem;
    cursor: pointer;
  }

  .flag-icon:hover {
    opacity: 0.7;
  }

  .language-select:hover ul {
    display: block;
  }

  .language-select .do-select {
    font-size: 0.75rem;
  }

  .file-langs .file-lang-single + .file-lang-single {
    margin-left: 8px;
  }

  .file-lang-single.has-translations {
    opacity: 0.4;
  }

  .file-lang-single.has-translations.enabled {
    opacity: 1;
  }

  .language-select .fa-caret-left {
    line-height: 1rem;
    color: #555;
    width: 10px;
    height: 13px;
    transition: transform 0.3s;
  }

  .language-select:hover .fa-caret-left {
    transform: rotateZ(-90deg);
  }

  .language-select.language-selected li span {
    opacity: 0.4;
  }

  .language-select.language-selected li.enabled span {
    opacity: 1;
  }

  .file-list-action,
  .media-list-action {
    cursor: pointer;
  }

  .form-attachments .file-name {
    width: 50%;
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      width: 60px;
      height: 100%;
      background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 1) 100%);
    }

    &:hover {
      cursor: pointer;
    }
  }

  .form-attachments .file-langs {
    margin-left: auto;
    margin-right: 25px;
  }

  .form-attachments .file-list-action,
  .form-media .media-list-action {
    margin-left: 15px;
    color: #588;
  }

  .form-attachments .file-list-action .fa-times,
  .form-media .media-list-action .fa-times,
  .metric-actions .fa-times {
    color: #f55;
  }

  .metrics-list {
    li {
      cursor: ns-resize;

      &.active {
        background-color: var(--info) !important;
        cursor: default;
      }
    }

    &.ordering-disabled {
      li {
        cursor: default;
      }
    }

    .metric-address {
      float: right;
      font-size: 0.8rem;
      color: #555;
    }

    .metric-actions {
      text-align: right;
      float: right;
      display: inline-flex;
      justify-content: flex-end;
      min-width: 80px;

      .metric-action {
        font-size: 16px;
        cursor: pointer;

        svg {
          color: #555;
        }

        & + .metric-action {
          margin-left: 10px;
        }
      }
    }
  }

  .form-metrics {
    ul {
      list-style: none;
      padding: 0;
      border: 1px solid #ccc;
    }

    li {
      padding: 4px 8px 3px;
      font-size: 0.9rem;

      & + li {
        border-top: 1px solid #ccc;
        margin-top: 0;
      }

      &:nth-child(2n) {
        background-color: #eee;
      }
    }
  }

  .form-metrics-global {
    ul {
      margin-top: 25px;
    }

    .section-body.open {
      overflow-y: auto;
      max-height: 70vh;
      padding-bottom: 48px;
      position: relative;
    }
  }

  @media screen and (max-width: 768px) {
    /* MOBILE TEST */
    .tree-container {
      width: 100%;
    }

    .column-level,
    .column-level-wrapper,
    .column-level-inner-wrapper {
      overflow: visible;
    }

    .column-level.active {
      max-width: 100%;
    }

    .column-level-wrapper .column-title {
      margin-left: 0;
      display: none;
    }

    .column-level-inner-wrapper {
      margin-left: 0;
      margin-right: 0;
      padding-right: 0;

      & > .container-fluid {
        height: auto !important;
        padding-left: 0;
        padding-right: 0;
      }
    }

    .card-title {
      p {
        margin-bottom: 0;
      }
    }

    .card-inner {
      padding: 15px 20px;

      h4 {
        font-size: 1.25rem;
      }
    }

    .card-actions {
      justify-content: space-between;

      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        padding: 3px;
      }
    }

    .breadcrumb {
      padding: 0.15rem 0rem;

      & > span + span::before {
        content: ' > ';
        display: inline-block;
        padding: 0 8px;
        color: #aaa;
      }

      span {
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .card-actions > div {
      text-align: center !important;
    }

    .card-level {
      .prev {
        width: 18px;
      }
    }

    .WidgetDesigner .breadcrumb span {
      width: 100%;
      padding: 2px 0;

      & + span {
        border-top: 1px solid #aaa;

        &::before {
          display: none;
        }
      }
    }

    .card-title .svg-inline--fa {
      display: none !important;
    }

    .WidgetDesigner {
      .wrapper,
      .cards {
        width: 100%;
      }

      .innerWrapper {
        justify-content: flex-end;
      }
    }
  }

  .section-body {
    overflow: hidden;
    max-height: 0;
    border: 1px solid #ccc;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    padding: 0 15px 0;
    border-top: none;

    &.open {
      max-height: 50vh;
      overflow-y: auto;
    }

    .section-wrapper {
      padding-top: 15px;

      label {
        margin-bottom: 0;
        font-size: 0.9rem;
      }
    }
  }

  .section-header {
    background-color: #eee;
    padding: 6px 8px 3px 25px;
    cursor: pointer;
    font-size: 0.9rem !important;
    border-radius: 3px;
    border: 1px solid #ccc;
    border-bottom-color: transparent;
    text-transform: uppercase;
    line-height: 1rem;
    position: relative;

    .svg-inline--fa {
      position: absolute;
      left: 8px;
    }
  }

  .section-actions {
    margin-top: 15px;
    margin-bottom: 15px;
    padding-bottom: 8px;
    height: 32px;
  }

  .form-actions {
    margin-top: 40px !important;
  }

  .metric-form-visible {
    > .form-actions {
      .btn {
        pointer-events: none;
        opacity: 0.3;
      }
    }
  }

  @media screen and (min-width: 1025px) {
    .breadcrumb {
      padding-left: 0;
      padding-right: 0;
      font-size: 0.9em;
      background-color: transparent;
      margin-left: 15px;
      margin-top: 5px;
    }

    .breadcrumb span {
      color: #e94e1b;
      text-decoration: none;
      background-color: transparent;
    }

    .breadcrumb span:last-child {
      color: #6c757d;
    }

    .WidgetDesigner .breadcrumb > span + span::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #6c757d;
      content: '/';
    }

    .main-title {
      margin-left: 30px;
      margin-top: 15px;
    }

    .main-title h1 {
      font-size: 1.5rem;
      letter-spacing: -0.025em;
      margin: 0;
      font-weight: 500;
      line-height: 1.2;
      color: inherit;
    }

    .card-level .prev span {
      margin: auto;
    }

    .card-level .prev {
      display: flex;
      justify-content: center;
    }

    .WidgetDesigner .column {
      max-width: 85px;
    }
    .WidgetDesigner .column:last-child {
      width: 500px;
      max-width: 100%;
    }
    .column-level-inner-wrapper {
      overflow-x: hidden;
    }

    .WidgetDesigner .column {
      position: relative;

      &:first-child {
        margin-left: 10px;
      }
    }

    .WidgetDesigner .wrapper {
      justify-content: flex-end;
    }

    .WidgetDesigner .column::after {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - 20px);
      height: 100%;
      background-color: #dddddd;
      content: '';
      display: block;
      margin-left: 20px;
      z-index: 100;
      border-radius: 3px;
      border: 1px solid #ccc;
    }

    .WidgetDesigner .column:last-child::after {
      display: none;
    }

    .column .column-title {
      transform: rotate(90deg);
      opacity: 1;
      margin: 0;
      position: absolute;
      top: 30px;
      left: 65px;
      width: 250px;
      transform-origin: top left;
      z-index: 200;
      cursor: pointer;
    }

    .column:last-child .column-title {
      display: none;
    }

    .WidgetDesigner .wrapper,
    .WidgetDesigner .innerWrapper {
      min-width: 48vw;
    }

    .sidecontent {
      width: 45vw;
      float: left;
      margin-top: 82px;
      padding-left: 30px;
      padding-right: 30px;
      border-left: 1px solid #eee;
      overflow: hidden;

      .vertical-wrapper {
        max-height: calc(100% - 40px);
        overflow-y: auto;
        overflow-x: hidden;
      }
    }

    .card-data.active {
      max-height: 0;
      opacity: 0;
    }

    .WidgetDesigner .innerWrapper {
      flex-grow: 1;
      flex-shrink: 0;
      width: auto !important;
    }

    .WidgetDesigner .wrapper {
      justify-content: flex-end;
    }

    .innerWrapper.animating .column:last-child {
      width: 0;
    }

    .column:last-child .column-title {
      display: block;
      transform: none;
      position: relative;
      top: auto;
      left: 35px;
      font-size: 1.7rem;
      text-transform: none;
      padding-bottom: 20px;
      margin-bottom: 25px;
      border-bottom: 1px solid #eee;
      width: calc(100% - 50px);
      text-align: left;
    }

    .col-1-0 {
      margin-left: 0 !important;
    }

    .sidecontent h3 {
      margin: 0;
      text-align: left;
      font-size: 1.5rem;
      margin-top: 15px;
      border-bottom: 1px solid #ddd;
      padding-bottom: 5px;
    }
    .sidecontent h4 {
      margin-top: 15px;
      font-size: 1.3rem;
    }

    .cards.has-media .card-title {
      cursor: pointer;
      flex-shrink: 0;
    }

    .cards.no-media {
      .card-details,
      .card-actions {
        display: none;
      }
    }

    .cards.no-media .card-title {
      margin-bottom: 0;

      h4 {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    .WidgetDesigner .cards,
    .card-inner {
      min-height: 60px;
    }

    .WidgetDesigner .cards.is-collapsed {
      max-height: 100px;
      overflow-y: hidden;

      .card-details,
      .card-actions {
        opacity: 0;
      }
    }

    .card-title .svg-inline--fa {
      position: absolute;
      top: 25px;
      right: 0;
      width: 18px;
      height: 18px;
      color: #444;
      transition: transform 0.3s;
    }

    .cards.has-media .card-title .svg-inline--fa {
      transform: rotate(0);
    }
    .cards.has-media.is-collapsed .card-title .svg-inline--fa {
      transform: rotate(-90deg);
    }
  }

  @media screen and (min-width: 1440px) {
    .WidgetDesigner .cards,
    .add-sibling-node {
      max-width: 430px;
    }
  }

  .tooltips {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: -6px;
      left: 50%;
      transform: translateX(0);
      border-width: 4px 6px 0 6px;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
      z-index: 100;
      transition: opacity 0.3s;
      opacity: 0;
    }

    &::after {
      content: attr(data-title);
      position: absolute;
      left: 50%;
      top: -6px;
      transform: translateX(0) translateY(-100%);
      background: rgba(0, 0, 0, 0.7);
      text-align: center;
      color: #fff;
      padding: 4px 2px;
      font-size: 12px;
      min-width: 100px;
      border-radius: 5px;
      pointer-events: none;
      transition: opacity 0.3s;
      opacity: 0;
    }

    &:hover {
      &::before,
      &::after {
        opacity: 1;
      }
    }
  }

  .overlay-focuser {
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: #000;
    position: fixed;
    top: 0;
    left: 0;
    transition: opacity 0.3s;
    pointer-events: none;
  }

  .sidecontent {
    transition: box-shadow 0.3s;
  }

  .card-level {
    transition: background-color 0.3s;
  }

  &.sidecontent-open {
    .overlay-focuser {
      //   pointer-events: auto;
      opacity: 0.25;
    }

    .sidecontent {
      z-index: 10000;
      background-color: #fff;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    }

    .top-actions .btn {
      pointer-events: none;
      opacity: 0.3;
    }

    .card-level.active {
      background-color: #fff;
      z-index: 10;

      .designer-node-actions {
        background-color: var(--brand-secondary);
      }
    }
  }
}

.metric-label {
  cursor: pointer;
}

.preview-data .value {
  float: right;
  text-align: right;
}

.preview-image {
  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 400px;
  }
}

.metric-order-helper {
  background-color: #fff;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.7);
  padding: 4px 8px 3px;
  font-size: 0.9rem;
  list-style: none;

  .metric-address {
    float: right;
    font-size: 0.8rem;
    color: #555;
  }

  .metric-actions {
    display: none;
  }
}

#preview-list-video {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100px;
  height: 80px;
  z-index: 10;

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

.list-group-video {
  margin-bottom: 40px !important;

  .list-group-item {
    position: relative;
    padding: 5px 10px;
    justify-content: space-between;
    display: inline-flex;
  }
}
