.inplant-service {
  .modal.fade {
    display: none;
  }
  .modal.fade.show {
    display: block;
  }
}

//Details
.inplant-service {
  .w-10 {
    width: 10%;
  }

  .w-15 {
    width: 15%;
  }

  .w-35 {
    width: 35%;
  }

  .w-40 {
    width: 40%;
  }
  .issue-attachments {
    figure {
      cursor: pointer;
    }
  }

  .breadcrumb {
    padding-left: 0;
    padding-right: 0;
    background: transparent;
    font-size: 0.9em;
  }
}

//List
.inplant-service {
  .issue-list-item {
    cursor: pointer;
  }

  .color-gray {
    color: gray;
  }

  .tooltips {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: -6px;
      left: 50%;
      transform: translateX(-50%);
      border-width: 4px 6px 0 6px;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
      z-index: 100;
      transition: opacity 0.3s;
      opacity: 0;
    }

    &::after {
      content: attr(title);
      position: absolute;
      left: 50%;
      top: -6px;
      transform: translateX(-50%) translateY(-100%);
      background: rgba(0, 0, 0, 0.7);
      text-align: center;
      color: #fff;
      padding: 4px 2px;
      font-size: 12px;
      min-width: 140px;
      border-radius: 5px;
      pointer-events: none;
      transition: opacity 0.3s;
      opacity: 0;
    }

    &:hover {
      &::before,
      &::after {
        opacity: 1;
      }
    }
  }

  .card-has-errored {
    box-shadow: 0 0 7px #ff6530;
  }

  .service-table {
    .rt-tr-group {
      cursor: pointer;
    }
  }
}

//New
.inplant-service {
  .attachments-preview {
    h4 {
      font-size: 16px;
      line-height: 24px;
    }

    ul {
      list-style: none;
      padding: 0;
    }
  }
}
